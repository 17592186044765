.autocomplete-input-container {
  position: relative;
}

#autocomplete {
  color: rgba(0, 0, 0, 0.3);
  position: absolute;
  background: transparent;
  pointer-events: none;
}

#search {
  position: relative;
  background: transparent;
}
