@charset "UTF-8";
@import "bootstrap-grid.css";
@import "icons.css";
@import "plugins/revolutionslider.css";
@import 'https://fonts.googleapis.com/css?family=Open+Sans';

input,
input[type=text],
input[type=password],
input[type=email],
input[type=number],
textarea,
select {
    height: 51px;
    line-height: 51px;
    padding: 0 20px;
    outline: 0;
    font-size: 15px;
    color: gray;
    margin: 0 0 16px;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: block;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
    font-weight: 500;
    opacity: 1;
    border-radius: 3px
}

select {
    padding: 15px 18px;
    cursor: pointer
}

input {
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out
}

input:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
textarea:focus {
    color: gray;
    transition: box-shadow .2s !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .07);
    border: 1px solid #d8d8d8;
    opacity: 1
}

input[type=submit] {
    border: 0;
    padding: 11px 18px;
    width: auto
}

input[type=checkbox] {
    display: inline
}

input[type=radio] {
    width: 15px;
    height: 15px;
    cursor: pointer;
    box-shadow: none
}

::-webkit-input-placeholder {
    color: #888;
    opacity: 1
}

:-moz-placeholder {
    color: #888;
    opacity: 1
}

::-moz-placeholder {
    color: #888;
    opacity: 1
}

:-ms-input-placeholder {
    color: #888;
    opacity: 1
}

textarea {
    height: auto;
    line-height: 27px;
    padding: 20px;
    min-height: 130px;
    transition: none !important;
    min-width: 100%
}

label,
legend {
    display: block;
    font-weight: 700;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 8px
}

label span,
legend span {
    font-weight: 400;
    font-size: 14px;
    color: #444
}

fieldset {
    padding: 0;
    border: 0
}

a,
button {
    outline: 0 !important
}

a:focus,
a:hover {
    text-decoration: none;
    color: #333
}

img {
    max-width: 100%
}

#logo {
    display: inline-block;
    margin-top: 3px
}

#logo img {
    max-height: 49px;
    width: auto;
    transform: translate3d(0, 0, 0)
}

img.footer-logo {
    max-height: 38px;
    width: auto
}

#header {
    position: relative;
    z-index: 999;
    padding-top: 0px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .12);
    font-size: 16px
}

.header-widget {
    position: relative;
    top: -1px;
    height: 54px
}

.header-widget .button,
.header-widget .button.border {
    margin-right: 0;
    min-width: 130px;
    text-align: center;
    position: relative;
    line-height: 26px;
    font-weight: 500
}

.header-widget .button.border i {
    font-size: 17px;
    padding: 0 0 0 4px;
    top: 2px;
    display: inline-block;
    position: relative
}

.header-widget .sign-in {
    position: relative;
    color: #333;
    margin-right: 25px;
    top: 10px;
    display: inline-block;
    vertical-align: top
}

.header-widget .sign-in i {
    padding-right: 4px;
    font-size: 15px
}

.header-widget .sign-in:hover {
    color: #66676b
}

header.fullwidth .container {
    width: 100%;
    margin: 0;
    padding: 0 40px
}

.left-side {
    float: left;
    width: 65%;
    display: inline-block
}

.right-side {
    float: right;
    width: 35%;
    display: inline-block
}

@media screen and (max-width: 600px) {
    .vendor-menu-item {
        visibility: hidden;
        clear: both;
        float: left;
        margin: 10px auto 5px 20px;
        width: 28%;
        display: none;
    }
}

@media screen and (min-width: 600px) {
    .vendor-menu-item2 {
        visibility: hidden;
        clear: both;
        float: left;
        margin: 10px auto 5px 20px;
        width: 28%;
        display: none;
    }
}


@media (min-width: 1025px) and (max-width: 1239px) {
    .right-side {
        width: auto;
        display: inline-block;
        margin-top: -126px;
        position: relative
    }

    .left-side {
        width: 100%;
        display: inline-block
    }
}

#logo,
#navigation {
    float: left
}

#logo {
    margin-right: 50px
}

.header-widget {
    text-align: right
}

#header.cloned {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999
}

#header.cloned {
    background-color: #fff
}

#header.cloned #logo img {
    transform: translate3d(0, 0, 0);
    max-width: 120px;
    margin-top: 1px
}

.sticky-logo {
    display: none
}

#header.cloned.unsticky {
    opacity: 1;
    visibility: hidden;
    pointer-events: none;
    transform: translate(0, -100%) scale(1);
    transition: .3s
}

#header.cloned.sticky {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0) scale(1);
    transition: .3s
}

#footer {
    padding: 70px 0 0;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, .09);
    font-size: 16px
}

#footer.sticky-footer {
    box-shadow: none
}

#footer.dark {
    border-top: 0
}

#footer h4 {
    color: #333;
    font-size: 21px;
    margin-bottom: 25px
}

.footer-shadow {
    width: 100%;
    display: block;
    background: #fff;
    height: 200px;
    margin-top: -200px;
    position: relative;
    z-index: -1;
    box-shadow: 0 20px 40px -20px rgba(0, 0, 0, .06)
}

.text-widget span {
    color: #707070
}

.text-widget span.address {
    display: block;
    margin-bottom: 10px
}

#footer a {
    color: #66676b
}

#footer ul.footer-links {
    margin: 0 0 15px;
    padding: 0;
    width: 50%;
    box-sizing: border-box;
    display: inline-block;
    float: left
}

#footer .footer-links li {
    position: relative;
    list-style: none
}

#footer .footer-links li a {
    padding: 0 0 9px 16px;
    display: inline-block;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: #707070;
    line-height: 21px
}

#footer .footer-links li a:hover {
    padding-left: 22px;
    color: #66676b
}

#footer .footer-links li:before {
    font-family: fontawesome;
    font-size: 20px;
    content: "\f105";
    position: absolute;
    left: 0;
    top: -2px;
    padding: 0 7px 0 0;
    color: #ccc
}

.copyrights {
    color: #707070;
    border-top: 1px solid rgba(0, 0, 0, .09);
    margin-top: 60px;
    text-align: center;
    padding: 40px 0;
    display: inline-block;
    width: 100%
}

#footer.alt .copyrights {
    margin-top: 0;
    border: 0;
    padding: 25px
}

.copyrights a {
    color: #707070
}

#footer.dark {
    background: #222;
    color: #aaa
}

#footer.dark a,
#footer.dark h4,
#footer.dark a:hover {
    color: #fff
}

#footer.dark .text-widget,
#footer.dark .text-widget span,
#footer.dark .footer-links li a {
    color: #aaa
}

#footer.dark .footer-links li a:hover {
    color: #fff
}

#footer.dark .footer-links li:before {
    color: #666
}

#footer.dark .copyrights {
    color: #aaa;
    border-top: 1px solid rgba(255, 255, 255, .09)
}

#footer .social-icons li a {
    opacity: .8;
    transition: opacity .35s
}

#footer .social-icons li a:hover {
    opacity: 1
}

#backtotop {
    position: fixed;
    right: 0;
    opacity: 0;
    visibility: hidden;
    bottom: 25px;
    margin: 0 25px 0 0;
    z-index: 999;
    transition: .35s;
    transform: scale(.7)
}

#backtotop.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1)
}

#backtotop a {
    text-decoration: none;
    border: 0;
    display: block;
    width: 46px;
    height: 46px;
    background-color: #66676b;
    opacity: 1;
    transition: all .3s;
    border-radius: 50%;
    text-align: center;
    font-size: 26px
}

body #backtotop a {
    color: #fff
}

#backtotop a:after {
    content: "\f106";
    font-family: fontawesome;
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%)
}

#navigation {
    position: relative;
    display: block
}

#navigation ul {
    list-style: none;
    position: relative;
    float: left;
    margin: 0;
    padding: 0
}

#navigation ul a {
    display: block;
    text-decoration: none
}

#navigation ul li {
    position: relative;
    float: left;
    margin: 0;
    padding: 0
}

#navigation ul li ul {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 0;
    z-index: 99999
}

#navigation ul ul li {
    float: none
}

#navigation ul ul ul {
    top: 0;
    left: 100%;
    margin: 0
}

#navigation ul ul {
    margin: 10px 0 0 15px
}

#navigation ul ul:before {
    content: "";
    position: absolute;
    left: 0;
    top: -10px;
    background: transparent;
    width: 100%;
    height: 10px
}

#navigation ul ul ul:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 100%;
    left: -10px;
    top: 0;
    opacity: 0
}

#navigation ul li:hover > ul {
    opacity: 1;
    visibility: visible
}

#navigation ul li a:after,
#navigation ul ul li a:after {
    font-family: fontawesome;
    opacity: .5
}

#navigation ul li a:after {
    content: '\f107';
    padding-left: 7px
}

#navigation ul ul li a:after {
    content: '\f105';
    position: absolute;
    right: 15px;
    top: 7px
}

#navigation ul li a:only-child:after {
    content: '';
    padding: 0
}

#navigation ul ul li a:only-child:after {
    content: ''
}

#navigation ul ul {
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .12);
    border-radius: 4px;
    font-size: 15px
}

#navigation ul ul li {
    border-radius: 4px
}

#navigation ul ul li:last-child {
    border-bottom: 0
}

#navigation ul li:hover ul a,
#navigation ul ul a {
    line-height: 27px !important;
    padding: 5px 40px 5px 15px !important;
    line-height: 24px !important
}

body #navigation ul ul {
    padding: 12px 10px;
    box-sizing: border-box
}

#navigation ul ul li {
    width: 180px
}

#navigation ul ul li:hover {
    border-radius: 0
}

#navigation ul ul li:first-child {
    border-radius: 4px 4px 0 0
}

#navigation ul ul li:last-child {
    border-radius: 0 0 4px 4px
}

#navigation ul ul {
    opacity: 0;
    visibility: hidden;
    transition: all .25s;
    transform: translate3d(0, 15px, 0)
}

#navigation ul li:hover > ul {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0)
}

#navigation.style-1 .current {
    background-color: transparent;
    color: #66676b
}

#navigation.style-1 {
    margin-top: 1px
}

#navigation.style-1 .current:after {
    opacity: 1
}

#navigation.style-1 ul li {
    transition: all .25s
}

#navigation.style-1 ul li a {
    color: #444;
    cursor: pointer
}

#navigation.style-1 ul li:hover a {
    color: #66676b
}

#navigation.style-1 ul ul li a {
    color: #444 !important
}

#navigation.style-1 ul ul li {
    opacity: .8
}

#navigation.style-1 ul ul li:hover {
    opacity: 1
}

#navigation.style-1 ul a {
    line-height: 32px;
    padding: 5px 15px
}

#navigation.style-1 ul li {
    margin: 0 5px
}

#navigation.style-1 ul ul li {
    margin: 0;
    border-radius: 0
}

#navigation.style-1 ul ul li:first-child {
    border-radius: 4px 4px 0 0
}

#navigation.style-1 ul ul li:last-child {
    border-radius: 0 0 4px 4px
}

#navigation ul ul li a:after {
    opacity: .5;
    transition: all .25s;
    transform: translateX(-5px)
}

#navigation ul ul li:hover a:after {
    opacity: 1;
    transform: translateX(0)
}

.mega-menu {
    opacity: 0;
    visibility: hidden;
    transition: all .25s;
    position: absolute;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .12);
    border-radius: 4px;
    font-size: 15px;
    display: flex;
    top: 100%;
    left: 0;
    padding: 20px 0;
    box-sizing: border-box;
    font-size: 14px;
    margin-top: -2px;
    background-color: #fff;
    width: 200px;
    z-index: 99;
    transform: translate3d(-42%, 24px, 0)
}

.mega-menu:before {
    content: "";
    position: absolute;
    top: -12px;
    left: 0;
    display: block;
    width: 100%;
    height: 12px;
    opacity: 0
}

#navigation ul li .mega-menu ul {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 0, 0);
    position: relative;
    display: inline-block;
    flex: 1;
    padding: 0;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    top: auto;
    left: auto;
    background-color: transparent
}

#navigation ul li:hover .mega-menu {
    opacity: 1;
    visibility: visible;
    transform: translate3d(-42%, 12px, 0)
}

#navigation ul li:hover .mega-menu ul {
    opacity: 1;
    visibility: visible
}

#navigation .mega-menu ul,
#navigation .mega-menu ul li {
    width: 100%
}

.mega-menu.two-columns {
    width: 429px
}

.mega-menu.three-columns {
    width: 643px
}

.mega-menu.four-columns {
    width: 857px
}

#navigation ul .mega-menu ul a {
    color: #bbb;
    padding: 0 !important;
    display: block;
    margin-left: 0;
    -wekkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

#navigation ul li:hover .mega-menu ul a,
#navigation ul .mega-menu ul a {
    padding: 6px 0 !important;
    line-height: 22px !important;
    transform: translate3d(0, 0, 0) !important
}

#navigation .mega-menu ul li {
    padding: 0 30px
}

#navigation ul li a i {
    font-size: 14px;
    position: relative;
    top: 0;
    padding-right: 5px
}

.mega-menu-section {
    display: inline-block;
    float: left;
    width: 214px;
    padding: 0;
    border-right: 1px solid #e9e9e9
}

.mega-menu .mega-menu-section:last-child {
    border: 0
}

.mega-menu-headline {
    color: #222;
    opacity: 1 !important;
    font-weight: 600;
    display: block;
    padding: 8px 30px !important;
    letter-spacing: 0;
    font-size: 14px;
    line-height: 21px
}

.mobile-styles .mm-listview {
    margin: 0 -20px !important
}

.mobile-styles .mm-listview .mega-menu-headline {
    color: #fff;
    padding: 15px 20px !important;
    background: #222 !important
}

.mobile-styles .mm-listview li a i {
    padding-right: 5px
}

.mobile-styles.mm-panel:before {
    height: 10px !important
}

.user-menu {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 25px;
    top: 9px;
    vertical-align: top;
    padding-left: 25px;
    margin-left: 25px
}

.user-menu .user-name {
    transition: .2s
}

.user-menu .user-name:after {
    content: "\f107";
    font-family: fontawesome;
    transition: .2s;
    transform: rotate(0deg);
    display: inline-block;
    margin-left: 6px;
    color: #aaa
}

.user-menu.active .user-name:after,
.user-menu:hover .user-name:after,
.user-menu.active .user-name,
.user-menu:hover .user-name {
    color: #66676b
}

.user-menu.active .user-name:after {
    transform: rotate(180deg)
}

.user-name span img {
    width: 100%;
    display: inline-block;
    border-radius: 50%;
    image-rendering: -webkit-optimize-contrast
}

.user-name span {
    display: inline-block;
    width: 38px;
    height: 38px;
    margin-right: 10px;
    border-radius: 50%;
    position: absolute;
    left: -22px;
    top: -5px
}

.user-menu ul {
    float: left;
    text-align: left;
    position: absolute;
    top: 45px;
    right: 0;
    list-style: none;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .12);
    border-radius: 4px;
    display: inline-block;
    width: 180px;
    font-size: 15px;
    transform: translate3d(0, 15px, 0);
    padding: 12px 10px;
    box-sizing: border-box;
    transition: .25s;
    visibility: hidden;
    opacity: 0;
    z-index: 110
}

.user-menu ul li a {
    padding: 6px 15px;
    line-height: 22px;
    display: inline-block;
    color: #696969;
    transition: .2s
}

.user-menu ul li a:hover {
    color: #444
}

.user-menu ul li a i {
    width: 20px;
    position: relative;
    display: inline-block
}

.user-menu.active ul {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0)
}

.mm-hidden {
    display: none !important
}

.mm-wrapper {
    overflow-x: hidden;
    position: relative
}

.mm-menu {
    box-sizing: border-box;
    background: inherit;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0
}

.mm-panels,
.mm-panels > .mm-panel {
    background: inherit;
    border-color: inherit;
    box-sizing: border-box;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0
}

.mm-panels {
    overflow: hidden
}

.mm-panel {
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left
}

.mm-panel.mm-opened {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-panel.mm-subopened {
    -webkit-transform: translate(-30%, 0);
    -ms-transform: translate(-30%, 0);
    transform: translate(-30%, 0);
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0)
}

.mm-panel.mm-highest {
    z-index: 1
}

.mm-panel.mm-noanimation {
    -webkit-transition: none !important;
    transition: none !important
}

.mm-panel.mm-noanimation.mm-subopened {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-panels > .mm-panel {
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 20px
}

.mm-panels > .mm-panel.mm-hasnavbar {
    padding-top: 40px
}

.mm-panels > .mm-panel:not(.mm-hidden) {
    display: block
}

.mm-panels > .mm-panel:after,
.mm-panels > .mm-panel:before {
    content: '';
    display: block;
    height: 20px
}

.mm-vertical .mm-panel {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important
}

.mm-listview .mm-vertical .mm-panel,
.mm-vertical .mm-listview .mm-panel {
    display: none;
    padding: 10px 0 10px 10px
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after,
.mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
    border-color: transparent
}

.mm-vertical li.mm-opened > .mm-panel,
li.mm-vertical.mm-opened > .mm-panel {
    display: block
}

.mm-listview > li.mm-vertical > .mm-next,
.mm-vertical .mm-listview > li > .mm-next {
    box-sizing: border-box;
    height: 40px;
    bottom: auto
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after,
.mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
    right: 19px
}

.mm-btn {
    box-sizing: border-box;
    width: 40px;
    height: 50px;
    position: absolute;
    top: 0;
    z-index: 1
}

.mm-clear:after,
.mm-clear:before,
.mm-close:after,
.mm-close:before {
    content: '';
    border: 2px solid transparent;
    display: block;
    width: 5px;
    height: 5px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.mm-clear:before,
.mm-close:before {
    border-right: 0;
    border-bottom: 0;
    right: 18px
}

.mm-clear:after,
.mm-close:after {
    border-left: 0;
    border-top: 0;
    right: 25px
}

.mm-next:after,
.mm-prev:before {
    content: '';
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    display: block;
    width: 8px;
    height: 8px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0
}

.mm-prev:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 23px;
    right: auto
}

.mm-next:after {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    right: 23px;
    left: auto
}

.mm-navbar {
    border-bottom: 1px solid;
    border-color: inherit;
    text-align: center;
    line-height: 30px;
    height: 50px;
    padding: 0 40px;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

.mm-navbar > * {
    display: block;
    padding: 10px 0
}

.mm-navbar a,
.mm-navbar a:hover {
    text-decoration: none
}

.mm-navbar .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.mm-navbar .mm-btn:first-child {
    left: 0
}

.mm-navbar .mm-btn:last-child {
    text-align: right;
    right: 0
}

.mm-panel .mm-navbar {
    display: none
}

.mm-panel.mm-hasnavbar .mm-navbar {
    display: block;
    background-color: #262626
}

.mm-listview,
.mm-listview > li {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0
}

.mm-listview {
    font: inherit;
    font-size: 14px;
    line-height: 20px
}

.mm-listview a,
.mm-listview a:hover {
    text-decoration: none
}

.mm-listview > li {
    position: relative
}

.mm-listview > li,
.mm-listview > li .mm-next,
.mm-listview > li .mm-next:before,
.mm-listview > li:after {
    border-color: inherit
}

.mm-listview > li {
    border-bottom: 1px solid #404040
}

.mm-listview > li > a,
.mm-listview > li > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: inherit;
    display: block;
    padding: 14px 10px 14px 20px;
    margin: 0
}

.mm-listview > li:not(.mm-divider):after {
    content: '';
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: none
}

.mm-listview > li:not(.mm-divider):after {
    left: 20px
}

.mm-listview .mm-next {
    background: transparent;
    width: 50px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2
}

.mm-listview .mm-next:before {
    content: '';
    border-left-width: 1px;
    border-left-style: solid;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0
}

.mm-listview .mm-next + a,
.mm-listview .mm-next + span {
    margin-right: 50px
}

.mm-listview .mm-next.mm-fullsubopen {
    width: 100%
}

.mm-listview .mm-next.mm-fullsubopen:before {
    border-left: 0
}

.mm-listview .mm-next.mm-fullsubopen + a,
.mm-listview .mm-next.mm-fullsubopen + span {
    padding-right: 50px;
    margin-right: 0
}

.mm-panels > .mm-panel > .mm-listview {
    margin: 20px -20px
}

.mm-panels > .mm-panel > .mm-listview:first-child,
.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
    margin-top: -10px
}

.mm-menu {
    background: #2a2a2a;
    border-color: rgba(255, 255, 255, .1);
    color: #fff
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
    border-color: rgba(255, 255, 255, .8)
}

.mm-menu .mm-listview {
    border-color: #2a2a2a
}

.mm-menu .mm-listview > li .mm-next:after {
    border-color: rgba(255, 255, 255, .8)
}

.mm-menu .mm-listview > li a:not(.mm-next) {
    -webkit-tap-highlight-color: rgba(255, 255, 255, .5);
    tap-highlight-color: rgba(255, 255, 255, .5)
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span {
    background: rgba(255, 255, 255, .05)
}

.mm-menu .mm-listview > li {
    transition: .2s
}

.mm-menu .mm-listview > li:hover {
    background: rgba(255, 255, 255, .03)
}

.mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel,
.mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next,
.mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel,
.mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
    background: rgba(0, 0, 0, .05)
}

.mm-menu .mm-divider {
    background: rgba(0, 0, 0, .05)
}

.mm-page {
    box-sizing: border-box;
    position: relative
}

.mm-slideout {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    z-index: 1
}

html.mm-opened {
    overflow-x: hidden;
    position: relative
}

html.mm-blocking {
    overflow: hidden
}

html.mm-blocking body {
    overflow: hidden
}

html.mm-background .mm-page {
    background: inherit
}

#mm-blocker {
    background: transparent;
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2
}

html.mm-blocking #mm-blocker {
    display: block
}

.mm-menu.mm-offcanvas {
    z-index: 0;
    display: none;
    position: fixed
}

.mm-menu.mm-offcanvas.mm-opened {
    display: block
}

.mm-menu.mm-offcanvas.mm-no-csstransforms.mm-opened {
    z-index: 10
}

.mm-menu.mm-offcanvas {
    width: 80%;
    min-width: 140px;
    max-width: 440px
}

html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(80%, 0);
    -ms-transform: translate(80%, 0);
    transform: translate(80%, 0);
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0)
}

@media all and (max-width: 175px) {
    html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
        -webkit-transform: translate(140px, 0);
        -ms-transform: translate(140px, 0);
        transform: translate(140px, 0);
        -webkit-transform: translate3d(140px, 0, 0);
        transform: translate3d(140px, 0, 0)
    }
}

@media all and (min-width: 550px) {
    html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
        -webkit-transform: translate(440px, 0);
        -ms-transform: translate(440px, 0);
        transform: translate(440px, 0);
        -webkit-transform: translate3d(440px, 0, 0);
        transform: translate3d(440px, 0, 0)
    }
}

.mm-sronly {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    white-space: nowrap !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important
}

em.mm-counter {
    font: inherit;
    font-size: 14px;
    font-style: normal;
    text-indent: 0;
    line-height: 20px;
    display: block;
    margin-top: -10px;
    position: absolute;
    right: 45px;
    top: 50%
}

em.mm-counter + a.mm-next {
    width: 90px
}

em.mm-counter + a.mm-next + a,
em.mm-counter + a.mm-next + span {
    margin-right: 90px
}

em.mm-counter + a.mm-fullsubopen {
    padding-left: 0
}

em.mm-counter + a.mm-fullsubopen + a,
em.mm-counter + a.mm-fullsubopen + span {
    padding-right: 90px
}

.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + a,
.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + span {
    padding-right: 90px
}

.mm-vertical > .mm-counter {
    top: 12px;
    margin-top: 0
}

.mm-vertical.mm-spacer > .mm-counter {
    margin-top: 40px
}

.mm-nosubresults > .mm-counter {
    display: none
}

.mm-menu em.mm-counter {
    background-color: rgba(0, 0, 0, .3);
    height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    line-height: 19px;
    font-size: 11px;
    font-weight: 500;
}

.mmenu-trigger {
    height: 50px;
    width: 50px;
    display: none;
    position: relative;
    margin: 0 0 20px;
    border-radius: 4px;
    cursor: pointer
}

.hamburger {
    padding: 0;
    top: 12px;
    left: 13px;
    transform: scale(.67);
    -moz-transform: scale(.7) translateY(4px);
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: .15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible
}

.hamburger-box {
    position: relative
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 34px;
    height: 4px;
    background-color: #555;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: ease
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block
}

.hamburger-inner::before {
    top: -10px
}

.hamburger-inner::after {
    bottom: -10px
}

.hamburger--collapse .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: .13s;
    transition-delay: .13s;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top .2s .2s cubic-bezier(.33333, .66667, .66667, 1), opacity .1s linear
}

.hamburger--collapse .hamburger-inner::before {
    transition: top .12s .2s cubic-bezier(.33333, .66667, .66667, 1), transform .13s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--collapse.is-active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: .22s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s .22s linear
}

.hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top .1s .16s cubic-bezier(.33333, 0, .66667, .33333), transform .13s .25s cubic-bezier(.215, .61, .355, 1)
}

.mmenu-trigger {
    display: none
}

@media only screen and (max-width: 1024px) {

    .mmenu-trigger {
        display: inline-block !important
    }

    #logo {
        position: absolute;
        width: 100%;
        margin: 0 auto;
        margin-top: 5px;
        width: 35%;
        text-align: center;
        left: 0;
        right: 0
    }

    .header-widget {
        padding: 20px 0;
        border-top: 1px solid #eee;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start
    }

    .header-widget a.button.border {
        padding: 7px 16px;
        background-color: transparent;
        font-size: 16px;
        min-width: auto;
        float: right
    }

    .sign-in,
    .user-menu {
        flex: 1
    }

    .header-widget .sign-in {
        top: 9px
    }

    #header-container.header-style-2 .left-side,
    #header-container.header-style-2 .right-side,
    .right-side,
    .left-side {
        width: 100%
    }

    #top-bar .right-side .social-icons {
        float: left;
        position: relative;
        z-index: 9
    }

    #header-container.header-style-2 .header-widget {
        float: left;
        margin-top: 35px;
        margin-bottom: -5px
    }

    .header-widget {
        text-align: left
    }

    #header {
        padding-bottom: 0
    }

    ul.top-bar-menu {
        z-index: 99;
        position: relative
    }

    .header-style-2 .menu-responsive {
        width: 750px;
        margin-left: auto;
        margin-right: auto;
        float: none;
        padding: 0 15px
    }

    #header-container.header-style-2 .header-widget {
        width: calc(100%);
        border-top: 1px solid #e0e0e0;
        padding-top: 30px
    }
}

.main-search-container {
    display: block;
    width: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    height: 620px;
    z-index: 9
}

.main-search-container:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.99) 20%, rgba(255, 255, 255, 0.7) 70%, rgba(255, 255, 255, 0) 95%)
}

.main-search-container h2 {
    font-size: 42px
}

.main-search-container h4 {
    font-size: 24px;
    font-weight: 300;
    color: white;
    line-height: 32px
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    overflow: hidden
}

.main-search-container video {
    top: 50%;
    transform: translateY(-52%);
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -10;
    background-size: cover;
    transition: 1s opacity;
    position: absolute
}

.main-search-container.dark-overlay:before {
    background: rgba(46, 47, 50, .55)
}

.main-search-container.dark-overlay h2 {
    color: #fff
}

.main-search-container.dark-overlay h4 {
    color: rgba(255, 255, 255, .7)
}

.main-search-inner {
    position: relative;
    display: block;
    top: 50%;
    transform: translate(0, -50%);
    padding-bottom: 30px
}

.main-search-inner select {
    border: 0;
    padding-top: 2px;
    padding-bottom: 0;
    height: 44px;
    box-shadow: none
}

.main-search-input {
    margin-top: 50px;
    border-radius: 50px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .12);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 9px;
    max-height: 68px
}

.main-search-input-item {
    flex: 1;
    border-right: 1px solid #e9e9e9;
    margin-top: 3px;
    position: relative;
    padding-left: 30px;
    padding-right: 30px
}

.main-search-input .main-search-input-item:nth-last-child(2) {
    border-right: 0;
    padding-left: 15px;
    padding-right: 15px
}

.main-search-input-item.location a {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    padding: 10px;
    background: #fff
}

.main-search-input-item.location a:hover {
    color: #66676b
}

.main-search-input input,
.main-search-input input:focus {
    font-size: 16px;
    border: 0;
    background: #fff;
    margin: 0;
    padding: 0;
    height: 44px;
    line-height: 44px;
    box-shadow: none
}

.main-search-input button.button {
    font-size: 18px;
    font-weight: 600;
    padding: 0 40px;
    margin-right: 1px;
    height: 50px;
    outline: 0
}

.gray-style.main-search-input {
    box-shadow: none;
    background-color: #f7f7f7;
    top: -10px;
    position: relative
}

.gray-style .main-search-input-item.location a,
.gray-style.main-search-input input,
.gray-style.main-search-input input:focus {
    background: #f7f7f7
}

.gray-style .main-search-input-item {
    border-color: #e0e0e0
}

body .pac-item {
    font-size: 12px;
    color: #999;
    cursor: pointer;
    transition: .2s;
    padding: 5px 15px
}

body .pac-container {
    border-radius: 0 0 4px 4px;
    box-shadow: none;
    border: 1px solid #e0e0e0;
    border-top: 0;
    box-sizing: content-box;
    margin-top: -2px;
}

.main-search-input .pac-container {
    left: 0 !important;
    top: 54px !important
}

.input-with-icon i,
.main-search-input-item.location a {
    padding: 5px 10px;
    z-index: 101
}

#autocomplete-container,
#autocomplete-input {
    position: relative;
    z-index: 101
}

.main-search-input.gray-style .main-search-input-item.location a,
.main-search-input.gray-style #autocomplete-input {
    background: 0 0
}

.main-search-input.gray-style #autocomplete-input {
    padding-right: 36px
}

@media (min-width: 992px) {
    .main-search-input .pac-container {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, .15);
        border: 0;
        padding-top: 45px;
        z-index: 9;
        left: 0 !important;
        top: 1px !important;
        border-radius: 4px
    }

    .main-search-input.gray-style .pac-container {
        box-shadow: none;
        border: 1px solid #e0e0e0
    }

    .main-search-input-item,
    .main-search-input-item #autocomplete-input {
        padding: 0 15px
    }
}

.rangeslider,
.rangeslider__fill {
    display: block;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px
}

.rangeslider {
    background: #e6e6e6;
    position: relative;
    margin: 25px 0
}

.rangeslider--horizontal {
    height: 3px;
    width: 100%
}

.rangeslider--vertical {
    width: 20px;
    min-height: 150px;
    max-height: 100%
}

.rangeslider--disabled {
    filter: alpha(opacity=40);
    opacity: .4
}

.rangeslider__fill {
    background: #66676b;
    position: absolute
}

.rangeslider--horizontal .rangeslider__fill {
    top: 0;
    height: 100%
}

.rangeslider--vertical .rangeslider__fill {
    bottom: 0;
    width: 100%
}

.rangeslider__handle {
    background: #fff;
    border: 2px solid #66676b;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
    cursor: pointer;
    display: inline-block;
    position: absolute;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%)
}

.rangeslider--vertical .rangeslider__handle {
    left: -10px;
    touch-action: pan-x;
    -ms-touch-action: pan-x
}

input[type=range]:focus + .rangeslider .rangeslider__handle {
    -moz-box-shadow: 0 0 8px rgba(255, 0, 255, .9);
    -webkit-box-shadow: 0 0 8px rgba(255, 0, 255, .9);
    box-shadow: 0 0 8px rgba(255, 0, 255, .9)
}

.range-output {
    font-size: 18px;
    font-weight: 400;
    color: #666
}

.range-output:after {
    content: "km"
}

.data-radius-title {
    font-style: normal;
    font-size: 15px;
    color: #888;
    font-weight: 400;
    margin-top: 0;
    display: block
}

.chosen-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    zoom: 1;
    *display: inline;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.chosen-container * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.chosen-container .chosen-drop {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    z-index: 990;
    width: 100%;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    border-top: 0;
    border-radius: 0 0 4px 4px
}

.chosen-container.chosen-with-drop .chosen-drop {
    left: 0
}

.chosen-container a {
    cursor: pointer
}

.chosen-container-single .chosen-single {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0 0 0 18px;
    height: 51px;
    line-height: 50px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .08);
    background-color: #fff;
    color: #888;
    text-decoration: none;
    white-space: nowrap;
    font-weight: 500;
    border-radius: 4px
}

.chosen-container-active.chosen-with-drop .chosen-single {
    border-radius: 4px 4px 0 0
}

.chosen-container-active .chosen-drop,
.chosen-container-single .chosen-drop {
    opacity: 0;
    visibility: hidden;
    transition: all .3s
}

.chosen-container-active.chosen-with-drop .chosen-drop {
    opacity: 1;
    visibility: visible
}

.chosen-container-single .chosen-default {
    color: #999
}

.chosen-container-single .chosen-single span {
    display: block;
    overflow: hidden;
    margin-right: 26px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #888
}

.chosen-container-single .chosen-single-with-deselect span {
    margin-right: 38px
}

.chosen-container-single .chosen-single div {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 18px;
    height: 100%
}

.chosen-container-single .chosen-single div:after {
    content: "\f107";
    font-family: fontawesome;
    font-size: 18px;
    margin: 1px 0 0;
    right: 20px;
    position: relative;
    width: auto;
    height: auto;
    display: inline-block;
    color: silver;
    float: right;
    font-weight: 400;
    transition: transform .3s;
    transform: translate3d(0, 0, 0) rotate(0deg)
}

.chosen-container-active.chosen-with-drop .chosen-single div:after {
    transform: translate3d(0, 0, 0) rotate(180deg)
}

.chosen-container-single .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    padding: 3px 8px;
    white-space: nowrap
}

.chosen-container-single .chosen-search input[type=text] {
    margin: 1px 0;
    padding: 6px 20px 6px 6px;
    width: 100%;
    height: 40px;
    outline: 0;
    margin-bottom: 10px !important;
    border: 1px solid #ddd !important;
    background-color: #fbfbfb !important;
    display: block;
    position: relative;
    z-index: 9;
    background-color: #fafafa;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .04)
}

.chosen-container-single .chosen-search:before {
    content: "\f002";
    font-family: fontawesome;
    font-size: 15px;
    margin: 0 15px 0 0;
    top: 12px;
    position: relative;
    width: 11px;
    height: 5px;
    display: inline-block;
    color: #aaa;
    float: right;
    font-weight: 400;
    z-index: 10;
    transition: transform .3s
}

.chosen-container-single .chosen-drop {
    margin-top: -1px;
    background-clip: padding-box
}

.chosen-container-single.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    left: -9999px
}

.chosen-container .chosen-results {
    color: #888;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 4px 8px 0;
    padding: 0 4px 0 8px;
    max-height: 300px
}

.chosen-container .chosen-results li {
    display: none;
    margin: 0;
    padding: 9px 10px;
    list-style: none;
    line-height: 15px;
    word-wrap: break-word;
    -webkit-touch-callout: none
}

.chosen-container-multi .chosen-results li {
    padding: 10px 18px;
    transition: color .3s
}

.chosen-container .chosen-results li.active-result {
    display: list-item;
    cursor: pointer
}

.chosen-container .chosen-results li.disabled-result {
    display: list-item;
    color: #ccc;
    cursor: default
}

.chosen-container .chosen-results li.highlighted {
    background-color: transparent;
    border-radius: 0;
    color: #66676b;
    transition: color .2s ease-in-out
}

.chosen-container .chosen-results li.no-results {
    color: #888;
    display: list-item;
    background-color: #fff
}

.chosen-container-multi .chosen-results li.no-results {
    background-color: #fff
}

.chosen-container .chosen-results li.group-result {
    display: list-item;
    font-weight: 700;
    cursor: default
}

.chosen-container .chosen-results li.group-option {
    padding-left: 15px
}

.chosen-container .chosen-results li em {
    font-style: normal;
    text-decoration: underline
}

.chosen-container-multi .chosen-choices {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0 0 0 18px;
    height: auto;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    text-decoration: none;
    white-space: nowrap;
    line-height: 33px;
    cursor: pointer;
    margin: 0;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06)
}

.chosen-container-multi.chosen-with-drop .chosen-choices {
    border-radius: 4px 4px 0 0
}

.chosen-container-multi .chosen-choices li {
    float: left;
    list-style: none
}

.chosen-container-multi .chosen-choices li.search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap
}

.chosen-container-multi .chosen-choices li.search-field input[type=text] {
    margin: 0;
    padding: 0;
    height: 49px;
    outline: 0;
    border: 0 !important;
    background: 0 0 !important;
    color: #888;
    line-height: normal;
    font-weight: 500;
    box-shadow: none;
    transition: none
}

.chosen-container-multi .chosen-choices li.search-choice {
    position: relative;
    margin: 10px 6px 0 -4px;
    padding: 0 25px 0 8px;
    max-width: 100%;
    background-color: #66676b;
    line-height: 29px;
    color: #fff;
    cursor: default;
    font-weight: 500;
    border-radius: 3px
}

.chosen-container-multi .chosen-choices li.search-choice span {
    word-wrap: break-word
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    position: absolute;
    top: -2px;
    right: 7px;
    display: block
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before {
    content: "\f00d";
    font-family: fontawesome;
    font-size: 13px;
    top: 1px;
    position: relative;
    width: 11px;
    height: 5px;
    display: inline-block;
    color: #fff;
    float: right;
    font-weight: 400
}

.chosen-container-multi .chosen-choices li.search-choice-disabled {
    padding-right: 5px;
    border: 1px solid #ccc;
    background-color: #e4e4e4;
    color: #888
}

.chosen-container-multi .chosen-choices li.search-choice-focus {
    background: #d4d4d4
}

.chosen-container-multi .chosen-results {
    margin: 0;
    padding: 0
}

.chosen-container-multi .chosen-drop .result-selected {
    display: list-item;
    color: #ccc;
    cursor: default
}

.chosen-container.chosen-container-multi .chosen-drop {
    opacity: 0;
    visibility: hidden;
    padding: 10px 0
}

.chosen-container.chosen-container-multi.chosen-with-drop.chosen-container-active .chosen-drop {
    opacity: 1;
    visibility: visible
}

.chosen-container-active .chosen-single,
.chosen-container-active .chosen-choices,
.chosen-container-active.chosen-with-drop .chosen-single {
    border: 1px solid #e0e0e0
}

.chosen-container-active.chosen-with-drop .chosen-single div {
    border-left: 0;
    background: 0 0
}

.chosen-container-active .chosen-choices li.search-field input[type=text] {
    color: #888 !important
}

.chosen-drop ::-webkit-scrollbar {
    width: 10px
}

.chosen-drop ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, .05);
    border-radius: 10px
}

.chosen-drop ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .2)
}

.chosen-drop ::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .3)
}

.input-with-icon {
    position: relative
}

.input-with-icon i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    padding: 0 0 0 19px;
    color: #999;
    transition: .2s;
    pointer-events: none
}

.input-with-icon input {
    padding-left: 47px !important
}

.input-with-icon.location input {
    padding-left: 20px !important
}

.input-with-icon.location a i {
    position: absolute;
    right: 1px;
    left: auto;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    padding: 15px 20px 15px 15px;
    background: #fff;
    pointer-events: all
}

.input-with-icon.location a i:hover {
    color: #66676b
}

.sort-by {
    position: relative;
    top: 9px
}

.sort-by label,
.sort-by .sort-by-select {
    display: inline-block;
    float: right
}

.sort-by-select select {
    border-radius: 50px;
    color: rgba(153, 1, 52, 1);
    border: 0;
    background-color: white;
    height: auto;
    padding: 7px 7px 7px 15px;
    line-height: 22px;
    box-shadow: none
}

.sort-by label {
    line-height: 40px;
    margin-top: 1px;
    padding: 0
}

.sort-by .chosen-container-single .chosen-single {
    height: 36px;
    line-height: 34px;
    border: 1px solid #fff;
    background-color: #fff;
    width: 100%;
    display: inline-block;
    padding: 0 14px 0 16px;
    box-shadow: none;
    color: #666;
    font-weight: 600;
    font-size: 15px;
    border-radius: 50px;
    transition: all .3s;
    display: inline-block
}

.sort-by .chosen-container-active .chosen-choices,
.sort-by .chosen-container-active.chosen-with-drop .chosen-single,
.sort-by .chosen-single:hover {
    background: #f3f3f3
}

.sort-by .chosen-container .chosen-drop {
    padding-top: 10px;
    border: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    border-radius: 3px;
    width: 200px;
    margin-left: 10px;
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .08);
    z-index: 999
}

.sort-by .chosen-container.chosen-with-drop .chosen-drop,
.sort-by .chosen-container .chosen-drop {
    left: auto;
    right: -1px
}

.sort-by .chosen-container-single .chosen-single div:after {
    color: #66676b;
    right: 0
}

.sort-by .chosen-container-single .chosen-default {
    color: #66676b
}

.sort-by .chosen-container-single .chosen-single span {
    margin-right: 0;
    color: #666;
    font-weight: 600;
    transition: all .2s
}

.sort-by .chosen-container-single .chosen-single div {
    position: relative;
    line-height: 33px;
    top: 0;
    margin-left: 0;
    display: inline-block
}

.sort-by .chosen-container-single .chosen-single span {
    display: inline-block;
    margin-right: 0;
    vertical-align: top
}

.main-search-input-item .chosen-container-single .chosen-single,
.main-search-input-item .chosen-single.chosen-container-active {
    position: relative;
    display: block;
    overflow: visible;
    padding: 0 0 0 15px;
    height: 44px;
    line-height: 44px;
    border: 1px solid #fff;
    background-color: #fff;
    color: #888;
    border-radius: 4px 4px 0 0;
    z-index: 100;
    box-shadow: none;
    transition: all .3s
}

.main-search-input-item .chosen-container-active .chosen-single,
.main-search-input-item .chosen-container-active .chosen-choices {
    border: 1px solid #fff
}

.main-search-input-item .chosen-container-active.chosen-with-drop .chosen-single {
    border: 1px solid #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .15)
}

.main-search-input-item .chosen-container {
    font-size: 16px
}

.main-search-input-item .chosen-container .chosen-drop {
    border: 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .15);
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 99
}

.main-search-input-item .chosen-single:after {
    content: "";
    position: absolute;
    bottom: -5px;
    display: block;
    width: 100%;
    left: 0;
    height: 10px;
    background: #fff;
    z-index: 112;
    opacity: 1;
    tranition: all .3s
}

.main-search-input-item .chosen-container-single .chosen-single span {
    display: inline-block;
    vertical-align: top
}

.main-search-input .chosen-container-single .chosen-single div {
    display: inline-block;
    position: relative;
    top: -2px
}

.main-search-input .chosen-container-single .chosen-search {
    padding: 3px 15px;
    margin-bottom: 5px
}

.main-search-input .chosen-container-single .chosen-search input[type=text] {
    margin: 1px 0;
    padding: 6px 20px 6px 6px;
    width: 100%;
    height: 40px;
    outline: 0;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    display: block;
    position: relative;
    z-index: 9;
    background-color: #fcfcfc;
    border-radius: 4px
}

.main-search-input-item .chosen-container .chosen-results {
    position: relative;
    z-index: 10000
}

.gray-style .main-search-input-item .chosen-container-single .chosen-single,
.gray-style .main-search-input-item .chosen-single.chosen-container-active {
    border: 1px solid #f7f7f7;
    background-color: #f7f7f7
}

.main-search-input-item .chosen-container.chosen-with-drop {
    border-radius: 4px
}

.gray-style .main-search-input-item .chosen-single::after {
    display: none
}

.gray-style .main-search-input-item .chosen-container-active.chosen-with-drop .chosen-single,
.gray-style .main-search-input-item .chosen-container .chosen-drop {
    box-shadow: none;
    border: 1px solid #e0e0e0;
    background: #fff
}

@media (min-width: 991px) {
    .gray-style .main-search-input-item .chosen-single {
        border-bottom: 0 !important
    }
}

.gray-style .main-search-input-item .chosen-container .chosen-drop {
    border-top: 0
}

.select-input {
    position: relative
}

.select-input input {
    position: relative;
    z-index: 101
}

.select-input .select {
    position: absolute;
    top: -8px
}

.select-hidden {
    display: none;
    visibility: hidden
}

.select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 15px;
    color: #fff;
    width: 100%;
    height: 51px
}

.select-styled {
    opacity: 0
}

.select-options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 990;
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid #e0e0e0;
    background-color: #fcfcfc;
    padding: 0 4px;
    padding-top: 8px;
    border-top: 0;
    border-radius: 0 0 4px 4px;
    box-sizing: border-box
}

.select-list-container {
    padding: 0 4px;
    max-height: 300px;
    overflow: auto;
    display: inline-block;
    width: 100%
}

ul.select-options li {
    margin: 0;
    padding: 0;
    transition: all .15s ease-in;
    color: #888;
    margin: 0;
    padding: 10px;
    list-style: none;
    line-height: 15px;
    border-radius: 4px
}

.select-options li:hover {
    color: #fff;
    background-color: #66676b
}

.select-options li[rel=hide] {
    display: none
}

.select-options ::-webkit-scrollbar {
    width: 10px
}

.select-options ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, .05);
    border-radius: 10px
}

.select-options ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .2)
}

.select-options ::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .3)
}

.panel-dropdown {
    position: relative;
    display: inline-block
}

.panel-dropdown a {
    color: #666;
    font-weight: 600;
    font-size: 15px;
    border-radius: 50px;
    padding: 3px 14px;
    transition: all .3s;
    display: inline-block
}

.panel-dropdown.active a,
.panel-dropdown a:hover {
    background: #f3f3f3
}

.panel-dropdown a:after {
    content: "\f107";
    font-family: fontawesome;
    font-size: 16px;
    color: #66676b;
    font-weight: 500;
    padding-left: 0;
    transition: all .3s;
    margin-left: 6px;
    display: inline-block
}

.panel-dropdown.active a:after {
    transform: rotate(180deg)
}

.panel-dropdown .panel-dropdown-content {
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    position: absolute;
    top: 44px;
    left: 0;
    z-index: 110;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .18);
    border-radius: 4px;
    box-shadow: 0 12px 35px 2px rgba(0, 0, 0, .12);
    padding: 20px 24px;
    overflow-y: auto;
    white-space: normal;
    width: 460px
}

.panel-dropdown.wide .panel-dropdown-content {
    width: 570px
}

.panel-dropdown.active .panel-dropdown-content {
    opacity: 1;
    visibility: visible
}

.listings-container {
    opacity: 1;
    transition: all .3s
}

.fs-inner-container.content.faded-out .listings-container {
    opacity: .2;
    background: #fff;
    pointer-events: none
}

.fs-inner-container.content.faded-out {
    background-color: #fcfcfc
}

.panel-dropdown .checkboxes label {
    margin-bottom: 10px;
    display: block
}

.panel-dropdown-content.checkboxes {
    padding: 28px
}

.panel-dropdown .row {
    margin-left: -10px;
    margin-right: -10px
}

button.panel-cancel,
button.panel-apply {
    background: 0 0;
    padding: 5px 16px;
    display: block;
    outline: 0;
    border: 0;
    font-weight: 600;
    float: right;
    margin: 0;
    font-size: 15px;
    border-radius: 50px;
    background-color: #66676b;
    color: #fff;
    transition: all .3s
}

button.panel-apply:hover {
    opacity: .9
}

button.panel-apply:active {
    transform: translateY(-2px)
}

button.panel-cancel {
    float: left;
    background-color: white;
    color: #666;
    opacity: 1
}

button.panel-cancel:hover {
    background-color: #e9e9e9
}

button.panel-cancel:active {
    transform: translateY(-2px)
}

.panel-buttons {
    width: 100%;
    margin-top: 13px
}

.panel-dropdown.float-right {
    float: right
}

.panel-dropdown.float-right .panel-dropdown-content {
    left: auto;
    right: 0
}

.fullwidth-filters .panel-dropdown {
    margin-left: 3px
}

.fullwidth-filters {
    display: inline-block;
    width: 100%;
    position: relative;
    top: 15px
}

.fullwidth-filters .sort-by {
    top: -1px;
    right: -1px
}

.more-search-options.relative {
    position: relative;
    margin-top: 0;
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: none;
    display: none
}

.more-search-options.relative.active {
    transform: translate3d(0, 0, 0)
}

.more-search-options-trigger {
    color: #666;
    font-weight: 500;
    font-size: 15px;
    border-radius: 3px;
    padding: 3px 0;
    transition: all .3s;
    display: inline-block
}

.more-search-options-trigger:before {
    content: attr(data-open-title)
}

.more-search-options-trigger.active:before {
    content: attr(data-close-title)
}

.more-search-options-trigger:after {
    content: "\f107";
    font-family: fontawesome;
    font-size: 16px;
    color: #66676b;
    font-weight: 500;
    padding-left: 0;
    transition: all .3s;
    margin-left: 6px;
    display: inline-block
}

.more-search-options-trigger.active:after {
    transform: rotate(180deg)
}

.more-search-options-trigger:hover {
    color: #444
}

.layout-switcher a {
    width: 39px;
    height: 39px;
    background-color: #eee;
    display: inline-block;
    color: #9d9d9d;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    transition: all .4s;
    border-radius: 50%;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    margin-right: 1px
}

.layout-switcher a.active {
    color: #66676b;
    background-color: #fff;
    border: 1px solid #66676b;
    line-height: 38px
}

.layout-switcher a i {
    position: relative;
    z-index: 11
}

.layout-switcher a:hover {
    color: #fff;
    background-color: #66676b
}

.fs-container {
    height: 100%;
    width: 100%
}

.fs-container #map-container {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 990;
    margin-bottom: 0
}

.fs-content {
    max-height: 100%;
    padding: 0
}

.fs-container section.search {
    padding: 45px 40px;
    background-color: #fff
}

.fs-container .fs-listings {
    padding: 0 25px;
    margin: 0
}

.fs-switcher {
    padding: 0 25px;
    margin: 0
}

.fs-inner-container {
    width: 50%;
    float: left;
    display: inline-block
}

.fs-inner-container.content {
    width: 55%;
    background-color: #f7f7f7;
    z-index: 995;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .12);
    position: relative;
    padding-top: 80px
}

.fs-inner-container {
    width: 45%;
    background-color: #fff;
    z-index: 10
}

.fs-container .copyrights {
    padding: 30px 0;
    font-size: 15px
}

#header-container.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1100;
    background-color: #fff
}

.fs-inner-container.map-fixed {
    height: 100vh;
    position: fixed;
    top: 0;
    padding-top: 80px
}

button.fs-map-btn.button {
    position: relative;
    float: right;
    top: 6px;
    padding: 10px 40px;
    margin: 0
}

.fs-container .more-search-options-trigger {
    position: relative;
    top: 10px
}

.showing-results {
    color: #888;
    font-weight: 500;
    margin: 5px 0 25px
}

.listings-container.fs-listings {
    left: 0;
    width: 100%;
    position: relative
}

.listings-container.fs-listings.grid-layout {
    left: 0;
    width: 100%;
    position: relative
}

.listings-container.fs-listings.list-layout {
    padding: 0 40px
}

.listings-container.fs-listings.list-layout .listing-content {
    flex: 4
}

.listings-container.fs-listings.list-layout .listing-img-container {
    flex: 0 auto;
    max-width: 300px
}

@media (max-width: 1365px) {
    .listings-container.fs-listings.grid-layout .listing-item {
        width: 100%;
        margin: 0 0 30px
    }

    .listings-container.fs-listings.grid-layout {
        padding: 0 40px
    }

    .listings-container.fs-listings.list-layout .listing-item {
        display: block
    }

    .listings-container.fs-listings.list-layout .details.button.border {
        display: none
    }

    .listings-container.fs-listings.list-layout .listing-img-container {
        max-width: 100%
    }

    .fs-switcher .layout-switcher {
        display: none
    }
}

.col-fs-12,
.col-fs-11,
.col-fs-10,
.col-fs-9,
.col-fs-8,
.col-fs-7,
.col-fs-6,
.col-fs-5,
.col-fs-4,
.col-fs-3,
.col-fs-2,
.col-fs-1 {
    float: left;
    padding: 0 10px
}

.col-fs-12 {
    width: 100%
}

.col-fs-11 {
    width: 91.66666667%
}

.col-fs-10 {
    width: 83.33333333%
}

.col-fs-9 {
    width: 75%
}

.col-fs-8 {
    width: 66.66666667%
}

.col-fs-7 {
    width: 58.33333333%
}

.col-fs-6 {
    width: 50%
}

.col-fs-5 {
    width: 41.66666667%
}

.col-fs-4 {
    width: 33.33333333%
}

.col-fs-3 {
    width: 25%
}

.col-fs-2 {
    width: 16.66666667%
}

.col-fs-1 {
    width: 8.33333333%
}

@media (max-width: 1599px) {
    .col-fs-12,
    .col-fs-11,
    .col-fs-10,
    .col-fs-9,
    .col-fs-8,
    .col-fs-7,
    .col-fs-6 {
        width: 100%
    }

    .col-fs-5,
    .col-fs-4,
    .col-fs-3,
    .col-fs-2,
    .col-fs-1 {
        width: 50%
    }
}

@media (max-width: 992px) {
    .col-fs-12,
    .col-fs-11,
    .col-fs-10,
    .col-fs-9,
    .col-fs-8,
    .col-fs-7,
    .col-fs-6,
    .col-fs-5,
    .col-fs-4,
    .col-fs-3,
    .col-fs-2,
    .col-fs-1 {
        width: 100%
    }

    .overflow-hidden {
        overflow: hidden
    }
}

.row.with-forms input,
.row.with-forms input[type=text],
.row.with-forms input[type=password],
.row.with-forms input[type=email],
.row.with-forms input[type=number],
.row.with-forms textarea,
.row.with-forms input,
.row.with-forms select,
.row.with-forms .chosen-container {
    margin-bottom: 18px
}

.row.with-forms {
    margin-left: -10px;
    margin-right: -10px
}

.with-forms .col-xs-1,
.with-forms .col-sm-1,
.with-forms .col-md-1,
.with-forms .col-lg-1,
.with-forms .col-xs-2,
.with-forms .col-sm-2,
.with-forms .col-md-2,
.with-forms .col-lg-2,
.with-forms .col-xs-3,
.with-forms .col-sm-3,
.with-forms .col-md-3,
.with-forms .col-lg-3,
.with-forms .col-xs-4,
.with-forms .col-sm-4,
.with-forms .col-md-4,
.with-forms .col-lg-4,
.with-forms .col-xs-5,
.with-forms .col-sm-5,
.with-forms .col-md-5,
.with-forms .col-lg-5,
.with-forms .col-xs-6,
.with-forms .col-sm-6,
.with-forms .col-md-6,
.with-forms .col-lg-6,
.with-forms .col-xs-7,
.with-forms .col-sm-7,
.with-forms .col-md-7,
.with-forms .col-lg-7,
.with-forms .col-xs-8,
.with-forms .col-sm-8,
.with-forms .col-md-8,
.with-forms .col-lg-8,
.with-forms .col-xs-9,
.with-forms .col-sm-9,
.with-forms .col-md-9,
.with-forms .col-lg-9,
.with-forms .col-xs-10,
.with-forms .col-sm-10,
.with-forms .col-md-10,
.with-forms .col-lg-10,
.with-forms .col-xs-11,
.with-forms .col-sm-11,
.with-forms .col-md-11,
.with-forms .col-lg-11,
.with-forms .col-xs-12,
.with-forms .col-sm-12,
.with-forms .col-md-12,
.with-forms .col-lg-12 {
    padding: 0 10px
}

.comment-by-listing {
    display: inline-block;
    color: #888
}

.comment-by-listing a {
    color: #888
}

.comment-by-listing a:hover {
    color: #66676b
}

.verified-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 22px;
    color: #19b453;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    position: relative;
    top: 3px;
    margin-left: 2px
}

.verified-icon:before {
    content: "\f058";
    font-family: fontawesome;
    position: relative;
    z-index: 11;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block
}

.verified-icon:after {
    position: relative;
    z-index: 9;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    display: block;
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: #fff
}

.verified-badge {
    background-color: #19b453;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    padding: 12px 20px;
    font-size: 17px;
    font-weight: 500;
    cursor: help;
    position: relative;
    transition: .3s;
    display: block
}

.verified-badge i {
    font-size: 22px;
    padding: 0 4px 0 0;
    position: relative;
    top: 3px
}

.verified-badge .tip-content {
    transform: translate(-50%, 100%);
    -ms-transform: translate(-50%, 100%);
    padding: 20px;
    border-radius: 0 0 4px 4px;
    max-width: auto;
    top: auto;
    bottom: 0
}

.verified-badge:hover {
    border-radius: 4px 4px 0 0
}

.verified-badge:hover .tip-content {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 100%);
    -ms-transform: translate(-50%, 100%)
}

.verified-badge .tip-content:after {
    font-family: fontawesome;
    content: "\f0d8";
    position: absolute;
    top: -3px;
    bottom: auto;
    left: 50%;
    font-size: 22px;
    color: #333;
    transform: translate(-50%, -50%)
}

.user-profile-titlebar {
    position: relative;
    margin-top: 3px;
    display: inline-block
}

.user-profile-avatar {
    max-width: 100px;
    height: auto;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: -12px;
    image-rendering: -webkit-optimize-contrast
}

.user-profile-avatar img {
    width: 100%
}

.user-profile-name {
    position: relative;
    left: 127px
}

#titlebar .user-profile-name h2 {
    font-size: 28px;
    display: inline-block;
    margin-bottom: -3px
}

.user-profile-titlebar .star-rating .rating-counter {
    top: 5px;
    font-size: 16px
}

.browse-all-user-listings {
    text-align: center
}

.browse-all-user-listings a {
    position: relative;
    font-weight: 600;
    font-size: 15px;
    left: 0;
    z-index: 10;
    text-align: center;
    display: inline-block;
    opacity: 1;
    visibility: visible;
    transition: all .3s;
    padding: 8px 25px;
    color: #666;
    background-color: #f2f2f2;
    border-radius: 50px;
    margin: 15px 0 0
}

.browse-all-user-listings a i {
    padding-left: 5px;
    color: #66676b;
    font-size: 16px
}

.browse-all-user-listings a:hover {
    color: #333
}

@media (max-width: 768px) {
    body .user-profile-avatar {
        max-width: 60px;
        top: 2px
    }

    body .user-profile-name {
        position: relative;
        left: 75px
    }

    #titlebar .user-profile-name h2 {
        font-size: 22px
    }

    #titlebar span.star {
        font-size: 14px
    }

    .user-profile-titlebar .star-rating .rating-counter {
        top: 5px;
        font-size: 14px
    }

    .user-profile-titlebar .star-rating {
        margin-top: -9px
    }
}

@media (max-width: 992px) {
    .user-profile-avatar {
        max-width: 90px;
        top: -7px
    }

    .user-profile-name {
        position: relative;
        left: 115px
    }
}

.payment-logo {
    height: 24px;
    position: absolute;
    right: 19px;
    top: 18px;
    image-rendering: -webkit-optimize-contrast
}

.payment-logo.paypal {
    height: 16px;
    top: 22px
}

.payment-tab-trigger > input {
    position: absolute;
    visibility: hidden
}

.payment-tab-trigger {
    padding: 0 20px
}

.payment-tab-trigger > label {
    cursor: pointer;
    display: block;
    position: relative;
    top: 0;
    padding: 14px 0;
    top: 1px;
    margin-bottom: 0;
    font-weight: 500
}

.payment-tab-trigger > label::before {
    transition: .2s;
    box-sizing: border-box;
    background-color: #fff;
    border: 2px solid #d0d0d0;
    border-radius: 100%;
    content: "";
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 4px;
    width: 20px;
    height: 20px
}

.payment-tab-trigger > label::after {
    background: #fff;
    border-radius: 100%;
    content: "";
    display: inline-block;
    position: absolute;
    left: 7px;
    top: 25px;
    width: 6px;
    height: 6px;
    transition: .2s;
    transform: scale(0)
}

.payment-tab-trigger > input:checked ~ label::before {
    border-color: #66676b
}

.payment-tab-trigger > input:checked ~ label::after {
    background-color: #66676b;
    transform: scale(1)
}

.payment {
    border: 2px solid #f2f2f2;
    border-radius: 4px;
    overflow: hidden
}

.payment-tab {
    background: #f2f2f2;
    box-sizing: border-box;
    max-height: 60px;
    overflow: hidden;
    position: relative;
    transition: all .3s 0s ease-in-out;
    width: 100%
}

.payment-tab:first-of-type {
    border-radius: 4px 4px 0 0
}

.payment-tab:last-of-type {
    border-radius: 0 0 4px 4px
}

.payment-tab.payment-tab-active {
    max-height: 400px;
    background: #fff
}

@media (max-width: 991px) {
    .payment-tab.payment-tab-active {
        max-height: 800px
    }
}

.payment-tab-content {
    padding: 0 20px 20px;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: .2s ease-in-out
}

.payment-tab.payment-tab-active .payment-tab-content {
    visibility: visible;
    opacity: 1
}

.card-label > input {
    box-sizing: border-box;
    display: block;
    height: 51px;
    margin: -14px auto 5px;
    padding: 5px 15px 0;
    transition: border-color .3s;
    width: 100%;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    text-decoration: none;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06)
}

.card-label > label {
    background-color: #fff;
    display: inline-block;
    margin: 6px auto auto 8px;
    font-size: 13px;
    padding: 0 7px;
    font-weight: 500;
    color: #909090
}

.payment-tab p {
    margin: 10px 0
}

.opening-hours.summary li {
    color: #333
}

.boxed-widget.summary {
    border-radius: 0 0 4px 4px
}

.opening-hours.summary li:hover {
    color: #66676b
}

.boxed-widget.summary h3 {
    padding: 0 0 25px
}

.opening-hours.summary li.total-costs {
    font-size: 16px;
    border-top: 1px solid #e8e8e8;
    padding-top: 18px;
    margin-top: 18px
}

.opening-hours.summary li.total-costs span {
    font-weight: 600;
    color: #66676b
}

.listing-item-container.compact.order-summary-widget .listing-item {
    border-radius: 4px 4px 0 0;
    cursor: default;
    height: 240px
}

.listing-item-container.compact.order-summary-widget {
    margin-bottom: 0
}

.listing-item-container.compact.order-summary-widget:hover {
    transform: none
}

.input-with-icon.medium-icons i {
    font-size: 21px;
    color: #a0a0a0;
    position: absolute;
    left: -3px;
    bottom: 0;
    top: auto
}

.button.booking-confirmation-btn {
    padding: 12px 35px;
    font-size: 16px
}

.booking-confirmation-page {
    text-align: center;
    padding: 40px 0
}

.booking-confirmation-page i {
    color: #23b35f;
    font-size: 160px
}

.booking-confirmation-page h2 {
    font-size: 50px;
    font-weight: 700;
    letter-spacing: -.5px;
    margin-bottom: 15px;
    display: block
}

.booking-confirmation-page p {
    font-size: 20px;
    display: block
}

.booking-confirmation-page a.button:before {
    display: none
}

.booking-confirmation-page a.button {
    padding: 11px 35px;
    background-color: #eee;
    color: #444;
    font-weight: 600;
    font-size: 16px
}

.book-now.button {
    padding: 9px;
    margin-bottom: -4px
}

@media (max-width: 991px) {
    .booking-confirmation-page i {
        font-size: 100px
    }

    .booking-confirmation-page p {
        font-size: 18px
    }

    .booking-confirmation-page h2 {
        font-size: 30px
    }
}

.booking-select {
    position: relative;
    z-index: 100
}

input#booking-date,
input#booking-time {
    z-index: 110
}

.booking-select select,
.booking-select .chosen-container-single .chosen-single {
    border: 0;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .1);
    font-size: 16px;
    font-weight: 600;
    height: auto;
    padding: 10px 16px;
    line-height: 30px;
    margin: 0;
    position: relative;
    background-color: #fff;
    text-align: left;
    color: #909090;
    transition: color .3s
}

.booking-select select {
    padding: 15px 16px
}

.booking-select .chosen-container-active.chosen-with-drop .chosen-single {
    border-radius: 5px 5px 0 0
}

.booking-select .chosen-container .chosen-drop {
    border: 0;
    background-color: #fff;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .1);
    z-index: 100
}

.booking-select .chosen-single:after {
    content: "";
    position: absolute;
    bottom: -5px;
    display: block;
    width: 100%;
    left: 0;
    height: 10px;
    background: #fff;
    z-index: 112;
    opacity: 1;
    tranition: all .3s
}

.booking-select .chosen-container .chosen-results li {
    padding: 10px;
    line-height: 16px;
    font-size: 16px
}

.booking-select .chosen-container-single .chosen-single span {
    transition: .2s
}

.booking-select .chosen-container-single .chosen-single:hover span {
    color: #444
}

.booking-select .chosen-container-single .chosen-single div:after {
    font-size: 20px;
    margin: 9px 0 0
}

#titlebar.listing-titlebar span a,
#titlebar.listing-titlebar span {
    font-size: 17px;
    color: #888;
    margin-top: 0;
    display: inline-block
}

#titlebar.listing-titlebar span a i {
    margin-right: 5px
}

#titlebar.listing-titlebar span a:hover {
    color: #666
}

#titlebar.listing-titlebar h1,
#titlebar.listing-titlebar h2 {
    font-size: 28px;
    line-height: 36px;
    margin: 1px 0
}

#titlebar.listing-titlebar span.listing-tag {
    margin: 0;
    padding: 8px 16px;
    line-height: 15px;
    letter-spacing: 2.5px;
    border-radius: 50px;
    text-transform: uppercase;
    background-color: #fff;
    border: 1px solid #66676b;
    color: #66676b;
    font-size: 10px;
    font-weight: 500;
    position: relative;
    top: -6px;
    margin-left: 8px
}

.rating-counter a {
    color: #909090
}

.star-rating .rating-counter a:hover {
    color: #66676b
}

.numerical-rating .rating-counter a:hover {
    color: #888
}

#titlebar.listing-titlebar .numerical-rating {
    display: block;
    margin: 2px 0 0
}

#titlebar.listing-titlebar {
    background: 0 0;
    margin: 2px 0 0
}

.single-listing-page-titlebar {
    position: absolute;
    width: 100%;
    height: 200px;
    background: linear-gradient(to bottom, #f7f7f7 0%, rgba(255, 255, 255, 0.5))
}

a.button.add-review-btn {
    min-width: 160px;
    text-align: center
}

a.button.add-review-btn i {
    font-size: 14px;
    position: relative;
    line-height: 0;
    top: 0;
    margin-right: 0;
    letter-spacing: 0
}

.back-to-listings {
    height: 60px;
    width: 60px;
    background-color: #eee;
    color: #a6a6a6;
    border-radius: 50%;
    display: inline-block;
    float: left;
    position: relative;
    top: 6px;
    margin: 0 25px 0 0;
    text-align: center;
    transition: all .4s;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -5px;
    transform: translate(0, -50%)
}

.back-to-listings:before {
    font-family: simple-line-icons;
    content: "\e605";
    font-size: 20px;
    line-height: 60px;
    left: -1px;
    position: relative
}

.back-to-listings:hover {
    color: #a6a6a6;
    transform: translate(-4px, -50%)
}

.titlebar-right-content {
    position: absolute;
    right: 15px;
    display: inline-block;
    top: 50%;
    transform: translateY(-49%);
    text-align: right
}

.hosted-by-title {
    padding: 0 70px 18px 0;
    margin: -4px 0 25px;
    display: block;
    border-bottom: 1px solid #e8e8e8;
    position: relative
}

.hosted-by-title h4 {
    font-size: 18px;
    transition: .2s;
    line-height: 22px;
    font-weight: 500;
    margin: 4px 0 7px
}

.hosted-by-title h4 a:hover {
    color: #66676b
}

.hosted-by-title h4 span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #888;
    margin-bottom: 2px
}

.hosted-by-avatar {
    max-width: 56px;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: -8px;
    image-rendering: -webkit-optimize-contrast
}

.listing-slider-small .item,
.listing-slider .item {
    position: relative;
    background-size: cover;
    background-position: 50%;
    height: 40vh;
    min-height: 400px
}

.listing-slider,
.listing-slider .item {
    height: 40vh;
    min-height: 400px
}

.listing-slider-small .item {
    height: 250px;
    min-height: auto
}

@media (max-width: 1366px) {
    .listing-slider-small .item {
        height: 220px
    }
}

@media (max-width: 1240px) {
    .listing-slider-small .item {
        height: 200px
    }
}

@media (max-width: 768px) {
    .listing-slider-small .item {
        height: 300px
    }
}

.listing-slider-small .item:last-child {
    border-radius: 0 4px 4px 0
}

.listing-slider-small {
    border-radius: 4px;
    overflow: hidden
}

a.mfp-gallery {
    cursor: zoom-in
}

.listing-slider {
    background-color: #fff
}

.listing-slider .slick-next:hover,
.listing-slider .slick-prev:hover,
.listing-slider .slick-next,
.listing-slider .slick-prev {
    background-color: rgba(20, 20, 20, .45);
    color: #fff;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    text-align: center;
    transition: .4s
}

.listing-slider-small .slick-next:hover,
.listing-slider-small .slick-prev:hover,
.listing-slider-small .slick-next,
.listing-slider-small .slick-prev {
    background-color: rgba(20, 20, 20, .45);
    color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    transition: .4s
}

.listing-slider-small .slick-next:hover,
.listing-slider-small .slick-next,
.listing-slider .slick-next:hover,
.listing-slider .slick-next {
    right: 15%;
    transform: translate(50px, -50%)
}

.listing-slider-small .slick-prev:hover,
.listing-slider-small .slick-prev,
.listing-slider .slick-prev:hover,
.listing-slider .slick-prev {
    left: 15%;
    transform: translate(-50px, -50%)
}

.listing-slider-small .slick-next:hover,
.listing-slider-small .slick-next {
    right: 50px;
    transform: translate(0, -50%)
}

.listing-slider-small .slick-prev:hover,
.listing-slider-small .slick-prev {
    left: 50px;
    transform: translate(0, -50%)
}

.listing-slider-small:hover .slick-next {
    transform: translate(15%, -50%)
}

.listing-slider-small:hover .slick-prev {
    transform: translate(-15%, -50%)
}

.listing-slider:hover .slick-next {
    transform: translate(60px, -50%)
}

.listing-slider:hover .slick-prev {
    transform: translate(-60px, -50%)
}

.listing-slider .slick-next:hover:before,
.listing-slider .slick-prev:hover:before,
.listing-slider .slick-prev::before,
.listing-slider .slick-next::before {
    font-size: 20px;
    line-height: 60px;
    position: relative;
    color: #fff
}

.listing-slider-small .slick-next:hover:before,
.listing-slider-small .slick-prev:hover:before,
.listing-slider-small .slick-prev::before,
.listing-slider-small .slick-next::before {
    font-size: 18px;
    line-height: 50px;
    position: relative;
    color: #fff
}

.listing-slider-small .slick-prev::before,
.listing-slider .slick-prev::before {
    left: -1px
}

.listing-slider-small .slick-next::before,
.listing-slider .slick-next::before {
    left: 1px
}

.listing-slider .slick-next:hover {
    transform: translate(64px, -50%)
}

.listing-slider .slick-prev:hover {
    transform: translate(-64px, -50%)
}

.listing-slider .item.slick-slide:before {
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    content: "";
    background: #222;
    z-index: 100;
    visibility: visible;
    opacity: .1;
    transition: .3s ease-in-out
}

.listing-slider .item.slick-slide:hover:before,
.listing-slider-small .slick-next,
.listing-slider-small .slick-prev,
.listing-slider .slick-next,
.listing-slider .slick-prev {
    opacity: 0;
    visibility: hidden
}

.listing-slider-small:hover .slick-next,
.listing-slider-small:hover .slick-prev,
.listing-slider:hover .slick-next,
.listing-slider:hover .slick-prev {
    opacity: 1;
    visibility: visible
}

.listing-slider-small .slick-next:hover,
.listing-slider-small .slick-prev:hover,
.listing-slider .slick-next:hover,
.listing-slider .slick-prev:hover {
    background-color: #66676b;
    color: #fff
}

.listing-details-sidebar {
    padding: 0;
    list-style: none;
    font-size: 15px;
    margin-bottom: -5px;
    position: relative;
    display: block
}

.listing-details-sidebar li {
    display: block;
    padding-left: 26px;
    position: relative;
    margin-bottom: 5px;
    line-height: 24px
}

.listing-details-sidebar li a {
    color: #66676b;
    transition: .3s
}

.listing-details-sidebar li i {
    position: absolute;
    left: 0;
    top: 5px;
    font-size: 16px;
    color: #888
}

.listing-details-sidebar.social-profiles {
    margin-top: 20px
}

.listing-details-sidebar.social-profiles li {
    line-height: 22px;
    padding-left: 23px
}

.listing-details-sidebar.social-profiles li i {
    top: 4px
}

.listing-details-sidebar li a.facebook-profile i,
.listing-details-sidebar li a.facebook-profile {
    color: #3b5998
}

.listing-details-sidebar li a.twitter-profile i,
.listing-details-sidebar li a.twitter-profile {
    color: #1da1f2
}

.listing-details-sidebar li a.gplus-profile i,
.listing-details-sidebar li a.gplus-profile {
    color: #dd4b39
}

.listing-details-sidebar li a.gplus-profile {
    padding-left: 4px
}

.boxed-widget {
    background-color: #f9f9f9;
    border-radius: 3px;
    padding: 32px;
    transform: translate3d(0, 0, 0);
    z-index: 90;
    position: relative
}

.boxed-widget h3 {
    font-size: 20px;
    padding: 0 0 25px;
    margin: 0 0 25px;
    display: block;
    border-bottom: 1px solid #e8e8e8
}

.boxed-widget h3 i {
    margin-right: 4px
}

.boxed-widget h3 i.sl-icon-clock {
    position: relative;
    top: 1px
}

.boxed-widget h3 i.fa-calendar-check-o {
    position: relative;
    top: -1px
}

.booking-widget {
    z-index: 100
}

.booking-widget .panel-dropdown .panel-dropdown-content {
    border: 0;
    overflow: visible;
    padding: 20px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .1)
}

.booking-widget .panel-dropdown {
    width: 100%
}

.booking-widget .panel-dropdown .panel-dropdown-content:before {
    content: "";
    height: 10px;
    position: absolute;
    width: 100%;
    top: -3px;
    background-color: #fff;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    border-bottom: 1px solid #eee
}

.booking-widget .panel-dropdown.active .panel-dropdown-content:before {
    opacity: 1;
    visibility: visible
}

.booking-widget .panel-dropdown a {
    border: 0;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .1);
    font-size: 16px;
    font-weight: 600;
    height: auto;
    padding: 10px 16px;
    line-height: 30px;
    margin: 0 0 15px;
    position: relative;
    background-color: #fff;
    text-align: left;
    color: #888;
    display: block;
    width: 100%;
    transition: color .3s
}

.booking-widget .panel-dropdown a:hover {
    color: #444
}

.booking-widget .panel-dropdown a:after {
    font-size: 20px;
    color: silver;
    margin-left: 0;
    position: absolute;
    right: 20px
}

.qtyButtons {
    display: flex;
    margin: 0 0 13px
}

@media (min-width: 992px) and (max-width: 1366px) {
    .qtyButtons {
        display: block
    }
}

.panel-dropdown-content .qtyButtons:first-child {
    margin-top: 8px
}

.panel-dropdown-content .qtyButtons:last-child {
    margin-bottom: 3px
}

.qtyButtons input {
    outline: 0;
    font-size: 20px;
    text-align: center;
    width: 50px;
    height: 36px;
    color: #333;
    line-height: 36px;
    margin: 0 !important;
    padding: 0 5px;
    border: 0;
    box-shadow: none;
    pointer-events: none;
    display: inline-block
}

.qtyTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    padding-right: 15px;
    display: block;
    flex: 1
}

.qtyInc,
.qtyDec {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    background-color: #f2f2f2;
    -webkit-text-stroke: 1px #f2f2f2;
    color: #333;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    font-family: fontawesome
}

.qtyInc:before {
    content: "\f067"
}

.qtyDec:before {
    content: "\f068"
}

.qtyTotal {
    background-color: #66676b;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    position: relative;
    top: -2px;
    left: 2px;
    height: 18px;
    width: 18px
}

.rotate-x {
    animation-duration: .5s;
    animation-name: rotate-x
}

@keyframes rotate-x {
    from {
        transform: rotateY(0deg)
    }
    to {
        transform: rotateY(360deg)
    }
}

a.send-message-to-owner.button {
    font-size: 13px;
    font-weight: 500;
    padding: 4px 20px;
    margin-top: 20px;
    margin-bottom: -5px
}

.opening-hours {
    position: relative;
    overflow: hidden
}

.opening-hours ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px
}

.opening-hours ul li {
    width: 100%;
    font-weight: 600;
    color: #888;
    margin: 2px 0;
    transition: .2s;
    cursor: default
}

.opening-hours ul li:hover {
    color: #444
}

.opening-hours ul li span {
    float: right;
    font-weight: 400
}

.listing-share {
    text-align: center;
    position: relative
}

.listing-share .share-buttons li {
    margin: 0 3px 8px 0
}

.listing-share.with-border {
    border-top: 1px solid #eee;
    padding-top: 35px
}

.listing-share span {
    display: block;
    font-size: 15px;
    color: #888
}

.like-button {
    outline: 0;
    border: 0;
    color: #444;
    font-weight: 600;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 50px;
    padding: 10px 25px;
    line-height: 24px;
    margin-bottom: 9px;
    transition: .3s;
    cursor: pointer
}

.like-button:hover {
    background-color: #fafafa;
    border: 1px solid #ddd
}

.listing-share .like-icon::before,
.listing-share .like-icon::after {
    top: 0
}

.listing-share .social-icons li {
    text-align: center;
    float: none;
    padding: 0;
    margin: 0
}

.like-button .like-icon:hover,
.like-button .like-icon.liked {
    background-color: transparent;
    color: #f3103c
}

.like-button .like-icon {
    position: relative;
    height: 20px;
    display: inline-block;
    line-height: 30px;
    right: auto;
    bottom: 3px;
    background: 0 0;
    color: #f3103c;
    font-weight: 500;
    float: left;
    width: 20px;
    margin-right: 8px;
    font-size: 18px
}

.listing-nav {
    width: 100%;
    padding: 0;
    list-style: none;
    margin: 0;
    border-bottom: 1px solid #e0e0e0;
    font-size: 17px;
    font-weight: 400;
    background-color: #fff;
    margin-bottom: 35px
}

.listing-nav-container.cloned {
    position: fixed;
    top: 0;
    margin: 0 auto;
    z-index: 1010;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: .4s;
    transform: translateY(-100%);
    border-bottom: 1px solid #e0e0e0
}

.listing-nav-container.cloned.stick {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    background-color: #fff
}

.listing-nav-container.cloned .listing-nav {
    border-bottom: 0;
    margin: 0 auto
}

.listing-nav-container.cloned .listing-nav a {
    padding: 15px 0
}

.listing-nav-container.cloned .listing-nav li:first-child a {
    color: #666;
    border-color: #e0e0e0
}

.listing-nav li {
    display: inline-block;
    margin-right: 15px
}

.listing-nav li a {
    display: inline-block;
    border-bottom: 1px solid #e0e0e0;
    position: relative;
    bottom: -1px;
    transition: .3s;
    color: #666;
    padding-bottom: 15px
}

.listing-nav-container.cloned .listing-nav li:first-child a.active,
.listing-nav-container.cloned .listing-nav li:first-child a:hover,
.listing-nav li:first-child a,
.listing-nav li a.active,
.listing-nav li a:hover {
    border-color: #66676b;
    color: #66676b
}

.listing-desc-headline {
    font-size: 22px;
    margin-bottom: 25px;
    margin-top: 45px;
    display: block
}

.listing-desc-headline span {
    color: #888
}

.listing-section {
    display: inline-block;
    width: 100%
}

#singleListingMap {
    height: 400px;
    border-radius: 3px
}

#singleListingMap-container {
    height: auto;
    position: relative;
    padding-top: 5px;
    display: block
}

.listing-features {
    width: 100%;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    columns: 3;
    font-size: 16px;
    -webkit-columns: 3;
    -moz-columns: 3
}

.listing-features.checkboxes li {
    color: #333;
    display: block;
    position: relative;
    margin: 0 0 10px;
    padding: 0 0 0 30px
}

.listing-features li:last-child {
    margin: 0
}

.listing-features li span,
.listing-features.checkboxes li {
    color: #707070
}

.listing-features.checkboxes li.offered:before {
    display: inline-block;
    width: 19px;
    height: 19px;
    position: relative;
    content: "\f00c";
    font-family: fontawesome;
    font-size: 12px;
    color: #fff;
    text-align: center;
    line-height: 15px;
    background-color: rgba(153, 1, 52, 1);
    border: 2px solid transparent;
    transition: border-color 0s;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 3px
}


.listing-features.checkboxes li.not-offered:before {
    display: inline-block;
    width: 19px;
    height: 19px;
    position: relative;
    content: "\f00d";
    font-family: fontawesome;
    font-size: 12px;
    color: #fff;
    text-align: center;
    line-height: 15px;
    background-color: #66676b;
    border: 2px solid transparent;
    transition: border-color 0s;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 3px
}

.pricing-list-container h4 {
    color: #66676b;
    background-color: #fff;
    border-bottom: 1px solid #66676b;
    width: 100%;
    padding: 30px;
    border-radius: 4px 4px 0 0;
    position: relative;
    z-index: 10;
    margin: 5px 0 0;
    display: block;
    font-weight: 500
}

.pricing-list-container h4:after {
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-color: #66676b;
    opacity: .05;
    z-index: 9
}

.pricing-list-container ul {
    list-style: none;
    padding: 0;
    margin-bottom: 35px
}

.pricing-list-container ul:last-child {
    margin-bottom: 25px
}

.pricing-list-container ul li {
    padding: 30px;
    padding-right: 70px;
    position: relative
}

.pricing-list-container ul li:last-child {
    border-radius: 0 0 4px 4px
}

.pricing-list-container ul li:nth-child(2n+2) {
    background-color: #f9f9f9
}

.pricing-list-container p,
.pricing-list-container h5 {
    padding: 0;
    font-size: 15px;
    margin: 0;
    display: inline-block;
    width: 100%
}

.pricing-list-container h5 {
    font-size: 18px;
    margin-bottom: 1px
}

.pricing-list-container p {
    color: white;
    line-height: 24px;
    margin-top: 3px
}

.pricing-list-container span {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    font-size: 18px;
    color: white
}

.show-more {
    height: 450px;
    overflow: hidden;
    position: relative;
    transition: margin .4s
}

.show-more:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 180px;
    display: block;
    background: linear-gradient(rgba(255, 255, 255, 0), #fff 88%);
    z-index: 9;
    opacity: 1;
    visibility: visible;
    transition: .8s
}

.show-more.visible {
    margin-bottom: 20px
}

.show-more.visible:after {
    opacity: 0;
    visibility: hidden
}

.show-more-button {
    position: relative;
    font-weight: 600;
    font-size: 15px;
    left: 0;
    margin-left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    text-align: center;
    display: inline-block;
    opacity: 1;
    visibility: visible;
    transition: all .3s;
    padding: 5px 20px;
    color: #666;
    background-color: #f2f2f2;
    border-radius: 50px;
    top: -10px;
    min-width: 140px
}

.show-more-button:before {
    content: attr(data-more-title)
}

.show-more-button.active:before {
    content: attr(data-less-title)
}

.show-more-button i {
    margin-left: 6px;
    color: #66676b;
    font-weight: 500;
    transition: .2s
}

.show-more-button.active i {
    transform: rotate(180deg)
}

.comments {
    margin: -10px 0 0
}

.comments h4 {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 500;
    color: #333
}

.comments h4 span {
    display: inline-block;
    font-size: inherit
}

.comment {
    font-size: 20px
}

.comments .button {
    margin: 0 0 10px;
    padding: 7px 15px
}

.comments.reviews .button {
    margin: 0
}

.comments ul {
    padding-left: 0
}

.comments ul li {
    display: block;
    width: 100%
}

.comments ul li,
.comments ul li ul li,
.comments ul li ul li ul li,
.comments ul li ul li ul li {
    margin: 50px 0 0
}

.comments ul li ul {
    margin: 0 0 0 100px
}

.comment-content p {
    margin: 3px 0 0;
    line-height: 26px
}

.comment-content {
    color: #666;
    padding: 0 0 0 100px
}

.comments ul li ul {
    border-left: 1px solid #e0e0e0;
    padding-left: 35px
}

.comments ul li ul li:before {
    content: "";
    width: 15px;
    height: 1px;
    background-color: #e0e0e0;
    display: inline-block;
    position: absolute;
    top: 30px;
    left: -35px
}

.comments ul li {
    position: relative
}

.comment-content strong {
    padding-right: 5px;
    color: #666
}

.comment-content span {
    color: #888
}

body .comment-content p {
    padding: 5px 0;
    font-size: 15px
}

.comments-amount {
    color: #888;
    font-weight: 500
}

.avatar {
    display: inline-block;
    float: left;
    padding: 0 20px 0 0;
    position: absolute;
    left: 0
}

.avatar img {
    max-width: 80px;
    border-radius: 50%
}

.comment-by {
    width: 100%;
    padding-bottom: 8px;
    padding-top: 5px;
    font-weight: 500;
    color: #333
}

.comment-by h5 {
    margin: 0 0 7px
}

.comment-by a.reply,
.comment-by a.reply i {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.comment-by a.reply {
    display: inline;
    background-color: white;
    padding: 5px 16px;
    line-height: 22px;
    font-size: 13px;
    color: #333;
    font-weight: 500;
    position: absolute;
    right: 0;
    top: 15px;
    border-radius: 50px
}

.comment-by a.reply i {
    font-size: 12px;
    margin-right: 1px
}

.comment-by .numerical-rating {
    margin: 4px 0 0
}

.comment-by .numerical-rating,
.comment-by .star-rating {
    position: absolute;
    right: 0;
    top: 7px
}

.comment-by a.reply:hover {
    background-color: #66676b
}

.comment-by a.reply:hover,
.comment-by a.reply:hover i {
    color: #fff
}

.comment-by span.date {
    color: #888;
    font-weight: 300;
    margin: 0;
    float: none;
    display: block;
    text-align: left
}

.listing-reviews ul li {
    padding-bottom: 45px;
    margin-top: 40px;
    border-bottom: 1px solid #e8e8e8
}

.review-images {
    display: flex;
    margin-top: 20px;
    margin-bottom: 5px
}

.review-images a {
    flex: 4;
    max-width: 25%;
    margin-right: 15px;
    border-radius: 3px
}

.review-images a img {
    border-radius: 4px
}

.add-review-box {
    border-radius: 4px;
    background-color: #f9f9f9;
    padding: 36px;
    padding-bottom: 40px;
    margin-bottom: 55px;
    margin-top: 70px;
    display: inline-block;
    width: 100%;
    position: relative
}

.add-review-box h3 {
    margin-top: 5px
}

.add-comment label span {
    color: #e24747
}

.add-comment input {
    margin: 0 0 25px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.add-comment textarea,
.add-comment input {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .05)
}

.add-comment textarea {
    margin: 0 0 30px;
    width: 100%;
    min-width: 100%;
    min-height: 220px;
    resize: vertical;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.add-comment .button {
    min-width: 160px
}

.leave-rating {
    height: 24px;
    float: left
}

.leave-rating:hover input[type=radio]:checked ~ label {
    color: #dadada
}

.leave-rating input[type=radio] {
    display: none
}

.leave-rating input[type=radio]:checked ~ label {
    color: #ffc600
}

.leave-rating label {
    font-size: 24px;
    float: right;
    letter-spacing: 4px;
    color: #dadada;
    cursor: pointer;
    transition: .3s
}

.leave-rating label:hover,
.leave-rating label:hover ~ label {
    color: #ffc600 !important
}

.leave-rating-title {
    display: block;
    margin: 35px 0 7px
}

.add-review-photos {
    font-weight: 600;
    padding: 6px 7px;
    color: #666;
    background-color: #ededed;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    font-size: 14px;
    width: auto;
    transition: .3s;
    cursor: pointer;
    line-height: 20px;
    float: right;
    top: -25px
}

.photoUpload {
    position: relative;
    overflow: hidden;
    padding: 3px 12px;
    cursor: pointer
}

.photoUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0)
}

.add-review-photos:hover {
    color: #fff;
    background-color: #66676b
}

.add-review-photos i {
    color: #66676b;
    margin-right: 3px;
    transition: .3s;
    font-size: 16px;
    position: relative;
    top: 2px
}

.add-review-photos:hover i {
    color: #fff
}

a.rate-review {
    border: 1px solid #e0e0e0;
    border-radius: 50px;
    font-size: 13px;
    color: #666;
    font-weight: 500;
    padding: 5px 20px;
    margin-top: 20px;
    display: inline-block;
    transition: .3s
}

a.rate-review span {
    border-left: 1px solid #e0e0e0;
    line-height: 20px;
    padding-left: 10px;
    margin-left: 5px;
    transition: .3s;
    color: #666
}

a.rate-review i {
    padding-right: 3px
}

a.rate-review:hover span {
    border-color: #d0d0d0
}

a.rate-review:hover {
    border-color: #d0d0d0;
    background-color: #fafafa
}

.pagination {
    margin: 9px 0 0;
    text-align: center;
    overflow: hidden
}

.pagination-next-prev {
    position: relative;
    top: -66px
}

.pagination ul li {
    display: inline-block;
    margin: 0;
    padding: 0
}

.pagination ul li a,
.pagination-next-prev ul li a {
    padding: 12px 0;
    border-bottom: 0;
    display: inline-block;
    color: #333;
    background-color: #fff;
    font-weight: 700;
    margin: 0;
    line-height: 22px;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    font-size: 14px
}

.pagination ul li a {
    border-radius: 50%;
    width: 52px;
    height: 52px;
    padding: 0;
    line-height: 52px
}

.pagination ul li a i {
    line-height: 52px
}

.pagination ul li.blank {
    color: #a0a0a0;
    padding: 0 6px
}

.pagination ul li a.current-page,
.pagination .current,
.pagination ul li a:hover,
.pagination-next-prev ul li a:hover {
    background-color: #f2f2f2;
    color: #333
}

.pagination-next-prev a.next {
    float: right
}

.pagination-next-prev a.prev {
    float: left
}

.pagination-next-prev ul li a {
    color: #333;
    padding: 12px 16px;
    text-transform: uppercase
}

.pagination ul,
.pagination-next-prev ul {
    padding: 0;
    list-style-type: none
}

.fs-listings .pagination ul li a,
.fs-listings .pagination-next-prev ul li a {
    background-color: transparent
}

.fs-listings .pagination ul li a.current-page,
.fs-listings .pagination .current,
.fs-listings .pagination ul li a:hover,
.fs-listings .pagination-next-prev ul li a:hover {
    background-color: #e8e8e8;
    color: #333
}

#contact textarea {
    min-height: 200px;
    margin: 15px 0 25px
}

#contact input {
    margin-bottom: 25px
}

.loader {
    margin-left: 15px
}

.submit.disabled:hover,
.submit.disabled {
    background-color: #e8e8e8;
    color: #333
}

#contact input.button.submit {
    margin-bottom: 10px;
    line-height: 18px;
    height: 49px;
    transition: all .25s !important
}

#contact input[type=submit].submit:hover {
    opacity: .92
}

.contact-sent {
    background-color: #ebf6e0;
    color: #5f9025;
    padding: 20px 26px;
    margin-bottom: 30px;
    border-radius: 3px
}

.office-address {
    text-align: center;
    position: relative;
    color: #fff;
    display: table-cell;
    vertical-align: middle;
    height: 100%
}

.office-address ul {
    list-style: none;
    font-size: 18px;
    padding: 0;
    line-height: 30px
}

.office-address h3 {
    font-size: 28px;
    color: #fff;
    margin-top: 0
}

.office-address h3:after {
    content: "";
    height: 2px;
    width: 50px;
    position: relative;
    display: block;
    background-color: #66676b;
    margin: 20px auto;
    border-radius: 2px
}

.address-container {
    background-color: #2b2c30;
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: table;
    width: 100%;
    position: relative;
    height: 100%
}

.address-container:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: #333;
    opacity: .7
}

.contact-map {
    display: flex
}

.contact-map #singleListingMap-container {
    flex: 1;
    position: relative;
    padding-top: 0
}

.contact-map .address-box-container {
    flex: 0 auto;
    width: 440px;
    height: 450px
}

.contact-map #singleListingMap-container #singleListingMap {
    height: 100%;
    width: 100%
}

@media (min-width: 1680px) {
    .contact-map .address-box-container {
        height: 480px
    }
}

@media (max-width: 1440px) {
    .contact-map .address-box-container {
        height: 420px
    }
}

.sidebar-textbox {
    display: inline-block;
    width: 100%;
    padding-right: 40px
}

.sidebar-textbox.color {
    background-color: #666;
    color: #fff
}

.sidebar-textbox.color h4 {
    color: #fff
}

.sidebar-textbox h4 {
    font-size: 16px;
    margin: 0;
    padding: 0;
    margin-bottom: 16px
}

.sidebar-textbox span {
    color: #666;
    display: inline-block
}

.sidebar-textbox.color span {
    color: #fff
}

.sidebar-textbox ul.contact-details {
    list-style: none;
    padding: 5px 0 0
}

.sidebar-textbox ul.contact-details strong {
    font-weight: 500
}

.sidebar-textbox ul.contact-details li {
    text-align: left;
    padding-left: 60px;
    position: relative;
    width: 100%;
    display: inline-block;
    margin: 10px 0;
    line-height: 24px
}

.sidebar-textbox ul.contact-details li a {
    color: #66676b
}

.sidebar-textbox ul.contact-details li strong {
    display: block;
    color: #333
}

.sidebar-textbox ul.contact-details li i {
    height: 100%;
    position: absolute;
    left: 0;
    font-size: 32px;
    color: #333;
    top: 10px
}

.coming-soon-page {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: relative
}

.coming-soon-page .container {
    z-index: 15;
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%)
}

@media (max-width: 992px) {
    .coming-soon-page {
        min-height: 100vh;
        height: auto
    }

    .coming-soon-page .container {
        padding-top: 50px;
        padding-bottom: 50px;
        top: auto;
        transform: translateY(0%)
    }
}

.coming-soon-page:before {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 10;
    content: "";
    background-color: rgba(26, 26, 26, .92)
}

.coming-soon-page h2,
.coming-soon-page h3 {
    margin: 0;
    padding: 0
}

.coming-soon-page h2 {
    font-size: 46px;
    color: #fff
}

.coming-soon-page h3 {
    font-size: 34px;
    font-weight: 300;
    margin: 30px 0;
    color: rgba(255, 255, 255, .9)
}

.coming-soon-page .main-search-input-item input {
    font-size: 16px;
    font-weight: 600
}

.coming-soon-page .gray-style.main-search-input {
    max-width: 650px;
    margin: 0 auto
}

.coming-soon-page .main-search-input button.button {
    font-size: 16px
}

#countdown {
    font-weight: 600;
    float: none;
    display: inline-block
}

#countdown div {
    display: inline-block;
    margin: 0 5px;
    float: left
}

#countdown div:first-child i {
    border: 0
}

#countdown span {
    background-color: rgba(255, 255, 255, .1);
    font-size: 40px;
    color: #fff;
    width: 120px;
    display: inline-block;
    padding: 32px 0;
    font-weight: 400;
    letter-spacing: -1px;
    border-radius: 3px 3px 0 0
}

#countdown i {
    display: block;
    font-style: normal;
    margin: 0;
    text-transform: uppercase;
    font-size: 13px;
    padding: 4px 0;
    letter-spacing: 1px;
    background-color: #fff;
    color: #333;
    border-radius: 0 0 3px 3px
}

.countdown-text {
    font-size: 24px;
    margin-top: 15px;
    line-height: 36px;
    font-weight: 300
}

@media (max-width: 550px) {
    #countdown,
    #countdown div,
    #countdown span {
        width: 100%
    }

    #countdown div {
        padding: 10px 0
    }
}

.blog-post {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    background-color: #fff;
    display: inline-block;
    width: 100%;
    border-radius: 3px;
    margin: 15px 0
}

section.fullwidth .blog-post {
    margin: 5px 0 0
}

.post-img {
    position: relative;
    height: 100%;
    width: 100%;
    display: block
}

.post-img img {
    width: 100%;
    border-radius: 3px 3px 0 0
}

.post-img:after {
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    content: "";
    z-index: 9;
    opacity: .1;
    background: #333;
    transition: opacity .4s;
    border-radius: 3px 3px 0 0
}

.post-img:hover:after {
    opacity: .5
}

.post-img:before {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    display: block;
    top: 50%;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    content: "";
    z-index: 119;
    transition: .3s;
    transform: translateY(-80%);
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50%;
    background-color: #66676b;
    background-image: url("../images/plus-icon.svg");
    background-repeat: no-repeat;
    background-position: 50%
}

.post-img:hover:before {
    opacity: 1;
    visibility: visible;
    transform: translateY(-50%)
}

.post-content {
    padding: 38px
}

.post-content p {
    margin: 18px 0 0
}

.post-content h3 {
    margin: 0;
    line-height: 34px;
    font-size: 22px
}

.post-content h3 a {
    color: #333
}

.post-content h3 a:hover {
    color: #66676b
}

.post-content .meta-tags span {
    margin: 0 0 15px;
    display: inline-block;
    color: #888
}

.post-content a.read-more {
    color: #66676b;
    margin: 23px 0 2px;
    display: inline-block
}

a.read-more i {
    font-size: 20px;
    position: relative;
    padding-left: 4px;
    top: 1px;
    transition: .2s
}

a.read-more:hover i {
    padding-left: 7px
}

.post-meta {
    list-style: none;
    padding: 0;
    margin: 5px 0 0
}

.post-meta li {
    display: inline-block;
    color: #888
}

.post-meta li:after {
    content: "";
    width: 1px;
    height: 12px;
    background: #d0d0d0;
    display: inline-block;
    position: relative;
    top: 0;
    margin: 0 10px
}

.post-meta li:last-child:after {
    display: none
}

.post-meta li a {
    color: #888
}

.post-meta li a:hover {
    color: #66676b
}

.blog-page .blog-post {
    margin: 0 0 45px
}

.blog-page h4.headline {
    font-size: 22px !important;
    font-weight: 400
}

.search-blog-input {
    position: relative
}

.widget .search-blog-input .input:before {
    content: "\e090";
    font-size: 17px;
    color: #a0a0a0;
    position: absolute;
    display: inline-block;
    font-family: simple-line-icons;
    right: 18px;
    top: 13px;
    margin-bottom: 0;
    background-color: #fff;
    padding-left: 7px
}

#contact textarea,
#contact input,
.widget .search-blog-input input {
    transition: box-shadow .2s !important
}

#contact textarea:focus,
#contact input:focus,
.widget .search-blog-input input:focus {
    transition: box-shadow .2s !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .07)
}

.widget-tabs {
    padding: 0;
    list-style: none
}

.widget-tabs li {
    padding: 0 0 27px;
    margin: 24px 0 0;
    border-bottom: 1px solid #e0e0e0
}

.widget .tab-content {
    padding-bottom: 0
}

.widget-thumb a img {
    display: block;
    width: 95px;
    height: 95px;
    float: left;
    border-radius: 3px;
    margin: 3px 22px 0 0
}

.widget-thumb {
    width: 85px;
    margin-right: 23px
}

.widget-text h5 {
    font-size: 15px;
    line-height: 24px;
    margin: 0 0 5px
}

.widget-text h5 a {
    color: #333
}

.widget-text h5 a:hover {
    color: #66676b
}

.widget-text span {
    color: #888;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    display: block
}

.widget-thumb {
    display: inline-block
}

.widget-thumb a img {
    width: 85px;
    height: auto
}

.widget-text {
    display: inline-block;
    width: 70%;
    padding: 0;
    position: relative;
    top: -2px
}

.widget-tabs .widget-content {
    display: table
}

.widget-tabs .widget-content .widget-text {
    display: table-cell;
    vertical-align: middle
}

.widget-tabs li {
    padding: 0 0 20px;
    margin: 24px 0 0
}

.widget-tabs li:first-child {
    padding: 0 0 20px;
    margin: 32px 0 0
}

.widget-tabs li:last-child {
    border: 0;
    padding-bottom: 0
}

.widget h3 {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 22px
}

.single-post p {
    margin: 20px 0
}

.single-post p:last-child {
    margin-bottom: 0
}

.single-post .post-img {
    border-radius: 3px 3px 0 0
}

.blog-page .blog-post .post-content,
.single-post .post-content {
    padding: 36px 43px
}

.about-author {
    margin: 10px 0 45px;
    padding: 30px 35px;
    background: #f8f8f8;
    border-radius: 4px
}

.about-author p {
    margin: 5px 0
}

.about-author a {
    position: relative;
    top: -7px;
    color: #66676b
}

.about-author h4 {
    letter-spacing: 0
}

.about-author img {
    float: left;
    margin: 7px 5px 5px;
    width: 110px;
    height: 110px;
    border-radius: 50%
}

.about-description {
    margin: 2px 0 0 150px
}

.blog-compact-item-container {
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, .12);
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background: 0 0;
    transform: translate3d(0, 0, 0);
    transition: transform .3s;
    margin-bottom: 30px
}

.blog-compact-item-container:hover {
    transform: translate3d(0, -6px, 0)
}

.blog-compact-item {
    background: #ccc;
    border-radius: 4px;
    height: 100%;
    display: block;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    height: 480px;
    z-index: 100;
    cursor: pointer
}

.blog-compact-item img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 4px
}

.blog-compact-item:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    background: linear-gradient(to top, rgba(35, 35, 37, 0.85) 0%, rgba(35, 35, 37, 0.65) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
    border-radius: 4px;
    opacity: 1
}

.blog-compact-item-content {
    position: absolute;
    bottom: 32px;
    left: 0;
    padding: 0 34px;
    width: 100%;
    z-index: 50;
    box-sizing: border-box
}

.blog-compact-item-content h3 {
    color: #fff;
    font-size: 20px;
    padding: 5px 0;
    font-weight: 500;
    margin: 2px 0 0;
    line-height: 31px
}

.blog-compact-item-content span {
    font-size: 16px;
    font-weight: 300;
    display: inline-block;
    color: rgba(255, 255, 255, .7)
}

.blog-compact-item-content p {
    font-size: 16px;
    font-weight: 300;
    display: inline-block;
    color: rgba(255, 255, 255, .8);
    margin: 7px 0 0
}

span.blog-item-tag {
    text-transform: uppercase;
    font-size: 9.7px;
    letter-spacing: 2.5px;
    font-weight: 60;
    background: #66676b;
    border-radius: 50px;
    padding: 5px 16px;
    line-height: 20px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 9px;
    position: absolute;
    top: 30px;
    left: 32px;
    z-index: 110
}

.blog-post-tags {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    opacity: .9
}

.blog-post-tags li {
    display: inline-block;
    margin-right: 5px
}

.blog-post-tags li i {
    margin-right: 3px;
    font-weight: 400;
    display: inline-block
}

button.button,
input[type=button],
input[type=submit],
a.button.border,
a.button {
    background-color: #66676b;
    top: 0;
    padding: 9px 20px;
    color: #fff;
    position: relative;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    transition: all .2s ease-in-out;
    cursor: pointer;
    margin-right: 6px;
    overflow: hidden;
    border: 0;
    border-radius: 50px
}

input[type=button],
input[type=submit] {
    line-height: 32px
}

button.button:before,
a.button:before {
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all .2s
}

button.button:hover:before,
a.button:hover:before {
    opacity: .1
}

a.button.white {
    background-color: #fff;
    color: #333
}

button.button {
    line-height: 26px
}

a.button.border:before {
    display: none
}

a.button.border {
    background-color: transparent;
    color: #66676b;
    border: 1px solid #66676b;
    padding: 9px 21px
}

a.button.border:hover {
    background-color: #66676b;
    color: #fff;
    opacity: 1
}

a.button.border.white {
    border-color: #fff;
    color: #fff
}

a.button.border.white:hover {
    background-color: #fff;
    color: #66676b
}

button.button.fullwidth,
a.button.fullwidth {
    width: 100%;
    text-align: center
}

a.button.white.border:hover {
    color: #333
}

.button i {
    padding-right: 4px
}

.centered-content {
    text-align: center
}

.centered-content .button {
    min-width: 130px
}

a.button.border {
    font-weight: 500
}

.icon-box-2 {
    text-align: center;
    margin-top: 45px;
    position: relative
}

.icon-box-2 i {
    width: 120px;
    height: 80px;
    background-color: #fff;
    border-radius: 50%;
    line-height: 80px;
    font-size: 60px;
    display: inline-block;
    margin-bottom: 10px;
    text-align: center;
    overflow: hidden;
    color: #66676b;
    position: relative;
    z-index: 11
}

.icon-box-2 span {
    color: #666;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500
}

.icon-box-2 h4 {
    margin: 4px 0;
    line-height: 26px
}

.icon-box-2 h3 {
    margin: 4px 0;
    line-height: 26px;
    font-size: 19px;
    font-weight: 500
}

.icon-box-2 p {
    margin-top: 20px;
    font-style: 16px
}

.icon-box-2 i.im-icon-Mail-withAtSign {
    font-size: 58px;
    top: 2px
}

.icon-box-2.with-line:before {
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 40px;
    background: #e9e9e9;
    display: block;
    z-index: 9
}

.icon-box-3 {
    text-align: left;
    padding-left: 90px;
    position: relative;
    width: 100%;
    display: inline-block;
    margin: 20px 0
}

.icon-box-3 h4 {
    font-size: 18px;
    font-weight: 600
}

.icon-box-3 i {
    font-size: 54px;
    color: #333;
    height: 60px;
    margin: 10px 0;
    display: inline-block;
    height: 100%;
    position: absolute;
    left: 0
}

.icon-box-3 p {
    color: rgba(51, 51, 51, .7);
    margin-top: 3px;
    display: inline-block
}

.icon-box-1.rounded i,
.icon-box-2.rounded i,
.icon-box-3.rounded i {
    width: 110px;
    height: 110px;
    background-color: #666;
    border-radius: 50%;
    color: #fff;
    line-height: 110px;
    font-size: 50px;
    display: inline-block;
    margin-bottom: 20px;
    text-align: center;
    overflow: hidden
}

.rounded i:before {
    position: relative;
    z-index: 11
}

.icon-box-1.rounded i,
.icon-box-2.rounded i,
.icon-box-3.rounded i {
    transition: background .3s ease-in-out, color .3s ease-in-out;
    transform: translate3d(0, 0, 0) rotate(0)
}

body .icon-box-1.rounded:hover i,
body .icon-box-2.rounded:hover i,
body .icon-box-3.rounded:hover i {
    color: #fff
}

.icon-box-1.rounded i:after,
.icon-box-2.rounded i:after,
.icon-box-3.rounded i:after {
    content: "";
    width: 120%;
    height: 0;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;
    z-index: 8;
    transition: height .3s ease-in-out, opacity .3s ease-out;
    opacity: 0;
    transform: translate3d(50%, -50%, 0) scale(1) rotate(45deg)
}

.icon-box-1.rounded:hover i:after,
.icon-box-2.rounded:hover i:after,
.icon-box-3.rounded:hover i:after {
    height: 145%;
    opacity: .1
}

.icon-box-1.rounded.dark i,
.icon-box-2.rounded.dark i,
.icon-box-3.rounded.dark i {
    background-color: #222
}

.icon-box-1.rounded.light i,
.icon-box-2.rounded.light i,
.icon-box-3.rounded.light i {
    color: #333;
    background-color: #f2f2f2
}

.icon-box-3.rounded {
    padding-left: 120px
}

.icon-box-3.rounded i {
    width: 90px;
    height: 90px;
    font-size: 40px;
    line-height: 90px
}

body .white-text {
    color: #fff
}

.white-text .icon-box-1 p,
.white-text .icon-box-2 p,
.white-text .icon-box-3 p,
.white-text .icon-box-4 p {
    color: rgba(255, 255, 255, .85)
}

.white-text h1,
.white-text h2,
.white-text h3,
.white-text h4,
.white-text h5,
.white-text .icon-box-1 h4,
.white-text .icon-box-2 h4,
.white-text .icon-box-3 h4,
.white-text .icon-box-4 h4,
.white-text .icon-box-1 i,
.white-text .icon-box-2 i,
.white-text .icon-box-3 i,
.white-text .icon-box-4 i {
    color: #fff
}

@media (max-width: 1659px) {
    .icon-box-3.rounded {
        padding-left: 110px
    }

    .icon-box-3.rounded i {
        width: 80px;
        height: 80px;
        font-size: 38px;
        line-height: 80px
    }
}

table.basic-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 0;
    margin-bottom: 15px
}

table.basic-table th {
    background-color: #66676b;
    text-align: left;
    color: #fff;
    vertical-align: top;
    font-weight: 500
}

table.basic-table th:first-child {
    border-radius: 4px 0 0 4px
}

table.basic-table th:last-child {
    border-radius: 0 4px 4px 0
}

table.basic-table th,
table.basic-table td {
    padding: 15px 28px
}

table.basic-table tr:nth-child(odd) {
    background-color: #f6f6f6
}

table.basic-table {
    margin-bottom: 0
}

@media screen and (max-width: 600px) {
    table {
        border: 0
    }

    table th {
        display: none
    }

    table tr {
        display: block;
        margin-bottom: 0
    }

    table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 14px;
        text-align: right
    }

    table td:before {
        content: attr(data-label);
        float: left;
        font-weight: 600
    }

    table td:last-child {
        border-bottom: 0
    }
}

.ui-accordion .ui-accordion-header,
.trigger a {
    display: block;
    cursor: pointer;
    position: relative;
    padding: 0;
    line-height: 26px;
    outline: 0;
    color: #333;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    transition: background-color .2s, color .2s;
    padding: 16px 27px;
    margin: -1px 0 0
}

.ui-accordion .ui-accordion-header i,
.trigger a i {
    font-size: 18px;
    top: 1px;
    position: relative;
    padding-right: 3px
}

.style-2 .ui-accordion .ui-accordion-header i,
.style-2 .trigger a i {
    padding-right: 6px
}

.style-2 .ui-accordion .ui-accordion-header:hover,
.style-2 .trigger a:hover {
    color: #66676b
}

.trigger.active a,
.ui-accordion .ui-accordion-header-active:hover,
.ui-accordion .ui-accordion-header-active {
    background-color: #66676b;
    border-color: #66676b;
    color: #fff
}

.ui-accordion .ui-accordion-header i,
.trigger a i {
    margin: 0 4px 0 0;
    position: relative
}

.ui-accordion .ui-accordion-content,
.toggle-container {
    padding: 28px 30px;
    border: 1px solid #e0e0e0;
    border-top: 0
}

.ui-accordion .ui-accordion-content p,
.toggle-container p {
    margin: 0
}

.style-2 .ui-accordion .ui-accordion-header,
.style-2 .trigger a {
    border: 0;
    margin: 0;
    padding: 27px 0;
    text-transform: none;
    font-size: 17px;
    font-weight: 500;
    background-color: transparent;
    color: #333;
    padding-right: 60px;
    font-weight: 400
}

body .style-2 .ui-accordion .ui-accordion-header,
body .style-2 .trigger a {
    text-transform: none
}

.style-2 .ui-accordion .ui-accordion-content,
.style-2 .toggle-container {
    padding: 0 0 30px;
    border: 0
}

.style-2 .trigger.active a,
.style-2 .ui-accordion .ui-accordion-header-active:hover,
.style-2 .ui-accordion .ui-accordion-header-active {
    background-color: transparent;
    border-color: transparent;
    color: #66676b;
    transition: color .1s
}

.style-2 .ui-accordion .ui-accordion-header,
.style-2 .ui-accordion .ui-accordion-content,
.style-2 .toggle-wrap {
    border-bottom: 1px solid #e0e0e0
}

.style-2 .ui-accordion .ui-accordion-header-active {
    border: 0
}

.accordion h3 .sl-icon-plus,
.trigger .sl-icon-plus {
    position: absolute;
    right: 0;
    top: 50%;
    font-size: 26px;
    transform: translate3d(0, -50%, 0) rotate(0);
    transition: .2s
}

.accordion h3.ui-accordion-header-active .sl-icon-plus,
.trigger.active .sl-icon-plus {
    margin-top: 2px;
    transform: translate3d(0, -50%, 0) rotate(45deg)
}

.share-buttons {
    padding: 0;
    list-style: none
}

.share-buttons li {
    display: inline-block;
    margin: 0 6px 8px 0
}

.share-buttons li a {
    background: #fff;
    border: 1px solid #666;
    border-radius: 50px;
    font-weight: 500;
    font-size: 13px;
    padding: 7px 20px;
    transition: .3s;
    display: inline-block;
    line-height: 17px;
    font-weight: 500
}

.share-buttons li a:hover {
    color: #fff
}

.share-buttons li i {
    font-size: 16px;
    position: relative;
    right: 3px;
    top: 1px
}

.share-buttons li a.fb-share:hover {
    background: #3b5998;
    color: #fff
}

.share-buttons li a.fb-share {
    border-color: #3b5998;
    color: #3b5998
}

.share-buttons li a.twitter-share:hover {
    background: #1da1f2;
    color: #fff
}

.share-buttons li a.twitter-share {
    border-color: #1da1f2;
    color: #1da1f2
}

.share-buttons li a.gplus-share:hover {
    background: #dd4b39;
    color: #fff
}

.share-buttons li a.gplus-share {
    border-color: #dd4b39;
    color: #dd4b39
}

.share-buttons li a.pinterest-share:hover {
    background: #bd081c;
    color: #fff
}

.share-buttons li a.pinterest-share {
    border-color: #bd081c;
    color: #bd081c
}

#posts-nav {
    padding: 0;
    list-style: none;
    width: 100%;
    position: relative;
    margin: 0;
    display: inline-block;
    min-height: 60px
}

#posts-nav li span {
    display: block;
    color: #888;
    font-size: 15px;
    margin-bottom: 1px;
    font-weight: 400
}

#posts-nav li {
    position: absolute;
    font-size: 17px;
    font-weight: 500;
    width: 50%
}

#posts-nav li a {
    transition: transform .3s, color .3s 0s;
    color: #333;
    display: inline-block
}

#posts-nav li a:hover {
    color: #66676b
}

#posts-nav li.next-post {
    right: 0;
    text-align: right
}

#posts-nav li.prev-post {
    left: 0
}

#posts-nav li.next-post a {
    padding-right: 50px
}

#posts-nav li.prev-post a {
    padding-left: 50px
}

#posts-nav li.prev-post a:before,
#posts-nav li.next-post a:before {
    font-family: simple-line-icons;
    font-size: 20px;
    position: absolute;
    color: inherit;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    transition: transform .3s
}

#posts-nav li.next-post a:before {
    right: 0;
    content: "\e606"
}

#posts-nav li.prev-post a:before {
    left: 0;
    content: "\e605"
}

#posts-nav li.next-post a:hover:before {
    transform: translate3d(5px, -50%, 0)
}

#posts-nav li.prev-post a:hover:before {
    transform: translate3d(-5px, -50%, 0)
}

.pricing-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative
}

.plan {
    flex: 1;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .1);
    text-align: center;
    position: relative;
    z-index: 9;
    overflow: hidden
}

.plan-price {
    width: 100%;
    background: #f9f9f9;
    padding: 45px 30px;
    padding-top: 48px
}

.plan:first-child .plan-price {
    border-radius: 4px 0 0 0
}

.plan:first-child {
    border-radius: 4px 0 4px 4px
}

.plan:last-child .plan-price {
    border-radius: 0 4px 0 0
}

.plan:last-child {
    border-radius: 0 4px 4px 0
}

.plan-price h3 {
    margin: 0;
    color: #888;
    font-weight: 400;
    font-size: 22px
}

.plan.featured .listing-badge {
    background-color: #fff;
    color: #66676b
}

.plan-price .value {
    display: block;
    font-size: 37px;
    line-height: 35px;
    padding: 22px 0 0;
    color: #333
}

.plan-price .period {
    color: #888;
    margin-top: 20px;
    padding: 0 10%;
    display: block
}

.plan-features {
    padding: 35px 30px;
    background: #fff;
    border-radius: 4px
}

.plan-features ul {
    list-style: none;
    padding: 0
}

.plan-features ul li {
    padding: 3px 0
}

.plan-features a.button {
    margin-top: 20px
}

.plan.featured {
    margin-left: -1px;
    margin-right: -1px;
    transform: translateY(-23px);
    border-radius: 0 0 4px 4px;
    z-index: 11
}

.plan.featured .plan-price {
    background-color: #66676b;
    padding: 50px 30px;
    border-radius: 4px 4px 0 0
}

.plan.featured .plan-price h3 {
    color: #fff
}

.plan.featured .plan-price .value {
    color: #fff
}

.plan.featured .plan-price .period {
    color: #fff;
    line-height: 23px
}

.plan.featured .plan-features {
    padding: 45px 30px
}

.plan.featured .listing-badges .featured {
    background: #fff;
    color: #66676b
}

.list-4,
.list-3,
.list-2,
.list-1 {
    padding: 3px 0 0;
    font-size: 16px
}

.list-4 li,
.list-3 li,
.list-2 li,
.list-1 li {
    list-style: none;
    margin: 14px 0;
    line-height: 27px;
    margin-left: 22px;
    position: relative
}

.list-1.alt li {
    margin: 12px 0;
    margin-left: 22px
}

.list-4 li:first-child,
.list-3 li:first-child,
.list-2 li:first-child,
.list-1 li:first-child {
    margin-top: 0
}

.list-4 li:before,
.list-3 li:before,
.list-2 li:before,
.list-1 li:before {
    font-family: fontawesome;
    margin: 0;
    position: relative;
    color: #66676b;
    float: left;
    margin-left: -22px;
    display: block
}

.list-1.gray li:before {
    color: white;
}

.list-4 li:before {
    content: "\f192";
    font-size: 14px;
    top: 1px
}

.list-3 li:before {
    content: "\f058";
    font-size: 15px;
    top: 1px
}

.list-2 li:before {
    content: "\f00c";
    font-size: 14px
}

.list-1 li:before {
    content: "\f0c8";
    font-size: 8px
}

.list-4 li {
    margin-left: 27px
}

.list-4 li:before {
    margin-left: -27px
}

.numbered ol {
    counter-reset: li;
    list-style: none;
    padding: 0;
    margin-left: 18px;
    display: inline-block;
    font-size: 16px
}

.numbered ol li {
    display: inline-block;
    padding: 6px 0;
    width: 100%
}

.numbered ol > li::before {
    content: counter(li);
    counter-increment: li;
    font-size: 16px;
    line-height: 33px;
    width: 36px;
    height: 36px;
    display: inline-block;
    border: 1px solid #333;
    border-radius: 50%;
    text-align: center;
    margin: 0;
    margin-left: 0;
    font-weight: 500;
    color: #333;
    position: relative;
    float: left;
    left: -18px;
    font-size: 15px;
    transform: translateY(-10%)
}

.numbered.color ol > li::before {
    border: 1px solid #66676b;
    color: #66676b
}

.numbered.color.filled ol > li::before {
    border: 1px solid #66676b;
    color: #fff;
    background-color: #66676b
}

mark.color {
    background-color: #66676b;
    border-radius: 4px
}

mark {
    border-radius: 4px
}

.demo h1,
.demo h2,
.demo h3,
.demo h4,
.demo h5,
.demo h6 {
    line-height: 40px
}

#tiptip_holder {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99999
}

#tiptip_holder.tip_top {
    padding-bottom: 5px;
    -webkit-transform: translateX(12px)
}

#tiptip_holder.tip_bottom {
    padding-top: 5px
}

#tiptip_holder.tip_right {
    padding-left: 5px
}

#tiptip_holder.tip_left {
    padding-right: 5px
}

#tiptip_content {
    color: #fff;
    font-size: 13px;
    line-height: 16px;
    padding: 6px 10px;
    background-color: #333;
    border-radius: 3px
}

#tiptip_arrow,
#tiptip_arrow_inner {
    position: absolute;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    height: 0;
    width: 0
}

#tiptip_holder.tip_top #tiptip_arrow {
    border-top-color: #333
}

#tiptip_holder.tip_bottom #tiptip_arrow {
    border-bottom-color: #333
}

#tiptip_holder.tip_right #tiptip_arrow {
    border-right-color: #333
}

#tiptip_holder.tip_left #tiptip_arrow {
    border-left-color: #333
}

#tiptip_holder.tip_top #tiptip_arrow_inner {
    margin-top: -7px;
    margin-left: -6px;
    border-top-color: #333
}

#tiptip_holder.tip_bottom #tiptip_arrow_inner {
    margin-top: -5px;
    margin-left: -6px;
    border-bottom-color: #333
}

#tiptip_holder.tip_right #tiptip_arrow_inner {
    margin-top: -6px;
    margin-left: -5px;
    border-right-color: #333
}

#tiptip_holder.tip_left #tiptip_arrow_inner {
    margin-top: -6px;
    margin-left: -7px;
    border-left-color: #333
}

.info-box {
    background-color: #fff;
    display: inline-block;
    width: 100%;
    border-radius: 3px;
    padding: 23px 27px;
    border-top: 2px solid #66676b;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95));
    background-color: #66676b;
    color: #66676b
}

.info-box.no-border {
    border: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.96), rgba(255, 255, 255, 0.93));
    background-color: #66676b
}

.info-box h4 {
    margin: 0;
    font-size: 19px;
    color: #66676b;
    margin-bottom: 20px;
    margin-top: 0
}

.info-box p {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 26px
}

.info-box.large {
    padding: 36px
}

#result .success,
.notification {
    line-height: 24px;
    margin-bottom: 15px;
    position: relative;
    padding: 20px 26px;
    padding-right: 50px;
    border-radius: 3px
}

.notification p {
    margin: 0;
    font-size: 15px
}

#result .success,
.notification.success {
    background-color: #ebf6e0
}

#result .success,
.notification.success,
.notification.success a,
.notification.success strong {
    color: #5f9025
}

.notification.error {
    background-color: #ffe9e9
}

.notification.error,
.notification.error a,
.notification.error strong {
    color: #de5959
}

.notification.warning {
    background-color: #fbfadd
}

.notification.warning,
.notification.warning a,
.notification.warning strong {
    color: #8f872e
}

.notification.notice h4 {
    font-size: 19px;
    margin: 3px 0 15px
}

.notification.notice h4,
.notification.notice,
.notification.notice a,
.notification.notice strong {
    color: #3184ae
}

.notification.notice {
    background-color: #e9f7fe
}

.notification.notice.large {
    padding: 32px 36px
}

#dashboard .success {
    background-color: #e6f3d8
}

#dashboard .notice {
    background-color: #e1f3fc
}

#dashboard .warning {
    background-color: #f6f4c9
}

body .notification strong {
    border: 0
}

.notification.success .close,
.notification.error .close,
.notification.warning .close,
.notification.notice .close {
    padding: 0 9px;
    position: absolute;
    right: 0;
    top: 22px;
    display: block;
    height: 8px;
    width: 8px;
    cursor: pointer
}

.notification.notice p span i {
    font-weight: 500
}

.notification a.button {
    float: right;
    color: #fff;
    margin-top: 3px
}

.notification.notice a.button {
    background-color: #388fc5
}

.notification.warning a.button {
    background-color: #dfbe51
}

.notification.error a.button {
    background-color: #d34c4c
}

.notification.success a.button {
    background-color: #79ba38
}

.notification.closeable a.close:before {
    content: "\f00d";
    font-family: fontawesome;
    position: absolute;
    right: 25px;
    top: 0;
    cursor: pointer
}

.img-box {
    height: 350px;
    display: inline-block;
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 90;
    margin: 10px 0;
    border-radius: 3px
}

.img-box-background {
    background-size: cover;
    background-position: 50%;
    width: 100%;
    height: 100%;
    transition: all .55s;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    transition: transform .35s ease-out
}

.img-box:hover .img-box-background {
    transform: scale(1.07)
}

.img-box:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    background-color: #222;
    opacity: .3;
    z-index: 99;
    transition: .3s ease-out
}

.img-box-content {
    position: absolute;
    z-index: 101;
    top: 50%;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    transform: translate(0, -50.5%)
}

.img-box-content h4 {
    font-size: 28px;
    line-height: 36px;
    color: #fff;
    margin: 0
}

.img-box-content span {
    font-size: 16px;
    color: #fff
}

.testimonial-box {
    background: 0 0;
    border-radius: 6px;
    padding: 38px;
    padding-left: 110px;
    color: #888;
    position: relative;
    margin: 18px 0;
    box-shadow: none;
    transition: .4s;
    cursor: grab
}

.testimonial-carousel .slick-slide {
    margin: 0 20px 30px;
    opacity: .5;
    transition: .4s;
    pointer-events: all
}

.testimonial-carousel .slick-slide.slick-active .testimonial-box {
    background: #66676b;
    color: #fff
}

.testimonial-carousel .slick-slide.slick-active .testimonial:before {
    color: #66676b
}

.testimonial:before {
    position: absolute;
    margin: 0 auto;
    display: block;
    width: 100%;
    text-align: center;
    bottom: -10px;
    left: 0;
    content: "\f0dd";
    font-family: fontawesome;
    font-size: 42px;
    color: transparent;
    background-color: transparent;
    cursor: default
}

.testimonial:after {
    position: absolute;
    top: 47px;
    left: 42px;
    content: "\f10d";
    font-family: fontawesome;
    font-size: 38px;
    opacity: .3;
    color: #222;
    background-color: transparent;
    cursor: default
}

.testimonial,
.testimonial p {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px
}

.testimonial-author {
    display: block;
    width: 100%;
    padding: 20px 0 15px;
    text-align: center;
    margin: 0 auto
}

.testimonial-author img {
    width: 60px;
    display: inline-block;
    text-align: center;
    height: auto;
    border-radius: 50%;
    margin: 0 auto
}

.testimonial-author h4 {
    display: block;
    width: 100%;
    font-size: 17px;
    padding-top: 5px;
    color: #333;
    font-weight: 600
}

.testimonial-author h4 span {
    display: block;
    color: #888;
    padding-top: 6px;
    font-weight: 400;
    font-size: 15px
}

.testimonial-box .star-rating {
    height: 30px;
    display: inline-block;
    margin-bottom: -10px;
    margin-top: 15px
}

.info-banner {
    display: block;
    width: 100%;
    padding: 90px 0;
    color: #fff;
    margin: 0
}

.info-banner-content {
    position: relative;
    z-index: 199
}

.info-banner.centered {
    text-align: center
}

.info-banner-content a.button {
    margin: 0 20px -15px;
    position: relative
}

.flip-banner h1,
.flip-banner h2,
.flip-banner h3,
.flip-banner h4,
.flip-banner h5,
.flip-banner h6,
.flip-banner a,
.info-banner-content h1,
.info-banner-content h2,
.info-banner-content h3,
.info-banner-content h4,
.info-banner-content h5,
.info-banner-content h6,
.info-banner-content a,
.info-banner-content {
    color: #fff;
    display: inline-block;
    margin: 0;
    position: relative
}

.text-content {
    background: 0 0;
    padding: 110px 0;
    font-weight: 500;
    z-index: 999;
    position: relative
}

.text-content.white-font {
    color: #fff
}

.text-content h1,
.text-content h2,
.text-content h3,
.text-content h4,
.text-content h5 {
    color: #fff
}

.text-content.white-font h1,
.text-content.white-font h2,
.text-content.white-font h3,
.text-content.white-font h4,
.text-content.white-font h5 {
    color: #fff
}

.text-content a.button {
    padding: 10px 26px;
    font-size: 16px;
    font-weight: 600
}

.text-content.white-font a.button:hover {
    background-color: #fff;
    color: #333
}

.text-content a.button:before {
    display: none
}

.text-content p {
    font-size: 18px;
    font-weight: 400;
    line-height: 34px
}

.text-content h2 {
    margin-bottom: 30px;
    line-height: 46px;
    font-size: 36px;
    font-weight: 500
}

.top-1 {
    position: relative;
    top: -1px
}

.top-0 {
    position: relative
}

.image-edge {
    background-color: #f8f8f8;
    position: relative;
    overflow: hidden;
    margin: 35px 0 0;
    height: 50vw;
    max-height: 640px;
    overflow: hidden;
    padding: 0
}

.image-edge #googlemaps,
.image-edge .google-map-container {
    height: 100%
}

.image-edge-content {
    padding: 0;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    width: 50%;
    padding: 0 9%
}

.image-edge.left .image-edge-content {
    padding-left: 6%
}

.image-edge-content h3.headline {
    width: 100%;
    display: block;
    margin-top: 0
}

.edge-bg {
    position: absolute;
    width: 50%;
    top: 0;
    right: 0;
    height: 100%;
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.edge-bg:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(34, 34, 34, .1)
}

.image-edge.left .image-edge-content {
    left: 50%
}

.image-edge.left .edge-bg {
    left: 0
}

.image-edge.left .container {
    transform: translateX(54%);
    -webkit-transform: translateX(54%)
}

.image-edge.dark {
    background-color: #282828;
    color: #fff
}

.image-edge.dark h1,
.image-edge.dark h2,
.image-edge.dark h3,
.image-edge.dark h4,
.image-edge.dark h5 {
    color: #fff
}

.presentation-content h2,
.image-edge h2 {
    line-height: 56px;
    font-weight: 600;
    font-size: 40px;
    letter-spacing: -.5px
}

.presentation-content p,
.image-edge p {
    font-size: 20px;
    color: #828282;
    line-height: 34px
}

.image-edge.dark p {
    color: rgba(255, 255, 255, .8)
}

.image-edge-content ul {
    list-style: none;
    padding: 0
}

.image-edge-content ul li {
    margin-bottom: 30px
}

.image-edge-content ul li:last-child {
    margin-bottom: 0
}

.image-edge-content h4 i {
    position: relative;
    top: -1px;
    margin-right: 2px
}

@media (max-width: 767px) {
    .edge-bg {
        position: relative;
        height: 400px;
        width: 100%;
        display: block;
        left: 0;
        right: 0
    }
}

.flip-banner {
    display: block;
    width: 100%;
    height: 180px;
    position: relative;
    text-align: center;
    overflow: hidden;
    z-index: 9999
}

.flip-banner:after {
    background-color: #000;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    z-index: 100;
    transition: all .6s
}

.flip-banner:hover:after {
    opacity: .6
}

.flip-banner-content {
    position: relative;
    top: 50%;
    z-index: 200
}

.flip-banner h2 {
    font-size: 32px;
    line-height: 40px;
    position: relative;
    width: 100%;
    display: block
}

.flip-banner-content .flip-visible,
.flip-banner-content .flip-hidden {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    transition: all .45s cubic-bezier(.87, -.41, .19, 1.44)
}

.flip-banner:hover .flip-banner-content .flip-hidden,
.flip-banner-content .flip-visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(-50%)
}

.flip-banner:hover .flip-banner-content .flip-visible,
.flip-banner-content .flip-hidden {
    opacity: 0;
    visibility: hidden;
    transform: translateY(150%)
}

.flip-banner:hover .flip-banner-content .flip-visible {
    transform: translateY(-150%)
}

.flip-banner i {
    padding: 0 10px
}

.flip-banner .sl {
    position: relative;
    top: 1px;
    font-size: 26px
}

@media (max-width: 767px) {
    .flip-banner .sl {
        font-size: 18px
    }

    .flip-banner h2 {
        font-size: 22px;
        line-height: 34px
    }
}

.tabs-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%
}

.tabs-nav li a i {
    padding-right: 5px;
    font-size: 16px;
    top: 1px;
    position: relative
}

.tabs-nav li {
    float: left;
    line-height: 45px;
    padding: 0;
    margin-right: 4px;
    position: relative;
    z-index: 12
}

.tabs-nav li a,
.tab-reviews {
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out
}

.tabs-nav li a {
    border-bottom: 1px solid transparent;
    color: #333;
    display: inline-block;
    outline: 0;
    padding: 7px 15px;
    text-decoration: none;
    font-size: 15px;
    z-index: 11;
    position: relative;
    margin-bottom: -1px
}

.tabs-nav li a:hover {
    border-color: #66676b
}

.tab-reviews {
    color: #aaa
}

.tabs-nav li.active .tab-reviews {
    color: #fff
}

.tabs-nav li a:hover,
.tabs-nav li.active a {
    border-color: #66676b;
    color: #66676b;
    z-index: 11
}

.tab-content {
    padding: 28px 33px;
    position: relative;
    z-index: 10;
    display: inline-block;
    width: 100%
}

.tabs-container {
    overflow: hidden;
    width: 100%
}

.tab-content p:last-child {
    margin-bottom: 0
}

.style-2 .tabs-container {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    border-radius: 3px
}

.style-1 .tabs-container .tab-content {
    border: 0;
    border-top: 1px solid #e0e0e0;
    padding: 30px 0 0
}

.style-3 .tabs-nav {
    text-align: center
}

.style-3 .tabs-nav li {
    float: none;
    line-height: 32px;
    padding: 0;
    margin-right: 4px;
    position: relative;
    z-index: 12;
    display: inline-block;
    text-align: center
}

.style-3 .tabs-nav li a {
    border: 0;
    color: #333;
    display: inline-block;
    outline: 0;
    padding: 7px 24px;
    text-decoration: none;
    font-size: 14px;
    z-index: 11;
    position: relative;
    margin-bottom: -1px;
    background-color: #fff;
    border-radius: 3px;
    font-size: 13px
}

.style-3 .tabs-nav li a:hover,
.style-3 .tabs-nav li.active a {
    border-color: #66676b;
    background-color: #66676b;
    color: #fff;
    z-index: 11
}

.style-3 .tabs-container .tab-content {
    border: 0;
    padding: 30px 0 0
}

.style-4 .tabs-nav {
    width: 35%;
    display: table-cell
}

.style-4 .tabs-container {
    width: 65%;
    padding-left: 20px;
    display: table-cell;
    vertical-align: middle
}

.style-4 .tabs-container .tab-content {
    border: 0;
    padding: 10px 0 0
}

.style-4.tabs {
    display: table
}

.style-4 .tabs-nav li a {
    float: none;
    width: 90%;
    border: 0;
    padding: 0;
    background-color: #fff;
    margin: 0 0 1px;
    border: 0;
    padding: 9px 24px;
    line-height: 30px;
    border-radius: 3px
}

.style-4 .tabs-nav li.active a {
    background-color: #66676b;
    color: #fff
}

.style-4 .tabs-nav li {
    width: 100%
}

.style-5 .tabs-nav li {
    width: 33.33%
}

.stytle-5 .tabs-container .widget-text {
    position: relative;
    top: 50px;
    transform: transitionY(-50%);
    display: inline-block
}

.style-5 .tabs-nav {
    bottom: 5px;
    position: relative
}

.style-5 .tabs-nav li a {
    border: 0;
    background-color: #f2f2f2;
    color: #333;
    border-radius: 4px;
    padding: 0
}

.style-5 .tabs-nav li.active a {
    background-color: #66676b;
    color: #fff
}

.style-5 .tabs-container .tab-content {
    box-shadow: none;
    border: 0;
    padding: 20px 0 0
}

.rememberme {
    display: block;
    margin-top: 10px;
    cursor: pointer
}

.sign-in-form .tabs-container {
    overflow: visible
}

.sign-in-form .tabs-nav li {
    line-height: 15px
}

.sign-in-form .tabs-nav li a {
    padding: 0 15px 18px;
    color: #666
}

.sign-in-form .tabs-nav li a:hover,
.sign-in-form .tabs-nav li.active a {
    color: #66676b
}

.lost_password a {
    margin: 0;
    color: #666;
    font-size: 15px
}

.lost_password:hover a {
    color: #66676b
}

.rememberme input {
    margin: 0 2px 0 0;
    display: inline-block;
    min-height: auto;
    width: auto
}

.myaccount input.button {
    margin-bottom: 0
}

.sign-in-form label {
    position: relative;
    color: #666;
    font-size: 15px
}

.sign-in-form label input {
    margin-top: 8px;
    padding-left: 45px;
    height: 53px
}

.sign-in-form label input[type=checkbox] {
    height: auto
}

.sign-in-form input {
    transition: box-shadow .2s !important
}

.sign-in-form input:focus {
    transition: box-shadow .2s !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .07)
}

.sign-in-form label i {
    font-size: 21px;
    color: #a0a0a0;
    position: absolute;
    left: 15px;
    bottom: 13px
}

.sign-in-form .button {
    line-height: 26px;
    height: auto;
    min-width: 120px;
    margin-bottom: 0
}

.social-icons {
    padding-left: 5px
}

.social-icons li {
    display: inline;
    float: left;
    text-align: left;
    margin-right: 16px;
    margin-top: 11px;
    transform: scale(1.19);
    -webkit-transform: scale(1.19);
    -webkit-filter: blur(radius)
}

.social-icons li a {
    width: 44px;
    height: 44px;
    display: inline-block;
    background-color: #f3f3f3;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    position: relative;
    overflow: hidden;
    border: 1px solid transparent
}

.social-icons.fixed li a {
    display: block
}

.social-icons li a i {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    position: absolute;
    z-index: 20;
    transform: scale(.9);
    -webkit-transform: scale(.9);
    -webkit-filter: blur(radius)
}

.social-icons li a:hover,
.social-icons li a i {
    color: #fff
}

.social-icons li a i {
    top: -30px;
    opacity: 0
}

.social-icons li a:hover i {
    top: 0;
    opacity: 1
}

.social-icons.rounded li a {
    border-radius: 50%
}

.social-icons.border li a {
    background-color: #fff;
    border: 1px solid #e0e0e0
}

.social-icons.border li a:hover {
    border-color: transparent
}

.social-icons.color li a {
    border-color: transparent
}

.social-icons.color li a i,
.social-icons.color li a:hover i {
    top: 0;
    opacity: 1
}

.social-icons.color a:before {
    opacity: 0
}

.social-icons.fixed {
    position: fixed;
    left: 0;
    padding-left: 2px;
    top: 100px;
    width: 50px
}

.social-icons.fixed li {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: 7px
}

.social-icons.fixed li a {
    background-color: rgba(51, 51, 51, .35)
}

.social-icons.fixed li a:before {
    color: #fff
}

.twitter:before,
.wordpress:before,
.facebook:before,
.linkedin:before,
.steam:before,
.tumblr:before,
.github:before,
.delicious:before,
.instagram:before,
.xing:before,
.amazon:before,
.dropbox:before,
.paypal:before,
.lastfm:before,
.gplus:before,
.yahoo:before,
.pinterest:before,
.dribbble:before,
.flickr:before,
.reddit:before,
.vimeo:before,
.spotify:before,
.rss:before,
.youtube:before,
.blogger:before,
.appstore:before,
.digg:before,
.evernote:before,
.fivehundredpx:before,
.forrst:before,
.stumbleupon:before,
.dribbble:before {
    font-family: fontello;
    font-size: 16px;
    line-height: 16px;
    color: #a0a0a0;
    -webkit-transition: all .15s ease-in-out;
    -moz-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    -ms-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    position: absolute;
    z-index: 20;
    transform: scale(.9)
}

.twitter:before,
.social-icons li a.twitter i {
    font-size: 14px
}

.twitter i,
.twitter:before {
    margin: 13px 0 0 12px
}

.twitter:before {
    content: "\54";
    top: 0;
    opacity: 1
}

.twitter:hover:before {
    top: 30px;
    opacity: 0
}

.twitter:hover {
    background-color: #3bc1ed !important
}

.twitter:hover,
.social-icons.color .twitter,
.social-icons.fixed .twitter:hover {
    background-color: #3bc1ed;
    border-color: #3bc1ed
}

.dribbble i,
.dribbble:before {
    margin: 13px 0 0 13px
}

.dribbble:before {
    content: "\44";
    top: 0;
    opacity: 1
}

.dribbble:hover:before {
    top: 30px;
    opacity: 0
}

.dribbble:hover {
    background-color: #ef5a92 !important
}

.social-icons.color .dribbble,
.social-icons.fixed .dribbble:hover {
    background-color: #ef5a92;
    border-color: #ef5a92
}

.facebook i,
.facebook:before {
    margin: 13px 0 0 16px
}

.facebook:before {
    content: "\66";
    top: 0;
    opacity: 1
}

.facebook:hover:before {
    top: 30px;
    opacity: 0
}

.facebook:hover {
    background-color: #4a6d9d !important
}

.social-icons.color .facebook,
.social-icons.fixed .facebook:hover {
    background-color: #4a6d9d;
    border-color: #4a6d9d
}

.linkedin i,
.linkedin:before {
    margin: 13px 0 0 13px
}

.linkedin:before {
    content: "\4c";
    top: 0;
    opacity: 1
}

.linkedin:hover:before {
    top: 30px;
    opacity: 0
}

.linkedin:hover {
    background-color: #0b7bb5 !important
}

.social-icons.color .linkedin,
.social-icons.fixed .linkedin:hover {
    background-color: #0b7bb5;
    border-color: #0b7bb5
}

.delicious i,
.delicious:before {
    margin: 13px 0 0 13px
}

.delicious:before {
    content: "\23";
    top: 0;
    opacity: 1
}

.delicious:hover:before {
    top: 30px;
    opacity: 0
}

.delicious:hover {
    background-color: #3274d1 !important
}

.social-icons.color .delicious,
.social-icons.fixed .delicious:hover {
    background-color: #3274d1;
    border-color: #3274d1
}

.paypal i,
.paypal:before {
    margin: 13px 0 0 13px
}

.paypal:before {
    content: "\24";
    top: 0;
    opacity: 1
}

.paypal:hover:before {
    top: 30px;
    opacity: 0
}

.paypal:hover {
    background-color: #2e689a !important
}

.social-icons.color .paypal,
.social-icons.fixed .paypal:hover {
    background-color: #2e689a;
    border-color: #2e689a
}

.gplus i,
.gplus:before {
    margin: 13px 0 0 13px
}

.gplus:before {
    content: "\f0d5";
    top: 0;
    opacity: 1
}

.gplus:hover:before {
    top: 30px;
    opacity: 0
}

.gplus:hover {
    background-color: #d53824 !important
}

.social-icons.color .gplus,
.social-icons.fixed .gplus:hover {
    background-color: #d53824;
    border-color: #d53824
}

.stumbleupon i,
.stumbleupon:before {
    margin: 13px 0 0 11px
}

.stumbleupon:before {
    content: "\2f";
    top: 0;
    opacity: 1
}

.stumbleupon:hover:before {
    top: 30px;
    opacity: 0
}

.stumbleupon:hover {
    background-color: #eb4924 !important
}

.social-icons.color .stumbleupon,
.social-icons.fixed .stumbleupon:hover {
    background-color: #eb4924;
    border-color: #eb4924
}

.fivehundredpx i,
.fivehundredpx:before {
    margin: 13px 0 0 11px
}

.fivehundredpx:before {
    content: "\30";
    top: 0;
    opacity: 1
}

.fivehundredpx:hover:before {
    top: 30px;
    opacity: 0
}

.fivehundredpx:hover {
    background-color: #444 !important
}

.social-icons.color .fivehundredpx,
.social-icons.fixed .fivehundredpx:hover {
    background-color: #444;
    border-color: #444
}

.pinterest i,
.pinterest:before {
    margin: 13px 0 0 15px
}

.pinterest:before {
    content: "\31";
    top: 0;
    opacity: 1
}

.pinterest:hover:before {
    top: 30px;
    opacity: 0
}

.pinterest:hover {
    background-color: #cb1f25 !important
}

.social-icons.color .pinterest,
.social-icons.fixed .pinterest:hover {
    background-color: #cb1f25;
    border-color: #cb1f25
}

.forrst i,
.forrst:before {
    margin: 13px 0 0 15px
}

.forrst:before {
    content: "\3a";
    top: 0;
    opacity: 1
}

.forrst:hover:before {
    top: 30px;
    opacity: 0
}

.forrst:hover {
    background-color: #40833e !important
}

.social-icons.color .forrst,
.social-icons.fixed .forrst:hover {
    background-color: #40833e;
    border-color: #40833e
}

.digg i,
.digg:before {
    margin: 13px 0 0 12px
}

.digg:before {
    content: "\3b";
    top: 0;
    opacity: 1
}

.digg:hover:before {
    top: 30px;
    opacity: 0
}

.digg:hover {
    background-color: #205685 !important
}

.social-icons.color .digg,
.social-icons.fixed .digg:hover {
    background-color: #205685;
    border-color: #205685
}

.spotify i,
.spotify:before {
    margin: 13px 0 0 13px
}

.spotify:before {
    content: "\3d";
    top: 0;
    opacity: 1
}

.spotify:hover:before {
    top: 30px;
    opacity: 0
}

.spotify:hover {
    background-color: #6ca405 !important
}

.social-icons.color .spotify,
.social-icons.fixed .spotify:hover {
    background-color: #6ca405;
    border-color: #6ca405
}

.reddit i,
.reddit:before {
    margin: 13px 0 0 12px
}

.reddit:before {
    content: "\3e";
    top: 0;
    opacity: 1
}

.reddit:hover:before {
    top: 30px;
    opacity: 0
}

.reddit:hover {
    background-color: #404040 !important
}

.social-icons.color .reddit,
.social-icons.fixed .reddit:hover {
    background-color: #404040;
    border-color: #404040
}

.appstore i,
.appstore:before {
    margin: 13px 0 0 14px
}

.appstore:before {
    content: "\41";
    top: 0;
    opacity: 1
}

.appstore:hover:before {
    top: 30px;
    opacity: 0
}

.appstore:hover {
    background-color: silver !important
}

.social-icons.color .appstore,
.social-icons.fixed .appstore:hover {
    background-color: silver;
    border-color: silver
}

.blogger i,
.blogger:before {
    margin: 13px 0 0 13px;
    font-size: 14px
}

.blogger:before {
    content: "\42";
    top: 0;
    opacity: 1
}

.blogger:hover:before {
    top: 30px;
    opacity: 0
}

.blogger:hover {
    background-color: #ff6501 !important
}

.social-icons.color .blogger,
.social-icons.fixed .blogger:hover {
    background-color: #ff6501;
    border-color: #ff6501
}

.dribbble i,
.dribbble:before {
    margin: 13px 0 0 13px
}

.dribbble:before {
    content: "\44";
    top: 0;
    opacity: 1
}

.dribbble:hover:before {
    top: 30px;
    opacity: 0
}

.dribbble:hover {
    background-color: #ef5a92 !important
}

.social-icons.color .dribbble,
.social-icons.fixed .dribbble:hover {
    background-color: #ef5a92;
    border-color: #ef5a92
}

.evernote i,
.evernote:before {
    margin: 13px 0 0 15px
}

.evernote:before {
    content: "\45";
    top: 0;
    opacity: 1
}

.evernote:hover:before {
    top: 30px;
    opacity: 0
}

.evernote:hover {
    background-color: #6bb130 !important
}

.social-icons.color .evernote,
.social-icons.fixed .evernote:hover {
    background-color: #6bb130;
    border-color: #6bb130
}

.flickr i,
.flickr:before {
    margin: 13px 0 0 13px
}

.flickr:before {
    content: "\46";
    top: 0;
    opacity: 1
}

.flickr:hover:before {
    top: 30px;
    opacity: 0
}

.flickr:hover {
    background-color: #136dd5 !important
}

.social-icons.color .flickr,
.social-icons.fixed .flickr:hover {
    background-color: #136dd5;
    border-color: #136dd5
}

.rss i,
.rss:before {
    margin: 13px 0 0 14px;
    font-size: 14px
}

.rss:before {
    content: "\52";
    top: 0;
    opacity: 1
}

.rss:hover:before {
    top: 30px;
    opacity: 0
}

.rss:hover {
    background-color: #fe8f00 !important
}

.social-icons.color .rss,
.social-icons.fixed .rss:hover {
    background-color: #fe8f00;
    border-color: #fe8f00
}

.youtube i,
.youtube:before {
    margin: 13px 0 0 14px;
    font-size: 18px
}

.youtube:before {
    content: "\55";
    top: 0;
    opacity: 1
}

.youtube:hover:before {
    top: 30px;
    opacity: 0
}

.youtube:hover {
    background-color: #bf2f29 !important
}

.social-icons.color .youtube,
.social-icons.fixed .youtube:hover {
    background-color: #bf2f29;
    border-color: #bf2f29
}

.vimeo i,
.vimeo:before {
    margin: 13px 0 0 13px;
    font-size: 14px
}

.vimeo:before {
    content: "\56";
    top: 0;
    opacity: 1
}

.vimeo:hover:before {
    top: 30px;
    opacity: 0
}

.vimeo:hover {
    background-color: #4c8ab0 !important
}

.social-icons.color .vimeo,
.social-icons.fixed .vimeo:hover {
    background-color: #4c8ab0;
    border-color: #4c8ab0
}

.xing i,
.xing:before {
    margin: 13px 0 0 14px
}

.xing:before {
    content: "\58";
    top: 0;
    opacity: 1
}

.xing:hover:before {
    top: 30px;
    opacity: 0
}

.xing:hover {
    background-color: #036568 !important
}

.social-icons.color .xing,
.social-icons.fixed .xing:hover {
    background-color: #036568;
    border-color: #036568
}

.yahoo i,
.yahoo:before {
    margin: 13px 0 0 11px
}

.yahoo:before {
    content: "\59";
    top: 0;
    opacity: 1
}

.yahoo:hover:before {
    top: 30px;
    opacity: 0
}

.yahoo:hover {
    background-color: #9251a2 !important
}

.social-icons.color .yahoo,
.social-icons.fixed .yahoo:hover {
    background-color: #9251a2;
    border-color: #9251a2
}

.amazon i,
.amazon:before {
    margin: 13px 0 0 12px
}

.amazon:before {
    content: "\61";
    top: 0;
    opacity: 1
}

.amazon:hover:before {
    top: 30px;
    opacity: 0
}

.amazon:hover {
    background-color: #2f3842 !important
}

.social-icons.color .amazon,
.social-icons.fixed .amazon:hover {
    background-color: #2f3842;
    border-color: #2f3842
}

.steam i,
.steam:before {
    margin: 13px 0 0 11px
}

.steam:before {
    content: "\62";
    top: 0;
    opacity: 1
}

.steam:hover:before {
    top: 30px;
    opacity: 0
}

.steam:hover {
    background-color: #383838 !important
}

.social-icons.color .steam,
.social-icons.fixed .steam:hover {
    background-color: #383838;
    border-color: #383838
}

.dropbox i,
.dropbox:before {
    margin: 13px 0 0 13px
}

.dropbox:before {
    content: "\64";
    top: 0;
    opacity: 1
}

.dropbox:hover:before {
    top: 30px;
    opacity: 0
}

.dropbox:hover {
    background-color: #2c92dd !important
}

.social-icons.color .dropbox,
.social-icons.fixed .dropbox:hover {
    background-color: #2c92dd;
    border-color: #2c92dd
}

.github i,
.github:before {
    margin: 13px 0 0 13px
}

.github:before {
    content: "\67";
    top: 0;
    opacity: 1
}

.github:hover:before {
    top: 30px;
    opacity: 0
}

.github:hover {
    background-color: #5c7381 !important
}

.social-icons.color .github,
.social-icons.fixed .github:hover {
    background-color: #5c7381;
    border-color: #5c7381
}

.lastfm i,
.lastfm:before {
    margin: 13px 0 0 11px
}

.lastfm:before {
    content: "\6c";
    top: 0;
    opacity: 1
}

.lastfm:hover:before {
    top: 30px;
    opacity: 0
}

.lastfm:hover {
    background-color: #d21309 !important
}

.social-icons.color .lastfm,
.social-icons.fixed .lastfm:hover {
    background-color: #d21309;
    border-color: #d21309
}

.tumblr i,
.tumblr:before {
    margin: 13px 0 0 15px
}

.tumblr:before {
    content: "\74";
    top: 0;
    opacity: 1
}

.tumblr:hover:before {
    top: 30px;
    opacity: 0
}

.tumblr:hover {
    background-color: #3d658d !important
}

.social-icons.color .tumblr,
.social-icons.fixed .tumblr:hover {
    background-color: #3d658d;
    border-color: #3d658d
}

.wordpress i,
.wordpress:before {
    margin: 13px 0 0 14px
}

.wordpress:before {
    content: "\77";
    top: 0;
    opacity: 1
}

.wordpress:hover:before {
    top: 30px;
    opacity: 0
}

.wordpress:hover {
    background-color: #383838 !important
}

.social-icons.color .wordpress,
.social-icons.fixed .wordpress:hover {
    background-color: #383838;
    border-color: #383838
}

.instagram i,
.instagram:before {
    margin: 13px 0 0 13px
}

.instagram:before {
    content: "\f31f";
    top: 0;
    opacity: 1
}

.instagram:hover:before {
    top: 30px;
    opacity: 0
}

.instagram:hover {
    background-color: #3f6f94 !important
}

.social-icons.color .instagram,
.social-icons.fixed .instagram:hover {
    background-color: #3f6f94;
    border-color: #3f6f94
}

@font-face {
    font-family: fontello;
    src: url("../fonts/fontello.eot");
    src: url("../fonts/fontello.eot") format('embedded-opentype'), url("../fonts/fontello.woff") format('woff'), url("../fonts/fontello.ttf") format('truetype'), url("../fonts/fontello.svg") format('svg');
    font-weight: 400;
    font-style: normal
}

.social-icons li i {
    font-family: fontello;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 16px;
    font-size: 16px;
    width: auto
}

.icon-delicious:before {
    content: '\23'
}

.icon-paypal:before {
    content: '\24'
}

.icon-gplus:before {
    content: '\f0d5'
}

.icon-stumbleupon:before {
    content: '\2f'
}

.icon-fivehundredpx:before {
    content: '\30'
}

.icon-pinterest:before {
    content: '\31'
}

.icon-forrst:before {
    content: '\3a'
}

.icon-digg:before {
    content: '\3b'
}

.icon-spotify:before {
    content: '\3d'
}

.icon-reddit:before {
    content: '\3e'
}

.icon-appstore:before {
    content: '\41'
}

.icon-blogger:before {
    content: '\42'
}

.icon-dribbble:before {
    content: '\44'
}

.icon-evernote:before {
    content: '\45'
}

.icon-flickr:before {
    content: '\46'
}

.icon-linkedin:before {
    content: '\4c'
}

.icon-rss:before {
    content: '\52'
}

.icon-twitter:before {
    content: '\54'
}

.icon-youtube:before {
    content: '\55'
}

.icon-vimeo:before {
    content: '\56'
}

.icon-xing:before {
    content: '\58'
}

.icon-yahoo:before {
    content: '\59'
}

.icon-amazon:before {
    content: '\61'
}

.icon-steam:before {
    content: '\62'
}

.icon-dropbox:before {
    content: '\64'
}

.icon-facebook:before {
    content: '\66'
}

.icon-github:before {
    content: '\67'
}

.icon-lastfm:before {
    content: '\6c'
}

.icon-tumblr:before {
    content: '\74'
}

.icon-wordpress:before {
    content: '\77'
}

.icon-linkedin-rect:before {
    content: '\f31b'
}

.icon-instagram:before {
    content: '\f31f'
}

.agent ul.social-icons,
#footer ul.social-icons,
.listing-share ul.social-icons,
#top-bar ul.social-icons {
    margin: 0;
    padding: 0
}

.listing-share .social-icons li a,
.agent .social-icons li a,
#footer .social-icons li a,
#top-bar .social-icons li a {
    width: 45px;
    height: 45px;
    display: inline-block;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    border-radius: 0;
    transform: scale(1);
    margin-right: 0
}

.listing-share .social-icons li,
.agent .social-icons li,
#footer .social-icons li,
#top-bar .social-icons li {
    border-radius: 0;
    transform: scale(1);
    -webkit-transform: scale(1);
    -webkit-filter: blur(radius)
}

.listing-share .social-icons li a i,
.listing-share .social-icons li a:before,
.agent .social-icons li a i,
.agent .social-icons li a:before,
#footer .social-icons li a i,
#footer .social-icons li a:before,
#top-bar .social-icons li a i,
#top-bar .social-icons li a:before {
    transform: scale(1.1) translateY(calc(-50% - 2px));
    -moz-transform: scale(1.1) translateY(calc(-50% - 1px));
    -ms-transform: scale(1.1) translateY(-50%);
    left: 0;
    right: 0;
    margin: 0;
    text-align: center;
    margin-top: 55%
}

.listing-share .social-icons li a.twitter i,
.listing-share .social-icons li a.twitter:before,
.agent .social-icons li a.twitter i,
.agent .social-icons li a.twitter:before,
#footer .social-icons li a.twitter i,
#footer .social-icons li a.twitter:before,
#top-bar .social-icons li a.twitter i,
#top-bar .social-icons li a.twitter:before {
    transform: scale(1.1) translateY(calc(-50% - 1px))
}

#top-bar .social-icons {
    float: right
}

.agent .social-icons li,
#footer .social-icons li,
#top-bar .social-icons li {
    margin: 0;
    padding: 0;
    float: left;
    line-height: 0
}

#top-bar .social-icons li a:before,
#top-bar .social-icons li a i {
    font-size: 14px;
    color: #fff
}

#top-bar .social-icons li .twitter:before,
#top-bar .social-icons li .twitter i:before {
    font-size: 12px
}

#top-bar .social-icons li a:hover {
    background: #fff !important;
    border-color: transparent
}

#top-bar .social-icons li a:hover i {
    color: #66676b
}

.listing-share .social-icons li a,
.agent .social-icons li a,
#footer .social-icons li a {
    width: 38px;
    height: 40px;
    background-color: transparent
}

.agent .social-icons,
#footer .social-icons {
    position: relative;
    left: -13px
}

.agent .social-icons li,
#footer .social-icons li {
    margin: 0;
    padding: 0;
    float: left;
    line-height: 0
}

.listing-share .social-icons li a:before,
.listing-share .social-icons li a i,
.agent .social-icons li a:before,
.agent .social-icons li a i,
#footer .social-icons li a:before,
#footer .social-icons li a i {
    font-size: 15px;
    color: #a0a0a0
}

.listing-share .social-icons li .twitter:before,
.listing-share .social-icons li .twitter i:before,
.listing-share .social-icons li .vimeo:before,
.listing-share .social-icons li .vimeo i:before,
.agent .social-icons li .twitter:before,
.agent .social-icons li .twitter i:before,
.agent .social-icons li .vimeo:before,
.agent .social-icons li .vimeo i:before,
#footer .social-icons li .twitter:before,
#footer .social-icons li .twitter i:before,
#footer .social-icons li .vimeo:before,
#footer .social-icons li .vimeo i:before {
    font-size: 13px
}

.listing-share .social-icons li a:hover,
.agent .social-icons li a:hover,
#footer .social-icons li a:hover {
    background: 0 0 !important;
    border-color: transparent
}

.listing-share .social-icons li a:hover i,
.agent .social-icons li a:hover i,
#footer .social-icons li a:hover i {
    color: #66676b
}

.checkboxes {
    list-style: none;
    padding: 0
}

.checkboxes.in-row label {
    margin-top: 9px
}

.checkboxes.one-in-row label {
    margin-top: 9px;
    width: 100%
}

.checkboxes li {
    padding: 4px 0
}

.checkboxes li:last-child {
    margin-bottom: -8px
}

.checkboxes label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 28px;
    margin-right: 20px;
    margin-bottom: 0;
    line-height: 24px;
    font-size: 16px
}

.checkboxes label span {
    color: #909090
}

.checkboxes input[type=checkbox] {
    display: none
}

.checkboxes label:before {
    content: "";
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 3px;
    background-color: #fff;
    border: 2px solid #d0d0d0;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    transition: all .25s;
    font-family: fontawesome;
    font-size: 12px;
    color: #fff;
    text-align: center;
    line-height: 15px
}

.checkboxes input[type=checkbox]:checked + label:before {
    content: "\f00c";
    background-color: #66676b;
    border: 2px solid #66676b
}

.listing-item-container.list-layout {
    background: 0 0;
    background-color: #f9f9f9;
    margin-bottom: 25px
}

.listing-item-container.list-layout:hover {
    transform: translate3d(6px, 0, 0)
}

.listing-item-container.list-layout .listing-item {
    display: flex;
    background-color: transparent;
    height: 220px
}

.listing-item-container.list-layout .listing-item:before {
    display: none
}

.listing-item-container.list-layout .listing-item img {
    border-radius: 0
}

.listing-item-container.list-layout .listing-item-content {
    flex: 5;
    position: relative;
    bottom: 0;
    left: 0;
    padding: 0;
    width: 100%;
    z-index: 50;
    box-sizing: border-box
}

.listing-item-container.list-layout .listing-item-image {
    min-height: 200px;
    flex: 2;
    height: 100%;
    overflow: hidden;
    border-radius: 4px 0 0 4px;
    position: relative
}

.listing-item-container.list-layout .listing-item-image:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    display: block;
    background-color: #272729;
    opacity: .2
}

.listing-item-container.list-layout span.like-icon {
    bottom: 50%;
    transform: translateY(50%);
    background-color: #eee;
    color: #9d9d9d;
    right: 35px
}

.listing-item-container.list-layout span.like-icon.liked,
.listing-item-container.list-layout span.like-icon:hover {
    background-color: #f3103c;
    color: #fff
}

.listing-item-container.list-layout .listing-item-inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 35px;
    padding-right: 120px
}

.listing-item-container.list-layout .listing-item-inner h3 {
    color: #333
}

.listing-item-container.list-layout .listing-item-inner span {
    color: gray
}

.listing-item-container.list-layout .star-rating {
    padding: 8px 0 0
}

.listing-item-container.list-layout .numerical-rating {
    margin: 10px 0 5px;
    display: block
}

.listing-item-container.list-layout .listing-item-details {
    position: absolute;
    bottom: 25px;
    right: 35px;
    left: auto;
    top: auto;
    padding: 0;
    width: auto;
    z-index: 50;
    color: #888;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px
}

.listing-item-container {
    background-color: #f6f6f6;
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    transform: translate3d(0, 0, 0);
    transition: transform .3s;
    margin-bottom: 30px
}

section.fullwidth .listing-item-container,
.fs-content .listing-item-container {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .08);
    background-color: #fff;
    background: #fff
}

section.fullwidth .listing-item-container.list-layout,
.fs-content .listing-item-container.list-layout {
    box-shadow: none
}

.listing-item-container:hover {
    transform: translate3d(0, -6px, 0)
}

.listing-item {
    background: #ccc;
    border-radius: 4px 4px 0 0;
    height: 100%;
    display: block;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    height: 265px;
    z-index: 100;
    cursor: pointer
}

.listing-item img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 4px 4px 0 0
}

.listing-item:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    background: linear-gradient(to top, rgba(35, 35, 37, 0.9) 0%, rgba(35, 35, 37, 0.45) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
    background-color: rgba(35, 35, 37, .2);
    border-radius: 4px 4px 0 0;
    opacity: 1
}

.listing-item-content {
    position: absolute;
    bottom: 28px;
    left: 0;
    padding: 0 32px;
    padding-right: 90px;
    width: 100%;
    z-index: 50;
    box-sizing: border-box
}

.listing-item-content h3 {
    color: #fff;
    font-size: 20px;
    bottom: -1px;
    position: relative;
    font-weight: 500;
    margin: 0;
    line-height: 31px
}

.listing-item-content span {
    font-size: 16px;
    font-weight: 300;
    display: inline-block;
    color: rgba(255, 255, 255, .7)
}

.listing-item-container.list-layout span.tag,
.listing-item-content span.tag {
    text-transform: uppercase;
    font-size: 9px;
    letter-spacing: 2.5px;
    font-weight: 60;
    background: rgba(255, 255, 255, .3);
    border-radius: 50px;
    padding: 4px 16px;
    line-height: 20px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 9px
}

.listing-item-container.list-layout span.tag {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: #66676b;
    margin: 0;
    z-index: 10;
    line-height: 14px;
    padding: 7px 16px;
    margin-right: 20px;
    text-align: center
}

.listing-item-details {
    position: absolute;
    top: 24px;
    left: 0;
    padding: 0 32px;
    width: 100%;
    z-index: 50;
    color: #fff;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 15px
}

.listing-item-details ul {
    margin: 0;
    list-style: none;
    padding: 0
}

.listing-item-details ul li {
    display: inline-block;
    margin-right: 6px;
    line-height: 20px
}

.listing-item-details ul li i {
    display: inline-block;
    margin: 0 3px 0 0;
    position: relative;
    top: 0;
    font-size: 14px
}

.listing-item-container.compact .listing-item {
    border-radius: 4px
}

.listing-item-container.compact .star-rating {
    padding: 5px 0 0
}

.listing-item-container.compact .rating-counter {
    color: rgba(255, 255, 255, .7)
}

.listing-item-container.compact .star-rating .star.empty:before,
.listing-item-container.compact .star-rating .star.half:before {
    color: rgba(255, 255, 255, .3)
}

.listing-item-container.compact .listing-item-content span.tag {
    background-color: #66676b
}

.numerical-rating {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    text-align: center;
    line-height: 26px;
    height: 26px;
    width: 44px;
    display: inline-block;
    position: relative;
    border-radius: 50px;
    letter-spacing: -.5px;
    background-color: rgba(255, 255, 255, .2);
    margin: 15px 32px
}

.compact .numerical-rating {
    margin: 0 0 8px
}

.numerical-rating:before {
    content: attr(data-rating)
}

.numerical-rating.high {
    background-color: #64bc36
}

.numerical-rating.mid {
    background-color: #c0c52a
}

.numerical-rating.low {
    background-color: #ee3535
}

.infoBox .numerical-rating .rating-counter,
.numerical-rating .rating-counter {
    position: absolute;
    top: 0;
    left: 14px;
    width: auto;
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    width: 150px;
    color: #909090
}

.infoBox .numerical-rating .rating-counter {
    left: 12px
}

.infoBox .numerical-rating {
    margin: 0;
}

.star-rating {
    display: block;
    font-size: 17px
}

.listing-item-container .star-rating {
    padding: 15px 32px
}

.star-rating .star {
    display: inline-block;
    margin: 0;
    padding: 0;
    float: left;
    margin-right: 4px;
    position: relative
}

.rating-counter {
    color: #909090;
    padding-left: 5px;
    display: inline-block;
    font-size: 15px
}

.star-rating .rating-counter {
    top: -1px;
    position: relative
}

.infoBox .star-rating .rating-counter {
    font-size: 15px;
    top: -2px
}

.star-rating .star.half:after,
.star-rating .star:before {
    font-family: fontawesome;
    content: "\f005";
    display: block;
    color: #ffc600
}

.star-rating .star.empty:before {
    color: #ddd
}

.rating:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.star-rating .star.half:before {
    color: #ddd
}

.star-rating .star.half:after {
    color: #ffc600;
    position: absolute;
    top: 0;
    width: 50%;
    display: block;
    height: 100%;
    overflow: hidden
}

span.like-icon {
    color: #fff;
    font-size: 19px;
    position: absolute;
    z-index: 101;
    right: 30px;
    bottom: 30px;
    cursor: normal;
    background-color: rgba(24, 24, 24, .4);
    display: block;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    transition: all .4s
}

.like-icon:before,
.like-icon:after {
    transition: opacity .3s, transform .4s cubic-bezier(0, -.41, .19, 2.5);
    font-family: fontawesome;
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    top: 9px
}

.like-icon:before {
    content: "\f08a";
    transform: scale(.95)
}

.like-icon:after {
    content: "\f004";
    opacity: 0;
    transform: scale(.5)
}

.like-icon.liked:after {
    opacity: 1;
    transform: scale(.95)
}

.like-icon:hover,
.like-icon.liked {
    background-color: #f3103c
}

.like-icon.liked:before {
    opacity: 0
}

.listing-item {
    overflow: hidden
}

.listing-badge {
    background-color: #333;
    float: left;
    position: absolute;
    transform: rotate(45deg);
    right: -64px;
    top: 22px;
    text-align: center;
    width: 200px;
    font-size: 12.5px;
    margin: 0;
    z-index: 999;
    color: #fff;
    font-weight: 500;
    line-height: 28px
}

.listing-badge.now-open {
    background-color: #54ba1d
}

.listing-badge.now-closed {
    background-color: #e91721
}

.categories-boxes-container {
    width: calc(100% + 20px);
    left: -20px;
    position: relative;
    display: flex;
    flex-wrap: wrap
}

.category-small-box {
    float: left;
    background-color: #f2f2f2;
    border-radius: 4px;
    margin: 20px 0 0 20px;
    padding: 22px 20px;
    text-align: center;
    color: white;
    transition: .4s;
    transform: scale(1);
    vertical-align: top;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.category-small-box:hover {
    background-color: #66676b;
    color: #fff;
    -webkit-transform: scale(1.06);
    -moz-transform: scale(1.1)
}

.category-small-box:hover h4 {
    font-size: 17px
}

.category-small-box h4 {
    font-size: 16px;
    color: #333;
    transition: .4s;
    line-height: 22px;
    margin-top: 8px
}

.category-small-box i {
    font-size: 42px;
    height: 40px;
    display: inline-block;
    margin-top: 20px
}

.category-small-box:hover h4 {
    color: #fff
}

@media (max-width: 768px) {
    .category-small-box {
        width: calc(100% * (1 / 2) - 21px)
    }
}

@media (min-width: 768px) {
    .category-small-box {
        width: calc(100% * (1 / 3) - 21px)
    }
}

@media (min-width: 992px) {
    .category-small-box {
        width: calc(100% * (1 / 3) - 21px)
    }
}

@media (min-width: 1240px) {
    .category-small-box {
        width: calc(100% * (1 / 6) - 21px)
    }
}

#titlebar {
    background-color: #f8f8f8;
    position: relative;
    padding: 70px 0;
    margin-bottom: 65px
}

#titlebar.gradient {
    background: linear-gradient(to bottom, #efe9e4 0%, #efe9e4);
    padding-bottom: 10px
}

#titlebar span a,
#titlebar span {
    font-size: 20px;
    color: #888;
    margin-bottom: 3px;
    margin-top: 6px;
    display: inline-block
}

#titlebar #breadcrumbs span {
    font-size: 13px
}

#titlebar h1,
#titlebar h2 {
    font-size: 32px;
    line-height: 40px;
    margin: 1px 0 3px
}

#breadcrumbs {
    position: absolute;
    right: 15px;
    display: none;
    font-size: 15px;
    font-weight: 400;
    color: #888;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

@media screen and (max-width: 600px) {
    #breadcrumbs {
        visibility: hidden;
    clear: both;
    float: left;
    display: none;


    }
    #breadcrumbs ul {
      visibility: hidden;
    clear: both;
    float: left;
    display: none;
}

}

#breadcrumbs ul {
    margin: 0
}

#breadcrumbs ul li a {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: #888
}

#breadcrumbs ul li a:hover {
    color: #666
}

#breadcrumbs ul li {
    display: inline-block;
    list-style: none;
    margin: 0 0 0 10px
}

#breadcrumbs ul li:first-child:before {
    display: none
}

#breadcrumbs ul li:before {
    content: "\f105";
    font-family: fontawesome;
    color: #a0a0a0;
    font-size: 14px;
    margin-right: 15px
}

#titlebar.photo-bg #breadcrumbs ul li,
#titlebar.photo-bg #breadcrumbs ul li a,
#titlebar.photo-bg #breadcrumbs ul li:before {
    color: #fff
}

.parallax #titlebar {
    background-color: transparent;
    position: relative;
    z-index: 999;
    padding: 90px 0
}

.parallax #titlebar h1,
.parallax #titlebar h2,
.parallax #titlebar #breadcrumbs ul li,
.parallax #titlebar #breadcrumbs ul li a,
.parallax #titlebar #breadcrumbs ul li::before {
    color: #fff
}

.parallax #titlebar span a,
.parallax #titlebar span {
    color: rgba(255, 255, 255, .7)
}

#not-found {
    margin: 40px 0 80px
}

#not-found.center {
    text-align: center
}

#not-found h2 {
    text-align: center;
    font-size: 220px;
    line-height: 210px;
    font-weight: 500;
    letter-spacing: -8px
}

#not-found p {
    text-align: center;
    font-size: 30px;
    line-height: 48px;
    display: inline-block;
    margin-top: 10px;
    color: #999
}

#not-found i {
    color: #e0e0e0;
    font-size: 190px;
    top: 16px;
    position: relative
}

@media (max-width: 767px) {
    #not-found h2 {
        font-size: 110px;
        line-height: 110px
    }

    #not-found p {
        font-size: 20px;
        line-height: 38px
    }

    #not-found i {
        font-size: 120px;
        top: 16px
    }
}

#dashboard {
    background-color: #f7f7f7;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    padding-top: 80px
}

.dashboard-content,
.dashboard-nav {
    flex: 1
}

.dashboard-content {
    padding: 40px 45px;
    padding-bottom: 0;
    position: relative;
    z-index: 10;
    height: 100%;
    margin-left: 260px
}

.dashboard-nav,
.dashboard #logo {
    min-width: 260px;
    max-width: 260px
}

.dashboard-nav {
    position: fixed;
    min-height: 100vh;
    overflow: hidden
}

.dashboard-nav-inner {
    height: 100%;
    width: 277px;
    overflow-y: scroll;
    position: relative;
    display: block;
    padding: 10px 0 25px
}

.messages-container-inner .messages-inbox ul::-webkit-scrollbar {
    width: 17px
}

.dashboard-nav-inner {
    max-height: 900px;
    width: calc(100% + 17px);
    overflow-y: scroll;
    position: relative;
    display: block;
    padding: 10px 0 25px
}

@media (max-width: 991px) {
    .dashboard-nav-inner {
        max-height: 900px;
        width: calc(100% + 18px)
    }
}

header.fullwidth.dashboard .container {
    padding-left: 285px
}

.dashboard #logo {
    display: block;
    background-color: #333;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0
}

.dashboard #logo a {
    margin-left: 33px;
    display: inline-block;
    height: 100%
}

.dashboard #logo a img {
    top: 50%;
    position: relative;
    transform: translate3d(0, -50%, 0)
}

#dashboard .copyrights {
    padding: 40px 0;
    margin: 0;
    display: block;
    text-align: left;
    border: 0
}

.dashboard #logo a {
    display: none
}

.dashboard #logo a.dashboard-logo {
    display: inline-block
}

.dashboard-nav {
    background-color: #2a2a2a;
    display: block;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .12);
    z-index: 11
}

a.dashboard-responsive-nav-trigger {
    display: none
}

.dashboard-nav ul {
    list-style: none;
    padding: 0;
    margin: 15px 0 0
}

.dashboard-nav ul:before {
    content: attr(data-submenu-title);
    padding: 0 30px;
    margin-bottom: 5px;
    display: block;
    color: #999;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 1px
}

.dashboard-nav ul li {
    color: #eee;
    display: block;
    border-left: 3px solid transparent;
    transition: .3s;
    line-height: 24px;
    font-size: 15px
}

.dashboard-nav ul li a {
    color: #eee;
    display: block;
    padding: 11px 27px;
    transition: .3s;
    cursor: pointer
}

.dashboard-nav ul li a i {
    padding-right: 8px;
    width: 20px
}

.dashboard-nav ul li a:hover {
    color: #fff
}

.dashboard-nav ul li span.nav-tag {
    background-color: rgba(255, 255, 255, .2);
    font-weight: 600;
    display: inline-block;
    font-size: 11px;
    line-height: 20px;
    color: #fff;
    padding: 0;
    padding: 0 7px;
    box-sizing: border-box;
    text-align: center;
    min-width: 20px;
    height: 20px;
    letter-spacing: -.5px;
    text-align: center;
    border-radius: 50px;
    margin-left: 4px
}

.dashboard-nav ul li span.nav-tag.messages {
    top: -2px;
    position: relative
}

.dashboard-nav ul li span.nav-tag.messages,
.dashboard-nav ul li span.nav-tag.green {
    background-color: #64bc36
}

.dashboard-nav ul li span.nav-tag.yellow {
    background-color: #b9be1c
}

.dashboard-nav ul li span.nav-tag.red {
    background-color: #ee3535
}

.dashboard-nav ul li ul {
    padding: 0;
    margin: 0;
    visibility: hidden;
    max-height: 0;
    opacity: 0;
    line-height: 0;
    transition: .2s
}

.dashboard-nav ul li.active ul {
    visibility: visible;
    padding: 0 0 15px;
    line-height: 24px;
    opacity: 1;
    max-height: 300px
}

.dashboard-nav ul li ul li {
    color: #eee;
    background-color: transparent;
    border: 0;
    padding-left: 54px;
    position: relative;
    margin: 0
}

.dashboard-nav ul li ul li a {
    padding: 7px 0
}

.dashboard-nav ul li ul li:hover {
    color: #fff;
    border: 0;
    background-color: transparent
}

.dashboard-nav ul li a:after {
    font-family: fontawesome;
    content: '\f107';
    right: 27px;
    font-size: 16px;
    position: absolute;
    transition: .2s;
    color: rgba(255, 255, 255, .5)
}

.dashboard-nav ul li.active a:after {
    transform: rotate(180deg)
}

.dashboard-nav ul li ul:before,
.dashboard-nav ul li a:only-child:after {
    content: '';
    display: none
}

.dashboard-nav ul li ul li:hover a {
    padding-left: 4px
}

.dashboard-nav ul li.active,
.dashboard-nav ul li:hover {
    color: #fff;
    border-color: #66676b;
    background-color: rgba(255, 255, 255, .05)
}

#dashboard #titlebar {
    padding: 0;
    margin: 0 0 40px;
    display: block;
    background-color: transparent;
    background: 0 0
}

#dashboard #titlebar h2 {
    font-size: 30px;
    line-height: 38px
}

.dashboard-stat {
    display: inline-block;
    padding: 0;
    height: 160px;
    background-color: #444;
    color: #fff;
    border-radius: 4px;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    transition: .3s;
    cursor: default
}

.dashboard-stat:hover {
    transform: translateY(-4px)
}

.dashboard-stat-content {
    position: absolute;
    left: 32px;
    top: 50%;
    width: 45%;
    transform: translateY(-50%)
}

.dashboard-stat-content h4 {
    font-size: 42px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    color: #fff;
    letter-spacing: -1px
}

.dashboard-stat-content span {
    font-size: 18px;
    margin-top: 4px;
    line-height: 24px;
    font-weight: 300;
    display: inline-block
}

.dashboard-stat-icon {
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-40%);
    font-size: 80px;
    opacity: .3
}

.dashboard-stat.color-1 {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.2));
    background-color: #64bc36
}

.dashboard-stat.color-2 {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.1));
    background-color: #363841
}

.dashboard-stat.color-3 {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.3));
    background-color: #ffae00
}

.dashboard-stat.color-4 {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.1));
    background-color: #f3103c
}

#dashboard .pagination ul li a,
#dashboard .pagination-next-prev ul li a {
    background-color: transparent
}

#dashboard .pagination ul li a.current-page,
#dashboard .pagination .current,
#dashboard .pagination ul li a:hover,
#dashboard .pagination-next-prev ul li a:hover {
    background-color: #fff
}

.messages-container {
    margin: 0;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .06);
    border-radius: 4px;
    background-color: #fff
}

.messages-headline {
    font-size: 16px;
    margin: 0;
    padding: 22px 30px;
    color: #333;
    background-color: #fff;
    display: block;
    border-bottom: 1px solid #eaeaea;
    border-radius: 4px 4px 0 0;
    position: relative
}

.messages-headline h4 {
    font-size: 16px;
    color: #333;
    padding: 0;
    margin: 0;
    font-weight: 600;
    display: inline-block
}

.message-action {
    border-radius: 50px;
    font-size: 13px;
    color: #666;
    font-weight: 500;
    display: inline-block;
    transition: .3s;
    color: #666;
    padding: 0;
    float: right
}

.message-action:hover {
    color: #ee3535
}

.messages-inbox ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.message-avatar img {
    display: inline-block;
    width: 54px;
    height: 54px;
    border-radius: 50%
}

.messages-inbox ul li a {
    position: relative;
    display: block;
    padding: 30px
}

.messages-inbox ul li {
    border-bottom: 1px solid #eaeaea;
    transition: .2s
}

.messages-inbox ul li:last-child {
    border-bottom: 0
}

.messages-inbox ul li.unread,
.messages-inbox ul li:hover {
    background-color: #fbfbfb
}

.messages-inbox ul li.active-message {
    background-color: #fafafa
}

.messages-inbox .message-avatar {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%)
}

.messages-inbox .message-by {
    margin-left: 85px;
    font-size: 15px;
    color: #666
}

.messages-inbox .message-by p {
    margin: 0;
    padding: 0;
    line-height: 27px;
    font-size: 15px
}

.message-by-headline h5,
.message-by-headline span {
    display: inline-block
}

.message-by-headline span {
    float: right;
    color: #888;
    font-size: 14px
}

.message-by-headline h5 {
    font-weight: 600;
    color: #333;
    margin: 0 0 7px;
    padding: 0
}

.message-by-headline:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.message-by h5 i {
    background-color: #64bc36;
    border-radius: 50px;
    line-height: 20px;
    font-size: 12px;
    color: #fff;
    font-style: normal;
    padding: 3px 8px;
    margin-left: 3px
}

.messages-container-inner {
    display: flex
}

.messages-container-inner .messages-inbox,
.messages-container-inner .message-content {
    flex: 1
}

.messages-container-inner .messages-inbox {
    overflow: hidden
}

.messages-container-inner .messages-inbox ul {
    max-height: 800px;
    overflow-y: scroll;
    width: 357px
}

.messages-container-inner .messages-inbox ul::-webkit-scrollbar {
    width: 17px
}

.messages-container-inner .message-content {
    padding: 30px
}

.messages-container-inner .messages-inbox {
    max-width: 340px;
    border-right: 1px solid #eaeaea
}

.messages-container-inner .messages-inbox ul li a {
    padding: 25px
}

.messages-container-inner .messages-inbox .message-by p {
    height: 26px;
    max-width: 205px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    color: #888
}

.messages-container-inner .message-avatar img {
    width: 40px;
    height: 40px
}

.messages-container-inner .messages-inbox .message-avatar {
    left: 25px
}

.messages-container-inner .messages-inbox .message-by {
    margin-left: 60px
}

.messages-container-inner .message-by-headline h5 {
    font-size: 14px;
    margin-bottom: 3px;
    max-width: 160px;
    line-height: 23px
}

.messages-container-inner .message-by-headline span {
    font-size: 13px;
    position: absolute;
    top: 25px;
    right: 25px
}

.message-bubble {
    display: block;
    position: relative;
    margin-bottom: 25px
}

.message-bubble .message-avatar {
    position: absolute;
    left: 0;
    top: 0
}

.message-bubble .message-avatar img {
    width: 50px;
    height: 50px
}

.message-bubble .message-text {
    margin-left: 70px;
    background-color: #f6f6f6;
    border-radius: 4px;
    padding: 20px;
    position: relative
}

.message-bubble .message-text p {
    font-size: 15px;
    padding: 0;
    margin: 0;
    line-height: 27px
}

.message-bubble .message-text:before {
    position: absolute;
    top: 12px;
    left: -9px;
    content: "\f0d9";
    font-family: fontawesome;
    font-size: 31px;
    color: #f6f6f6
}

.message-bubble.me .message-avatar {
    left: auto;
    right: 0
}

.message-bubble.me .message-text {
    margin-left: 0;
    margin-right: 70px
}

.message-bubble.me .message-text:before {
    left: auto;
    right: -11px;
    content: "\f0da"
}

.message-reply {
    display: block;
    margin-top: 15px
}

.message-reply textarea {
    max-width: 100%;
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    padding: 15px 20px
}

.message-reply button {
    border-radius: 4px;
    margin-top: 5px
}

.dashboard-list-box {
    margin: 30px 0 0;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .06);
    border-radius: 4px
}

.dashboard-list-box .button {
    padding: 6px 15px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 600;
    margin: 0
}

.dashboard-list-box .button.gray:before {
    display: none
}

.dashboard-list-box .button.gray {
    background-color: #e9e9e9;
    color: #666
}

.dashboard-list-box .button.gray:hover {
    background-color: #66676b;
    color: #fff
}

.dashboard-list-box h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 25px 30px;
    color: #333;
    background-color: #fff;
    display: block;
    border-bottom: 1px solid #eaeaea;
    border-radius: 4px 4px 0 0
}

.dashboard-list-box h4.gray {
    background-color: #fbfbfb
}

.dashboard-list-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border-radius: 0 0 4px 4px
}

.dashboard-list-box ul li {
    padding: 23px 30px;
    border-bottom: 1px solid #eaeaea;
    transition: .3s;
    position: relative
}

.dashboard-list-box.with-icons ul li {
    padding-left: 87px
}

.dashboard-list-box ul li:last-child {
    border: 0
}

.dashboard-list-box ul li:hover {
    background-color: #fbfbfb
}

.dashboard-list-box .numerical-rating {
    margin: 0 3px
}

.dashboard-list-box ul li i.list-box-icon {
    height: 38px;
    width: 38px;
    color: white;
    text-align: center;
    line-height: 37px;
    border-radius: 50%;
    transition: .3s;
    display: inline-block;
    background-color: #eee;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30px
}

.dashboard-list-box ul li:hover i.list-box-icon {
    background-color: #eaeaea
}

.dashboard-list-box-static {
    padding: 30px;
    background-color: #fff
}

.dashboard-list-box-static .button {
    padding: 12px 20px;
    font-size: 15px;
    margin-bottom: 3px
}

a.close-list-item {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    opacity: 0;
    padding: 4px;
    color: #ea2828;
    transition: .3s
}

.dashboard-list-box ul li:hover a.close-list-item {
    opacity: 1
}

.dashboard-list-box ul ul {
    background-color: transparent
}

.dashboard-list-box ul ul li:nth-last-child(2n+1) {
    background-color: transparent
}

.dashboard-list-box ul ul li {
    padding: 0;
    border: 0;
    transition: .3s;
    background-color: transparent;
    display: inline-block
}

.dashboard-list-box ul ul li:hover {
    background-color: transparent
}

.dashboard-list-box .sort-by {
    top: 16px;
    right: 26px
}

.dashboard-list-box .sort-by .chosen-container-single .chosen-single {
    background-color: transparent;
    padding: 0 4px
}

.dashboard-list-box .chosen-container .chosen-results li {
    border: 0
}

.dashboard-list-box.invoices li {
    padding: 20px 30px
}

.dashboard-list-box.invoices ul li strong {
    color: #333
}

.dashboard-list-box.invoices ul ul {
    line-height: 22px;
    padding-bottom: 2px;
    color: #909090
}

.dashboard-list-box.invoices ul ul li {
    padding: 0
}

.dashboard-list-box.invoices ul ul li:after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 11px;
    background-color: #e0e0e0;
    position: relative;
    margin: 0 10px
}

.dashboard-list-box.invoices ul ul li:last-child:after {
    display: none
}

.paid {
    color: #64bc36
}

.unpaid {
    color: #ee3535
}

.buttons-to-right,
.dashboard-list-box .button.to-right {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate3d(0, -49%, 0);
    -moz-transform: translate3d(0, -50%, 0);
    opacity: 0;
    transition: .4s;
    box-shadow: 0 0 10px 15px #fbfbfb
}

.dashboard-list-box li:hover .buttons-to-right,
.dashboard-list-box li:hover .button.to-right {
    opacity: 1
}

.buttons-to-right {
    box-shadow: none
}

.buttons-to-right .button {
    margin-left: 2px
}

.dashboard-list-box .button i {
    padding-right: 2px;
    position: relative;
    top: 1px
}

.dashboard-list-box .button i.fa-remove {
    padding-right: 1px
}

.buttons-to-right a.button.approve:hover {
    background-color: #64bc36
}

.buttons-to-right a.button.reject:hover {
    background-color: #e42929
}

.edit-profile-photo img {
    border-radius: 4px;
    max-width: 240px;
    width: 100%
}

.edit-profile-photo {
    position: relative;
    display: block;
    margin-bottom: 35px
}

.change-photo-btn {
    font-weight: 600;
    padding: 5px 6px;
    color: #333;
    background-color: #fff;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    font-size: 13px;
    width: auto;
    transition: .3s;
    cursor: pointer;
    line-height: 20px;
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: auto
}

.photoUpload {
    position: relative;
    overflow: hidden;
    padding: 3px 12px;
    cursor: pointer
}

.photoUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0)
}

.change-photo-btn:hover {
    color: #fff;
    background-color: #66676b
}

.change-photo-btn i {
    margin-right: 2px
}

.my-profile label {
    margin-top: 27px;
    font-size: 15px;
    font-weight: 600;
    color: #333;
    margin-bottom: 9px
}

.my-profile {
    display: block
}

.my-profile label i {
    margin-right: 3px
}

.my-profile textarea {
    height: 200px
}

.list-box-listing {
    display: flex;
    padding: 5px 0;
    position: relative
}

.list-box-listing-img {
    flex: 1;
    max-width: 150px;
    position: relative
}

.list-box-listing-img a {
    width: 100%;
    height: 100%;
    display: inline-block;
    overflow: hidden;
    position: relative;
    z-index: 10
}

.list-box-listing-img a:before {
    content: "";
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(22, 22, 22, .2);
    border-radius: 4px;
    z-index: 11
}

.list-box-listing-img a img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 4px
}

.bookings {
    margin: 10px 0
}

.bookings .list-box-listing-img a:before {
    display: none
}

.bookings .list-box-listing-img {
    max-width: 70px;
    max-height: 70px;
    border-radius: 50%;
    overflow: hidden
}

.bookings .list-box-listing-content .inner {
    top: 0;
    transform: none
}

.bookings .list-box-listing-img {
    margin-right: 5px
}

.bookings .list-box-listing-content .inner h3 {
    margin-bottom: 20px
}

.inner-booking-list h5 {
    display: inline-block;
    font-weight: 600;
    margin: 0;
    color: #666
}

.inner-booking-list ul {
    display: inline-block;
    padding: 0;
    margin-bottom: 0
}

.inner-booking-list ul li {
    display: inline-block;
    padding: 0;
    margin: 0 0 4px
}

.inner-booking-list ul li:after {
    content: "";
    display: inline-block;
    position: relative;
    margin: 0 10px;
    height: 12px;
    top: 1px;
    background-color: #ddd;
    width: 1px
}

.inner-booking-list ul li:last-child:after {
    display: none
}

.inner-booking-list ul li.highlighted {
    padding: 2px 10px;
    line-height: 22px;
    font-weight: 500;
    font-size: 14px;
    background-color: #eee !important;
    color: red;
    border-radius: 50px;
    display: inline-block
}

.pending-booking .inner-booking-list ul li.highlighted {
    background-color: #e9f7fe !important;
    color: #3184ae
}

.approved-booking .inner-booking-list ul li.highlighted {
    background-color: #ebf6e0 !important;
    color: #5f9025
}

.bookings .inner h3 span.booking-status {
    border-radius: 50px;
    line-height: 20px;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    font-style: normal;
    padding: 2px 8px;
    margin-left: 3px;
    position: relative;
    top: -2px
}

.approved-booking .bookings .inner h3 span.booking-status {
    background-color: #64bc36
}

.pending-booking .bookings .inner h3 span.booking-status {
    background-color: #61b2db
}

.canceled-booking .bookings .inner h3 span.booking-status {
    background-color: #ee3535
}

.list-box-listing-content {
    flex: 1;
    padding-left: 25px
}

.list-box-listing-content .inner {
    position: relative;
    top: 14px;
    vertical-align: top
}

.list-box-listing-content .inner h3 {
    font-size: 18px;
    bottom: -2px;
    position: relative;
    font-weight: 500;
    margin: 0;
    line-height: 27px
}

.list-box-listing-content .inner span {
    font-size: 15px;
    font-weight: 300;
    display: inline-block
}

.list-box-listing-content .inner .numerical-rating,
.list-box-listing-content .inner .star-rating {
    margin: 5px 0 0;
    display: block
}

.dashboard-list-box .comments.listing-reviews li {
    margin: 19px 0 10px
}

.dashboard-list-box a.rate-review {
    border: 0;
    color: #666;
    background-color: #eee;
    padding: 4px 18px
}

.dashboard-list-box a.rate-review:hover {
    background-color: #66676b;
    color: #fff
}

.dashboard-list-box .comment-by-listing {
    display: inline-block;
    color: #888
}

.dashboard-list-box .comment-by-listing.own-comment,
.dashboard-list-box .comment-by-listing.own-comment a {
    color: #444
}

.dashboard-list-box .comment-by-listing a {
    color: #888
}

.dashboard-list-box .comment-by-listing a:hover {
    color: #66676b
}

.add-listing-section label.switch {
    position: absolute;
    right: 40px;
    top: 30px;
    z-index: 100
}

.add-listing-section {
    position: relative
}

.add-listing-section .switcher-content {
    opacity: .4;
    pointer-events: none;
    transition: .4s
}

.add-listing-section.switcher-on .switcher-content {
    opacity: 1;
    pointer-events: all
}

label.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    margin-top: 0;
    margin-bottom: 0
}

.switch input {
    display: none
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 50px;
    transition: .4s
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    transition: .4s;
    border-radius: 50px
}

input:checked + .slider {
    background-color: #66676b
}

input:checked + .slider:before {
    transform: translateX(30px)
}

.add-listing-section {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .06);
    padding: 0 40px 25px
}

.add-listing-headline {
    width: calc(100% + 80px);
    left: -40px;
    position: relative;
    padding: 30px 40px;
    margin: 0 0 30px;
    border-radius: 4px 4px 0 0;
    background-color: #fcfcfc;
    border-bottom: 1px solid #eaeaea
}

.add-listing-headline h3 {
    padding: 0;
    margin: 0;
    line-height: 32px;
    font-size: 22px;
    position: relative;
    top: 1px
}

#add-listing h5 {
    font-size: 15px;
    font-weight: 600;
    color: #333;
    margin-bottom: 17px
}

#add-listing h5 span {
    color: #888;
    font-weight: 400
}

#add-listing h5 i {
    padding-right: 4px
}

.add-listing-headline i {
    padding-right: 7px;
    display: inline-block;
    position: relative;
    top: 1px
}

.button.preview {
    margin-top: 40px;
    font-size: 18px;
    font-weight: 400;
    padding: 11px 26px;
    display: inline-block
}

.button.preview i {
    padding: 0;
    margin: 0 0 0 4px
}

.add-listing-section .row.with-forms input,
.add-listing-section .row.with-forms input[type=text],
.add-listing-section .row.with-forms input[type=password],
.add-listing-section .row.with-forms input[type=email],
.add-listing-section .row.with-forms input[type=number],
.add-listing-section .row.with-forms textarea,
.add-listing-section .row.with-forms input,
.add-listing-section .row.with-forms select,
.add-listing-section .row.with-forms .chosen-container {
    margin-bottom: 22px
}

.add-listing-section textarea {
    min-height: 200px;
    margin-bottom: 35px
}

#add-listing h5.fb-input {
    color: #3b5998
}

#add-listing h5.twitter-input {
    color: #1da1f2
}

#add-listing h5.gplus-input {
    color: #dd4b39
}

.tip {
    font-style: normal;
    position: relative;
    top: -1px;
    left: 2px;
    display: inline-block;
    cursor: help;
    color: #fff;
    font-size: 11px;
    line-height: 0;
    width: 18px;
    height: 18px;
    background: #66676b;
    display: inline-block;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    padding: 0 !important;
    font-weight: 500
}

.tip-content {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 20px));
    -ms-transform: translate(-50%, -100%);
    background: #333;
    color: #fff;
    padding: 13px 15px;
    min-width: 200px;
    max-width: 200px;
    font-size: 13px;
    border-radius: 4px;
    line-height: 19px;
    pointer-events: none;
    z-index: 999;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all .3s
}

.tip:hover .tip-content {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, calc(-100% - 13px));
    -ms-transform: translate(-50%, -115%)
}

.tip:after {
    font-family: fontawesome;
    content: "\f128";
    line-height: 19px;
    display: inline-block;
    position: relative;
    text-align: center;
    -moz-transform: translateY(-1px)
}

.tip-content:after {
    font-family: fontawesome;
    content: "\f0dd";
    position: absolute;
    bottom: 3px;
    left: 50%;
    font-size: 22px;
    color: #333;
    transform: translate(-50%, 50%)
}

.opening-day {
    margin-bottom: 22px
}

.opening-day h5 {
    margin-top: 17px;
    transition: .15s
}

.opening-day:hover h5 {
    color: #66676b !important
}

.opening-day .chosen-container .chosen-results {
    max-height: 200px
}

#pricing-list-container {
    width: 100%;
    margin-top: 10px
}

.pricing-list-item td {
    display: flex;
    width: 100%;
    margin-bottom: 5px
}

.pricing-list-item td .fm-input,
.pricing-list-item td .fm-move,
.pricing-list-item td .fm-close {
    flex: 1;
    position: relative
}

.pricing-list-item td .fm-move,
.pricing-list-item td .fm-close {
    max-width: 40px
}

.pricing-list-item td .fm-move {
    cursor: move;
    line-height: 51px;
    color: #666;
    font-size: 17px
}

.pricing-list-item td .fm-close {
    text-align: right
}

.pricing-list-item td .fm-close a {
    padding: 10px;
    padding-right: 0;
    color: #ea2828;
    display: inline-block
}

.pricing-list-item td .fm-input {
    margin-right: 20px
}

.pricing-list-item td .fm-input.pricing-name {
    max-width: 350px
}

.pricing-list-item td .fm-input.pricing-price {
    max-width: 150px;
    margin-right: 0
}

.pricing-submenu {
    display: block;
    padding-top: 20px
}

.pricing-submenu input {
    background-color: #fafafa
}

.pricing-submenu.pricing-list-item td .fm-input {
    margin-right: 0
}

#pricing-list-container tbody tr:first-child.pricing-list-item.pricing-submenu {
    padding-top: 0
}

.data-unit {
    position: absolute;
    top: 12px;
    right: 19px;
    font-style: normal;
    color: #aaa;
    z-index: 110;
    background-color: transparent;
    outline: 0;
    border: 0;
    padding: 0 0 0 10px;
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    pointer-events: none;
    background-color: #fff
}

.add-pricing-submenu.button:before,
.add-pricing-list-item.button:before {
    display: none
}

.add-pricing-submenu.button,
.add-pricing-list-item.button {
    margin-top: 15px;
    margin-bottom: 5px;
    background-color: white;
    color: #666;
    padding: 8px 22px;
    font-weight: 500
}

.add-pricing-submenu.button:hover,
.add-pricing-list-item.button:hover {
    color: #fff;
    background-color: #66676b
}

.separated-form .add-listing-section {
    border: 1px solid #eaeaea;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .04)
}

.separated-form .button.preview {
    padding: 14px 38px
}

#titlebar i.sl {
    margin-right: 4px
}

.notification.notice.large h4 {
    font-weight: 500
}

.infoBox {
    border-radius: 4px
}

.map-box {
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .12);
    position: relative
}

.map-box .listing-img-container img {
    width: 100%;
    border-radius: 4px 4px 0 0
}

.map-box h4 {
    margin: 0;
    padding: 0
}

.map-box h4 a {
    padding: 0 0 2px;
    font-size: 17px;
    line-height: 25px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    display: inline-block
}

.listing-img-container {
    position: relative;
    height: 100%;
    display: block
}

.map-box h4 a:hover {
    color: #66676b
}

.map-box p {
    padding: 0;
    line-height: 25px;
    margin: 2px 0 0;
    font-size: 14px
}

.map-box ul.listing-details {
    padding: 0;
    font-size: 14px;
    display: none
}

.map-box .listing-title {
    padding: 16px 22px
}

.map-box .listing-img-content {
    padding: 18px 22px
}

.map-box .listing-img-content span {
    font-size: 21px
}

.map-box .listing-img-content .listing-price i {
    font-size: 15px;
    margin: -7px 0 0
}

.infoBox > img {
    position: absolute !important;
    right: 0;
    top: 0
}

.map-box .listing-img-container::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    background: linear-gradient(to top, rgba(35, 35, 37, 0.85) 0%, rgba(35, 35, 37, 0.40) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
    background-color: rgba(35, 35, 37, .2)
}

.map-box .listing-item-content {
    position: absolute;
    bottom: 20px;
    left: 0;
    padding: 0 24px;
    width: 100%;
    z-index: 50;
    box-sizing: border-box
}

.map-box .listing-item-content h3 {
    color: #fff;
    font-size: 18px;
    padding: 0 0 2px;
    font-weight: 500;
    margin: 0;
    line-height: 27px
}

.map-box .listing-item-content span {
    font-size: 15px;
    font-weight: 300;
    display: inline-block;
    color: rgba(255, 255, 255, .7)
}

.infoBox-close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: inline-block;
    z-index: 999;
    text-align: center;
    line-height: 29px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    height: 29px;
    width: 29px;
    background-color: #fff;
    color: #333;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    font-family: fontawesome;
    border-radius: 50%;
    -webkit-text-stroke: 1px #fff
}

.infoBox-close:hover {
    color: #fff;
    background-color: #66676b;
    -webkit-text-stroke: 1px #66676b
}

.map-box-icon {
    height: 38px;
    width: 38px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #3685cf;
    display: block;
    color: #fff;
    font-size: 12px;
    z-index: 101;
    text-align: center;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
    visibility: hidden
}

.map-box-icon:before {
    content: "\f064";
    font-family: fontawesome;
    position: relative;
    top: 7px;
    display: block
}

.map-box-image:hover .map-box-icon {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateX(0)
}

.map-box-image {
    position: relative;
    overflow: hidden;
    display: block
}

.infoBox {
    -webkit-animation: fadeIn .9s;
    animation: fadeIn .9s
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.infoBox .star-rating {
    font-size: 16px;
    margin-top: 1px
}

.infoBox .star-rating span {
    margin-right: 3px
}

.infoBox .rating-counter {
    position: relative;
    top: -1px
}

.infoBox:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff
}

#map {
    height: 100%;
    width: 100%;
    margin: 0;
    z-index: 990
}

#map-container {
    position: relative;
    top: 0;
    left: 0;
    height: 520px;
    width: 100%;
    z-index: 990;
    margin-bottom: 60px
}

.gmnoprint {
    margin: 10px
}

#map-container.fullwidth-home-map {
    height: 620px;
    margin-bottom: 0
}

#map-container.fullwidth-home-map .main-search-inner {
    position: absolute;
    display: block;
    bottom: 60px;
    top: auto;
    transform: none;
    padding-bottom: 0;
    z-index: 9999;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%
}

#map-container.fullwidth-home-map .main-search-input {
    margin-top: 0
}

.cluster img {
    display: none
}

.cluster-visible {
    text-align: center;
    font-size: 15px !important;
    color: #fff !important;
    font-weight: 500;
    border-radius: 50%;
    width: 36px !important;
    height: 36px !important;
    line-height: 36px !important;
    background-color: #66676b
}

.cluster-visible:before {
    border: 7px solid #66676b;
    opacity: .2;
    box-shadow: inset 0 0 0 4px #66676b;
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: cluster-animation 2.5s infinite
}

@keyframes cluster-animation {
    0%,
    100% {
        transform: scale(1.3) rotate(0deg)
    }
    50% {
        transform: scale(1.5) rotate(90deg)
    }
}

.map-marker-container {
    position: absolute;
    margin-top: 10px;
    transform: translate3d(-50%, -100%, 0)
}

.marker-container {
    position: relative;
    margin: 10px auto;
    width: 40px;
    height: 40px;
    z-index: 1;
    border-radius: 50%;
    cursor: pointer;
    top: -5px
}

#singleListingMap .marker-container {
    cursor: default
}

.marker-container {
    perspective: 1e3
}

.marker-card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all .4s ease-in-out;
    position: absolute;
    z-index: 1
}

.marker-container:hover .marker-card,
.map-marker-container.clicked .marker-card {
    transform: rotateY(180deg) translateY(-4px)
}

.marker-arrow {
    width: 0;
    content: "";
    height: 0;
    border-style: solid;
    border-width: 8px 4px 0;
    border-color: #66676b transparent transparent;
    top: 38px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    border-radius: 50%;
    z-index: 1
}

.face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    z-index: 100
}

.face i {
    line-height: 34px;
    font-size: 20px
}

.face.front {
    background-color: #fff;
    border: 2px solid #66676b;
    color: #66676b;
    z-index: 100
}

.face.back {
    transform: rotateY(180deg);
    box-sizing: border-box;
    background: #66676b;
    border-color: #66676b;
    color: #fff
}

.face.back i {
    trasform: scaleX(-1);
    line-height: 38px;
    font-size: 18px
}

.face.back:after {
    content: "";
    display: block;
    width: 36px;
    height: 36px;
    top: 2px;
    left: 2px;
    border: 1px solid rgba(255, 255, 255, .65);
    border-radius: 50%;
    position: absolute
}

.custom-zoom-in,
.custom-zoom-out {
    background-color: #fff;
    color: #333;
    cursor: pointer;
    border-radius: 50%;
    margin: 5px 15px;
    transition: color .3s, background-color .3s;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .2);
    text-align: center;
    font-size: 15px;
    height: 34px;
    width: 34px
}

.custom-zoom-in:hover,
.custom-zoom-out:hover {
    background-color: #66676b;
    color: #fff
}

.custom-zoom-in:before,
.custom-zoom-out:before {
    font-family: fontawesome;
    width: 100%;
    line-height: 35px;
    transition: -webkit-text-stroke .3s;
    -webkit-text-stroke: 1px #fff
}

.zoomControlWrapper {
    position: absolute;
    left: 0;
    right: auto;
    width: 70px
}

.custom-zoom-in:before {
    content: "\f067"
}

.custom-zoom-out:before {
    content: "\f068"
}

.custom-zoom-in:hover:before,
.custom-zoom-out:hover:before {
    -webkit-text-stroke: 1px #66676b
}

#mapnav-buttons {
    position: absolute;
    transform: translate(0, 0);
    z-index: 999;
    font-size: 14px;
    display: inline-block;
    bottom: 20px;
    right: 20px;
    list-style: none;
    padding: 0
}

#mapnav-buttons.top {
    top: 20px;
    right: 20px;
    bottom: auto
}

#mapnav-buttons li {
    float: left;
    margin-left: 4px;
    line-height: 20px
}

#streetView,
#geoLocation,
#scrollEnabling,
#mapnav-buttons a {
    color: #333;
    background-color: #fff;
    padding: 7px 18px;
    padding-top: 9px;
    font-weight: 500;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -transition: all .2s ease-in-out;
    box-sizing: border-box;
    display: inline-block;
    border-radius: 50px;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .2)
}

#geoLocation:hover,
#streetView:hover,
#scrollEnabling:hover,
#scrollEnabling.enabled,
#mapnav-buttons a:hover {
    background-color: #66676b;
    color: #fff
}

#prevpoint:before,
#nextpoint:after {
    font-family: fontawesome;
    position: relative;
    font-weight: 500;
    margin: 0 0 0 6px;
    font-size: 17px;
    top: 0;
    line-height: 1px
}

#prevpoint:before {
    content: "\f104";
    margin: 0 6px 0 0
}

#nextpoint:after {
    content: "\f105";
    margin: 0 0 0 6px
}

#streetView,
#geoLocation,
#scrollEnabling {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;
    font-size: 13px;
    line-height: 21px
}

#streetView:before,
#geoLocation:before,
#scrollEnabling:before {
    content: "\e015";
    font-family: simple-line-icons;
    position: relative;
    top: 2px;
    margin: 0 6px 0 0;
    font-size: 15px;
    line-height: 1px
}

#scrollEnabling:before {
    margin-left: -3px
}

#streetView:before {
    content: "\f21d";
    font-family: fontawesome;
    font-size: 16px;
    top: 1px;
    margin-right: 8px
}

#geoLocation {
    right: auto;
    left: 20px;
    padding: 8px 11px
}

#geoLocation:before {
    content: "\f192";
    font-family: fontawesome;
    font-size: 16px;
    margin: 0;
    top: 2px
}

@-webkit-keyframes passing-through {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
        -ms-transform: translateY(40px);
        -o-transform: translateY(40px);
        transform: translateY(40px)
    }
    30%,
    70% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        -moz-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
        -o-transform: translateY(-40px);
        transform: translateY(-40px)
    }
}

@-moz-keyframes passing-through {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
        -ms-transform: translateY(40px);
        -o-transform: translateY(40px);
        transform: translateY(40px)
    }
    30%,
    70% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        -moz-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
        -o-transform: translateY(-40px);
        transform: translateY(-40px)
    }
}

@keyframes passing-through {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
        -ms-transform: translateY(40px);
        -o-transform: translateY(40px);
        transform: translateY(40px)
    }
    30%,
    70% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        -moz-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
        -o-transform: translateY(-40px);
        transform: translateY(-40px)
    }
}

@-webkit-keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
        -ms-transform: translateY(40px);
        -o-transform: translateY(40px);
        transform: translateY(40px)
    }
    30% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px)
    }
}

@-moz-keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
        -ms-transform: translateY(40px);
        -o-transform: translateY(40px);
        transform: translateY(40px)
    }
    30% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px)
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
        -ms-transform: translateY(40px);
        -o-transform: translateY(40px);
        transform: translateY(40px)
    }
    30% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px)
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
    10% {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1)
    }
    20% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@-moz-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
    10% {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1)
    }
    20% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
    10% {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1)
    }
    20% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

.dropzone,
.dropzone * {
    box-sizing: border-box
}

.dropzone {
    min-height: 150px;
    border: 2px solid rgba(0, 0, 0, .3);
    background: #fff;
    padding: 20px
}

.dropzone.dz-clickable {
    cursor: pointer
}

.dropzone.dz-clickable * {
    cursor: default
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
    cursor: pointer
}

.dropzone.dz-started .dz-message {
    display: none
}

.dropzone.dz-drag-hover {
    border-style: solid
}

.dropzone.dz-drag-hover .dz-message {
    opacity: .5
}

.dropzone .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px
}

.dropzone .dz-preview:hover {
    z-index: 1000
}

.dropzone .dz-preview:hover .dz-details {
    opacity: 1
}

.dropzone .dz-preview.dz-file-preview .dz-image {
    border-radius: 3px;
    background: #999;
    background: linear-gradient(to bottom, #eee, #ddd)
}

.dropzone .dz-preview.dz-file-preview .dz-details {
    opacity: 1
}

.dropzone .dz-preview.dz-image-preview {
    background: #fff
}

.dropzone .dz-preview.dz-image-preview .dz-details {
    -webkit-transition: opacity .2s linear;
    -moz-transition: opacity .2s linear;
    -ms-transition: opacity .2s linear;
    -o-transition: opacity .2s linear;
    transition: opacity .2s linear
}

.dropzone .dz-preview .dz-remove {
    font-size: 14px;
    text-align: center;
    display: block;
    cursor: pointer;
    border: 0
}

.dropzone .dz-preview .dz-remove:hover {
    text-decoration: underline
}

.dropzone .dz-preview:hover .dz-details {
    opacity: 1
}

.dropzone .dz-preview .dz-details {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 13px;
    min-width: 100%;
    max-width: 100%;
    padding: 2em 1em;
    text-align: center;
    color: rgba(0, 0, 0, .9);
    line-height: 150%
}

.dropzone .dz-preview .dz-details .dz-size {
    margin-bottom: 1em;
    font-size: 16px
}

.dropzone .dz-preview .dz-details .dz-filename {
    white-space: nowrap
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
    border: 1px solid rgba(200, 200, 200, .8);
    background-color: rgba(255, 255, 255, .8)
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
    border: 1px solid transparent
}

.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
    background-color: rgba(255, 255, 255, .4);
    padding: 0 .4em;
    border-radius: 3px
}

.dropzone .dz-preview:hover .dz-image img {
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-filter: blur(8px);
    filter: blur(8px)
}

.dropzone .dz-preview .dz-image {
    border-radius: 3px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    transition: all .3s;
    display: block;
    z-index: 10
}

.dropzone .dz-preview .dz-image img {
    display: block;
    filter: blur(0px);
    transition: all .4s
}

.dropzone .dz-preview.dz-success .dz-success-mark {
    -webkit-animation: passing-through 3s cubic-bezier(.77, 0, .175, 1);
    -moz-animation: passing-through 3s cubic-bezier(.77, 0, .175, 1);
    -ms-animation: passing-through 3s cubic-bezier(.77, 0, .175, 1);
    -o-animation: passing-through 3s cubic-bezier(.77, 0, .175, 1);
    animation: passing-through 3s cubic-bezier(.77, 0, .175, 1)
}

.dropzone .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    -webkit-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
    -moz-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
    -ms-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
    -o-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
    animation: slide-in 3s cubic-bezier(.77, 0, .175, 1)
}

.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 500;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -27px
}

.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
    display: block;
    width: 54px;
    height: 54px
}

.dropzone .dz-preview.dz-processing .dz-progress {
    opacity: 1;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear
}

.dropzone .dz-preview.dz-complete .dz-progress {
    opacity: 0;
    -webkit-transition: opacity .4s ease-in;
    -moz-transition: opacity .4s ease-in;
    -ms-transition: opacity .4s ease-in;
    -o-transition: opacity .4s ease-in;
    transition: opacity .4s ease-in
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
    -webkit-animation: pulse 6s ease infinite;
    -moz-animation: pulse 6s ease infinite;
    -ms-animation: pulse 6s ease infinite;
    -o-animation: pulse 6s ease infinite;
    animation: pulse 6s ease infinite
}

.dropzone .dz-preview .dz-progress {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    height: 16px;
    left: 50%;
    top: 50%;
    margin-top: -8px;
    width: 80px;
    margin-left: -40px;
    background: rgba(255, 255, 255, .9);
    -webkit-transform: scale(1);
    border-radius: 8px;
    overflow: hidden
}

.dropzone .dz-preview .dz-progress .dz-upload {
    background: #333;
    background: linear-gradient(to bottom, #666, #444);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    -webkit-transition: width 300ms ease-in-out;
    -moz-transition: width 300ms ease-in-out;
    -ms-transition: width 300ms ease-in-out;
    -o-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out
}

.dropzone .dz-preview.dz-error .dz-error-message {
    display: block
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
    opacity: 1;
    pointer-events: auto
}

.dropzone .dz-preview .dz-error-message {
    pointer-events: none;
    z-index: 1000;
    position: absolute;
    display: block;
    display: none;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    -moz-transition: opacity .3s ease;
    -ms-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: opacity .3s ease;
    font-size: 13px;
    top: 130px;
    left: -10px;
    width: 140px;
    background: #be2626;
    background: linear-gradient(to bottom, #be2626, #a92222);
    border-radius: 4px;
    padding: 10px 15px;
    line-height: 18px;
    color: #fff
}

.dropzone .dz-preview .dz-error-message:after {
    content: '';
    position: absolute;
    top: -6px;
    left: 64px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #be2626
}

.dropzone {
    position: relative;
    border: 2px dashed #d0d0d0;
    border-radius: 3px;
    background: #fcfcfc;
    transition: all .3s linear;
    margin-bottom: 10px;
    margin-top: 5px;
    display: inline-block;
    width: 100%
}

.dropzone:hover {
    border: 2px dashed #59b02c;
    background: #fff;
    transition: all .35s linear
}

.dropzone:before {
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9));
    background-color: #59b02c;
    opacity: 0;
    z-index: 10;
    transition: all .3s linear;
    border-radius: 3px
}

.dropzone:hover:before {
    opacity: .4
}

.dropzone .dz-message {
    text-align: center;
    margin: 50px 0;
    font-size: 17px;
    color: #888;
    transition: all .3s linear;
    position: relative;
    z-index: 11
}

.dropzone:hover .dz-message {
    color: #59b02c
}

.dz-message i {
    display: block;
    font-size: 32px;
    margin-bottom: 15px
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    overflow: hidden;
    position: fixed;
    background: #000;
    opacity: .96
}

.mfp-wrap {
    top: -10px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2001;
    position: fixed;
    outline: 0 !important;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    height: 100%;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 18px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle
}

.mfp-align-top .mfp-container:before {
    display: none
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 505
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.mfp-loading.mfp-figure {
    display: none
}

.mfp-hide {
    display: none !important
}

.mfp-preloader {
    color: #aaa;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -.8em;
    left: 8px;
    right: 8px;
    z-index: 504
}

.mfp-preloader a {
    color: #ccc
}

.mfp-preloader a:hover {
    color: #fff
}

.mfp-s-ready .mfp-preloader {
    display: none
}

.mfp-s-error .mfp-content {
    display: none
}

.mfp-s-loading .mfp-arrow {
    display: none
}

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    border: 0;
    -webkit-appearance: none;
    display: block;
    padding: 0;
    z-index: 506
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    right: 0;
    top: -40px
}

.og-close,
button.mfp-close {
    position: absolute;
    width: 45px;
    height: 45px;
    top: -20px;
    display: block;
    right: -12px;
    cursor: pointer !important;
    z-index: 9999;
    color: #fff;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50%;
    margin: 0;
    background-color: transparent;
    outline: 0;
    transform: translate3d(0, 0, 0)
}

.mfp-iframe-holder .mfp-close {
    top: -50px;
    right: -12px;
    transform: translate3d(0, 0, 0)
}

.mfp-gallery .mfp-close {
    top: 20px;
    right: 10px
}

.mfp-gallery .mfp-content .mfp-close {
    display: none
}

.mfp-gallery .mfp-close:after,
.mfp-gallery .mfp-close:before {
    font-family: fontawesome;
    font-size: 29px;
    top: 4px;
    left: 50%;
    position: absolute;
    height: 10px;
    transition: .3s
}

.mfp-close::after,
.mfp-close::before {
    top: 0
}

.og-close {
    margin: 17px;
    right: 10px;
    background: 0 0;
    color: #222
}

.mfp-close:hover,
.og-close:hover {
    transform: rotate(90deg)
}

.og-close:after,
.og-close:before,
.mfp-close:after,
.mfp-close:before {
    font-family: fontawesome;
    font-size: 29px;
    top: 21px;
    left: 50%;
    position: absolute;
    height: 10px;
    transition: .3s
}

.og-close:after,
.og-close:before {
    top: 7px;
    font-size: 29px
}

.og-close:after,
.mfp-close:after {
    content: "\f106";
    -webkit-transform: translate(0, 0);
    transform: translate(-50%, 60%)
}

.og-close:after {
    -webkit-transform: translate(0, 0);
    transform: translate(-50%, 60%)
}

.og-close:before,
.mfp-close:before {
    content: "\f107";
    -webkit-transform: translate(0, 0);
    transform: translate(-50%, 0%)
}

#small-dialog .mfp-close,
#sign-in-dialog,
.mfp-close,
.mfp-close:hover {
    color: #fff
}

#sign-in-dialog .mfp-close,
#small-dialog .mfp-close {
    color: #666;
    background-color: #e4e4e4;
    border-radius: 50%;
    top: 28px;
    right: 40px;
    width: 40px;
    height: 40px
}

#sign-in-dialog .mfp-close:hover,
#small-dialog .mfp-close:hover {
    color: #fff;
    transform: rotate(0);
    background-color: #66676b
}

#sign-in-dialog .mfp-close::after,
#sign-in-dialog .mfp-close::before,
#small-dialog .mfp-close::after,
#small-dialog .mfp-close::before {
    top: 4px;
    height: 9px;
    font-size: 21px;
    transition: 0s
}

.small-dialog-content input[type=submit] {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 10px
}

.small-dialog-content .divider {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    padding-bottom: 0
}

.small-dialog-header {
    font-size: 22px;
    width: calc(100% + 80px);
    position: relative;
    left: -40px;
    top: 0;
    border-radius: 4px 4px 0 0;
    display: inline-block;
    background-color: #f6f6f6;
    padding: 35px 40px;
    margin-bottom: 40px
}

.small-dialog-header h3 {
    padding: 0;
    margin: 0;
    font-size: 24px
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #aaa;
    font-size: 13px;
    line-height: 18px
}

.mfp-arrow {
    opacity: 1;
    margin: 0 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    color: #fff;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer;
    width: 60px;
    height: 60px;
    position: absolute;
    display: block;
    z-index: 100;
    overflow: hidden;
    background: rgba(255, 255, 255, .15);
    border-radius: 50%;
    outline: 0
}

.mfp-arrow:hover {
    background: #66676b;
    color: #fff
}

.mfp-arrow.mfp-arrow-left,
.mfp-arrow.mfp-arrow-right {
    font-family: simple-line-icons;
    font-size: 20px
}

.mfp-arrow.mfp-arrow-right {
    right: 15px
}

.mfp-arrow.mfp-arrow-left:before {
    content: "\e605";
    left: -1px;
    top: 0;
    position: relative
}

.mfp-arrow.mfp-arrow-right:before {
    content: "\e606";
    left: 1px;
    top: 0;
    position: relative
}

.mfp-content:hover .mfp-arrow {
    opacity: 1
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 1180px
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 54%
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #000
}

#sign-in-dialog,
#small-dialog {
    background: #fff;
    padding: 40px;
    padding-top: 0;
    text-align: left;
    max-width: 610px;
    margin: 40px auto;
    position: relative;
    box-sizing: border-box;
    border-radius: 4px
}

#sign-in-dialog {
    max-width: 500px
}

#sign-in-dialog span.line,
#small-dialog span.line {
    padding-top: 0
}

.my-mfp-zoom-in .zoom-anim-dialog {
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8)
}

.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8);
    opacity: 0
}

.my-mfp-zoom-in.mfp-bg {
    opacity: .001;
    -webkit-transition: opacity .3s ease-out;
    -moz-transition: opacity .3s ease-out;
    -o-transition: opacity .3s ease-out;
    transition: opacity .3s ease-out
}

.my-mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: .8
}

.my-mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 40px auto;
    border-radius: 4px
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 18px rgba(11, 11, 11, .6)
}

.mfp-figure {
    line-height: 0
}

.mfp-bottom-bar {
    margin-top: -30px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-break: break-word;
    padding-right: 36px
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px
}

.mfp-image-holder .mfp-content {
    max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        text-align: center;
        padding: 0
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }
}

.mfp-ie7 .mfp-img {
    padding: 0
}

.mfp-ie7 .mfp-bottom-bar {
    width: 600px;
    left: 50%;
    margin-left: -300px;
    margin-top: 5px;
    padding-bottom: 5px
}

.mfp-ie7 .mfp-container {
    padding: 0
}

.mfp-ie7 .mfp-content {
    padding-top: 44px
}

.mfp-ie7 .mfp-close {
    top: 0;
    right: 0;
    padding-top: 0
}

.mfp-fade.mfp-bg {
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    transition: all .2s ease-out
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: .92;
    background-color: #111
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    transition: all .2s ease-out
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0
}

.parallax {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    z-index: 99
}

.fullscreen,
.content-a {
    width: 100%;
    height: 100%;
    overflow: hidden
}

.fullscreen.overflow,
.fullscreen.overflow .content-a {
    height: auto;
    min-height: 100%
}

.centered-content {
    position: relative;
    vertical-align: middle;
    text-align: center
}

.parallax-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    background-color: #333;
    opacity: .4
}

.parallax-content {
    position: relative;
    z-index: 999;
    padding: 105px 0
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: 0
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: ''
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.logo-slick-carousel,
.testimonial-carousel,
.simple-fw-slick-carousel,
.simple-slick-carousel {
    display: none
}

.slick-initialized.logo-slick-carousel,
.slick-initialized.testimonial-carousel,
.slick-initialized.simple-fw-slick-carousel,
.slick-initialized.simple-slick-carousel {
    display: block
}

.simple-fw-slick-carousel .fw-carousel-item {
    padding: 10px 15px 0
}

.simple-fw-slick-carousel {
    margin: 0 15px;
    max-width: 100%;
    box-sizing: border-box
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    z-index: 100;
    display: block;
    width: auto;
    height: auto;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0
}

.logo-slick-carousel .slick-prev,
.logo-slick-carousel .slick-next,
.simple-slick-carousel .slick-prev,
.simple-slick-carousel .slick-next {
    display: none !important
}

.simple-slick-carousel.arrows-nav .slick-prev,
.simple-slick-carousel.arrows-nav .slick-next {
    display: block !important
}

.slick-prev:hover,
.slick-next:hover {
    color: transparent;
    outline: 0;
    background: 0 0
}

.slick-prev:hover:before,
.slick-next:hover:before {
    color: #999
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25
}

.slick-prev:before,
.slick-next:before {
    font-family: simple-line-icons;
    font-size: 32px;
    line-height: 1;
    opacity: 1;
    color: #aaa;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all .3s
}

.white-nav .slick-prev:before,
.white-nav .slick-next:before {
    color: #fff
}

.slick-prev {
    left: 3px;
    transform: translate3d(-90px, -50%, 0)
}

.slick-next {
    right: 3px;
    transform: translate3d(90px, -50%, 0)
}

.slick-prev:before {
    content: '\e605'
}

.slick-next:before {
    content: '\e606'
}

.slick-dotted.slick-slider {
    margin-bottom: 30px
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center
}

.simple-slick-carousel.slick-dotted.slick-slider {
    margin-bottom: 0
}

.simple-slick-carousel.slick-dotted.slick-slider.dots-nav {
    margin-bottom: 30px
}

.simple-slick-carousel .slick-dots {
    display: none !important
}

.simple-slick-carousel.dots-nav .slick-dots {
    display: block !important
}

.slick-dots li {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 3px;
    cursor: pointer
}

.slick-dots li {
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    color: transparent;
    border: 0;
    position: relative;
    outline: 0;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 3px;
    opacity: 1;
    border-radius: 50%;
    line-height: 0;
    box-shadow: inset 0 0 0 2px silver;
    transition: all .3s
}

.slick-dots li.slick-active {
    box-shadow: inset 0 0 0 6px silver;
    transition: all .3s
}

.slick-dots li:after {
    display: block;
    height: 13px;
    width: 13px;
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: transparent;
    border-radius: 50%;
    transition: all .3s;
    opacity: 0;
    background-color: silver
}

.slick-dots li.slick-active:after {
    opacity: 1
}

.white-nav .slick-dots li {
    box-shadow: inset 0 0 0 2px #fff
}

.white-nav .slick-dots li.slick-active {
    box-shadow: inset 0 0 0 8px #fff
}

.slick-dots li button {
    display: none
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: 0
}

.logo-slick-carousel div.item {
    margin-bottom: 30px;
    text-align: center;
    display: inline-block
}

.logo-slick-carousel div.item img {
    display: inline-block
}

.fullwidth-slick-carousel .slick-slide {
    margin: 0 15px 30px;
    opacity: .2;
    transition: .4s;
    pointer-events: none
}

.slick-slide {
    outline: 0
}

.testimonial-carousel .slick-slide.slick-active,
.fullwidth-slick-carousel .slick-slide.slick-active {
    opacity: 1;
    filter: none;
    pointer-events: all
}

.fullwidth-carousel-container {
    overflow: hidden;
    width: 100%
}

.category-box-container {
    position: relative;
    height: 100%
}

.category-box-container.half {
    height: calc(50% - 15px);
    margin-bottom: 30px
}

.category-box {
    background: #ccc;
    border-radius: 4px;
    height: 100%;
    display: block;
    margin-bottom: 20px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    overflow: hidden;
    cursor: pointer
}

.category-box:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    background: -webkit-linear-gradient(bottom, rgba(35, 35, 37, 0.85) 0%, rgba(35, 35, 37, 0.4) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
    background: -o-linear-gradient(bottom, rgba(35, 35, 37, 0.85) 0%, rgba(35, 35, 37, 0.4) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to top, rgba(35, 35, 37, 0.85) 0%, rgba(35, 35, 37, 0.4) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%)
}

.fullwidth-slick-carousel.category-carousel .slick-slide {
    height: 450px
}

.fullwidth-slick-carousel.category-carousel .slick-slide.fw-carousel-review {
    height: auto
}

.category-box-content {
    position: absolute;
    bottom: 30px;
    left: 34px;
    width: calc(100% - 68px);
    z-index: 50;
    box-sizing: border-box
}

.category-box-content h3 {
    color: #fff;
    font-size: 24px;
    padding: 5px 0;
    margin: 0
}

.category-box-content span {
    font-size: 18px;
    font-weight: 300;
    display: inline-block;
    color: rgba(255, 255, 255, .7)
}

.category-box-btn {
    position: absolute;
    right: 32px;
    bottom: 32px;
    z-index: 111;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    padding: 8px 0;
    text-align: center;
    min-width: 120px;
    border-radius: 50px;
    transition: all .3s
}

.category-box-btn:hover {
    background-color: #66676b;
    border-color: #66676b
}

.fw-carousel-item {
    position: relative;
    height: 100%
}

.category-box-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    transition: transform .35s ease-out;
    transform: translate3d(0, 0, 0) scale(1);
    image-rendering: -webkit-optimize-contrast
}

.category-box:hover .category-box-background {
    transform: translate3d(0, 0, 0) scale(1.08)
}

.simple-slick-carousel {
    height: auto;
    width: calc(100% + 30px);
    left: -10px
}

.simple-slick-carousel .category-box-container {
    height: 100%;
    display: block
}

.simple-slick-carousel .slick-slide {
    padding: 10px 15px
}

.option-set {
    padding: 0;
    margin: 0
}

.option-set li {
    display: inline-block;
    margin-right: 2px
}

.option-set li a {
    background: 0 0;
    padding: 5px 16px;
    display: block;
    outline: 0;
    border: 0;
    font-weight: 600;
    float: right;
    margin: 0;
    font-size: 15px;
    border-radius: 50px;
    background-color: white;
    color: #666;
    line-height: 26px;
    transition: all .3s
}

.option-set li a:hover {
    background-color: #e9e9e9
}

.option-set li a.selected {
    color: #fff;
    background-color: #66676b
}

body {
    color: #707070;
    font-size: 15px;
    line-height: 27px;
    background-color: #efe9e4
}

body p {
    font-size: 16px;
    line-height: 29px
}

#wrapper {
    background-color: #efe9e4
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
input[type=text],
input[type=password],
input[type=email],
textarea,
select,
input[type=button],
input[type=submit],
button,
#tiptip_content,
.map-box p,
.map-box div,
.numerical-rating .rating-counter,
body .menu-responsive i.menu-trigger:after {
    font-family: "Open Sans", helveticaneue, helvetica neue, Helvetica, Arial, sans-serif;
    text-transform: none
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #222
}

h1.white,
h2.white,
h3.white,
h4.white,
h5.white,
h6.white {
    color: #fff
}

h3.headline {
    font-size: 30px;
    line-height: 37px;
    margin: 0 0 30px
}

h2.headline {
    font-size: 34px;
    line-height: 40px;
    margin: 0 0 30px
}

h2.headline span {
    font-size: 23px;
    line-height: 37px
}

.headline span {
    font-size: 22px;
    line-height: 36px;
    margin-top: 10px;
    color: #888;
    font-weight: 300;
    display: block
}

.headline span i {
    color: #66676b;
    font-style: normal
}

h4.headline {
    font-size: 22px;
    line-height: 32px;
    margin: -5px 0 30px
}

.headline.centered {
    text-align: center
}

.border-top {
    border-top: 1px solid #e8e8e8
}

.icons-container {
    overflow: hidden
}

mark {
    font-weight: 500
}

.glyph {
    font-size: 16px;
    width: 33%;
    display: inline-block;
    transition: .3s
}

h4 span.headline-detail {
    font-size: 15px;
    margin-top: 5px
}

.mls {
    font-size: 16px;
    top: -8px;
    display: inline-block;
    position: relative
}

.fs1 {
    font-size: 32px
}

.pbs .im {
    width: 50px;
    display: inline-block
}

.unit,
.unitRight,
.fs0 {
    display: none
}

.pbs {
    padding: 10px 0 5px 15px;
    border-radius: 4px;
    width: 100%;
    display: inline-block;
    transition: .2s
}

.pbs:hover {
    background-color: #f4f4f4
}

.sl-icons-demo i {
    width: 25px;
    display: inline-block;
    top: 1px;
    position: relative
}

.icons .fa.fa-fw {
    margin-right: 3px;
    font-size: 14px
}

.icons .text-muted {
    display: none
}

.margin-top-0 {
    margin-top: 0 !important
}

.margin-top-1 {
    margin-top: 1px !important
}

.margin-top-2 {
    margin-top: 2px !important
}

.margin-top-3 {
    margin-top: 3px !important
}

.margin-top-4 {
    margin-top: 4px !important
}

.margin-top-5 {
    margin-top: 5px !important
}

.margin-top-6 {
    margin-top: 6px !important
}

.margin-top-7 {
    margin-top: 7px !important
}

.margin-top-8 {
    margin-top: 8px !important
}

.margin-top-9 {
    margin-top: 9px !important
}

.margin-top-10 {
    margin-top: 10px !important
}

.margin-top-15 {
    margin-top: 15px !important
}

.margin-top-20 {
    margin-top: 20px !important
}

.margin-top-25 {
    margin-top: 25px !important
}

.margin-top-30 {
    margin-top: 30px !important
}

.margin-top-35 {
    margin-top: 35px !important
}

.margin-top-40 {
    margin-top: 40px !important
}

.margin-top-45 {
    margin-top: 45px !important
}

.margin-top-50 {
    margin-top: 50px !important
}

.margin-top-55 {
    margin-top: 55px !important
}

.margin-top-60 {
    margin-top: 60px !important
}

.margin-top-65 {
    margin-top: 65px !important
}

.margin-top-70 {
    margin-top: 70px !important
}

.margin-top-75 {
    margin-top: 75px !important
}

.margin-top-80 {
    margin-top: 80px !important
}

.margin-top-85 {
    margin-top: 85px !important
}

.margin-top-90 {
    margin-top: 90px !important
}

.margin-top-95 {
    margin-top: 95px !important
}

.margin-top-100 {
    margin-top: 100px !important
}

.margin-top-105 {
    margin-top: 105px !important
}

.margin-top-110 {
    margin-top: 110px !important
}

.margin-top-115 {
    margin-top: 115px !important
}

.margin-top-120 {
    margin-top: 120px !important
}

.margin-bottom-0 {
    margin-bottom: 0 !important
}

.margin-bottom-5 {
    margin-bottom: 5px !important
}

.margin-bottom-10 {
    margin-bottom: 10px !important
}

.margin-bottom-15 {
    margin-bottom: 15px !important
}

.margin-bottom-20 {
    margin-bottom: 20px !important
}

.margin-bottom-25 {
    margin-bottom: 25px !important
}

.margin-bottom-30 {
    margin-bottom: 30px !important
}

.margin-bottom-35 {
    margin-bottom: 35px !important
}

.margin-bottom-40 {
    margin-bottom: 40px !important
}

.margin-bottom-45 {
    margin-bottom: 45px !important
}

.margin-bottom-50 {
    margin-bottom: 50px !important
}

.margin-bottom-55 {
    margin-bottom: 55px !important
}

.margin-bottom-55 {
    margin-bottom: 55px !important
}

.margin-bottom-55 {
    margin-bottom: 55px !important
}

.margin-bottom-55 {
    margin-bottom: 55px !important
}

.margin-bottom-60 {
    margin-bottom: 60px !important
}

.margin-bottom-65 {
    margin-bottom: 65px !important
}

.margin-bottom-70 {
    margin-bottom: 70px !important
}

.margin-bottom-75 {
    margin-bottom: 75px !important
}

.margin-bottom-80 {
    margin-bottom: 80px !important
}

.margin-bottom-85 {
    margin-bottom: 85px !important
}

.margin-bottom-90 {
    margin-bottom: 90px !important
}

.margin-bottom-95 {
    margin-bottom: 95px !important
}

.margin-bottom-100 {
    margin-bottom: 100px !important
}

.margin-left-0 {
    margin-left: 0 !important
}

.margin-left-1 {
    margin-left: 1px !important
}

.margin-left-2 {
    margin-left: 2px !important
}

.margin-left-3 {
    margin-left: 3px !important
}

.margin-left-4 {
    margin-left: 4px !important
}

.margin-left-5 {
    margin-left: 5px !important
}

.margin-left-6 {
    margin-left: 6px !important
}

.margin-left-7 {
    margin-left: 7px !important
}

.margin-left-8 {
    margin-left: 8px !important
}

.margin-left-9 {
    margin-left: 9px !important
}

.margin-left-10 {
    margin-left: 10px !important
}

.margin-left-15 {
    margin-left: 15px !important
}

.margin-left-20 {
    margin-left: 20px !important
}

.margin-left-25 {
    margin-left: 25px !important
}

.margin-left-30 {
    margin-left: 30px !important
}

.margin-left-35 {
    margin-left: 35px !important
}

.margin-left-40 {
    margin-left: 40px !important
}

.margin-left-45 {
    margin-left: 45px !important
}

.margin-left-50 {
    margin-left: 50px !important
}

.margin-left-55 {
    margin-left: 55px !important
}

.margin-left-60 {
    margin-left: 60px !important
}

.margin-left-65 {
    margin-left: 65px !important
}

.margin-left-70 {
    margin-left: 70px !important
}

.margin-left-75 {
    margin-left: 75px !important
}

.margin-left-80 {
    margin-left: 80px !important
}

.margin-left-85 {
    margin-left: 85px !important
}

.margin-left-90 {
    margin-left: 90px !important
}

.margin-left-95 {
    margin-left: 95px !important
}

.margin-left-100 {
    margin-left: 100px !important
}

.margin-right-0 {
    margin-right: 0 !important
}

.margin-right-1 {
    margin-right: 1px !important
}

.margin-right-2 {
    margin-right: 2px !important
}

.margin-right-3 {
    margin-right: 3px !important
}

.margin-right-4 {
    margin-right: 4px !important
}

.margin-right-5 {
    margin-right: 5px !important
}

.margin-right-6 {
    margin-right: 6px !important
}

.margin-right-7 {
    margin-right: 7px !important
}

.margin-right-8 {
    margin-right: 8px !important
}

.margin-right-9 {
    margin-right: 9px !important
}

.margin-right-10 {
    margin-right: 10px !important
}

.margin-right-15 {
    margin-right: 15px !important
}

.margin-right-20 {
    margin-right: 20px !important
}

.margin-right-25 {
    margin-right: 25px !important
}

.margin-right-30 {
    margin-right: 30px !important
}

.margin-right-35 {
    margin-right: 35px !important
}

.margin-right-40 {
    margin-right: 40px !important
}

.margin-right-45 {
    margin-right: 45px !important
}

.margin-right-50 {
    margin-right: 50px !important
}

.margin-right-55 {
    margin-right: 55px !important
}

.margin-right-60 {
    margin-right: 60px !important
}

.margin-right-65 {
    margin-right: 65px !important
}

.margin-right-70 {
    margin-right: 70px !important
}

.margin-right-75 {
    margin-right: 75px !important
}

.margin-right-80 {
    margin-right: 80px !important
}

.margin-right-85 {
    margin-right: 85px !important
}

.margin-right-90 {
    margin-right: 90px !important
}

.margin-right-95 {
    margin-right: 95px !important
}

.margin-right-100 {
    margin-right: 100px !important
}

.padding-top-0 {
    padding-top: 0 !important
}

.padding-top-1 {
    padding-top: 1px !important
}

.padding-top-2 {
    padding-top: 2px !important
}

.padding-top-3 {
    padding-top: 3px !important
}

.padding-top-4 {
    padding-top: 4px !important
}

.padding-top-5 {
    padding-top: 5px !important
}

.padding-top-6 {
    padding-top: 6px !important
}

.padding-top-7 {
    padding-top: 7px !important
}

.padding-top-8 {
    padding-top: 8px !important
}

.padding-top-9 {
    padding-top: 9px !important
}

.padding-top-10 {
    padding-top: 10px !important
}

.padding-top-15 {
    padding-top: 15px !important
}

.padding-top-20 {
    padding-top: 20px !important
}

.padding-top-25 {
    padding-top: 25px !important
}

.padding-top-30 {
    padding-top: 30px !important
}

.padding-top-35 {
    padding-top: 35px !important
}

.padding-top-40 {
    padding-top: 40px !important
}

.padding-top-45 {
    padding-top: 45px !important
}

.padding-top-50 {
    padding-top: 50px !important
}

.padding-top-55 {
    padding-top: 55px !important
}

.padding-top-60 {
    padding-top: 60px !important
}

.padding-top-65 {
    padding-top: 65px !important
}

.padding-top-70 {
    padding-top: 70px !important
}

.padding-top-75 {
    padding-top: 75px !important
}

.padding-top-80 {
    padding-top: 80px !important
}

.padding-top-85 {
    padding-top: 85px !important
}

.padding-top-90 {
    padding-top: 90px !important
}

.padding-top-95 {
    padding-top: 95px !important
}

.padding-top-100 {
    padding-top: 100px !important
}

.padding-top-105 {
    padding-top: 105px !important
}

.padding-top-110 {
    padding-top: 110px !important
}

.padding-top-115 {
    padding-top: 115px !important
}

.padding-top-120 {
    padding-top: 120px !important
}

.padding-bottom-0 {
    padding-bottom: 0 !important
}

.padding-bottom-5 {
    padding-bottom: 5px !important
}

.padding-bottom-10 {
    padding-bottom: 10px !important
}

.padding-bottom-15 {
    padding-bottom: 15px !important
}

.padding-bottom-20 {
    padding-bottom: 20px !important
}

.padding-bottom-25 {
    padding-bottom: 25px !important
}

.padding-bottom-30 {
    padding-bottom: 30px !important
}

.padding-bottom-35 {
    padding-bottom: 35px !important
}

.padding-bottom-40 {
    padding-bottom: 40px !important
}

.padding-bottom-45 {
    padding-bottom: 45px !important
}

.padding-bottom-50 {
    padding-bottom: 50px !important
}

.padding-bottom-55 {
    padding-bottom: 55px !important
}

.padding-bottom-55 {
    padding-bottom: 55px !important
}

.padding-bottom-55 {
    padding-bottom: 55px !important
}

.padding-bottom-55 {
    padding-bottom: 55px !important
}

.padding-bottom-60 {
    padding-bottom: 60px !important
}

.padding-bottom-65 {
    padding-bottom: 65px !important
}

.padding-bottom-70 {
    padding-bottom: 70px !important
}

.padding-bottom-75 {
    padding-bottom: 75px !important
}

.padding-bottom-80 {
    padding-bottom: 80px !important
}

.padding-bottom-85 {
    padding-bottom: 85px !important
}

.padding-bottom-90 {
    padding-bottom: 90px !important
}

.padding-bottom-95 {
    padding-bottom: 95px !important
}

.padding-bottom-100 {
    padding-bottom: 100px !important
}

.padding-right-0 {
    padding-right: 0 !important
}

.padding-right-5 {
    padding-right: 5px !important
}

.padding-right-10 {
    padding-right: 10px !important
}

.padding-right-15 {
    padding-right: 15px !important
}

.padding-right-20 {
    padding-right: 20px !important
}

.padding-right-25 {
    padding-right: 25px !important
}

.padding-right-30 {
    padding-right: 30px !important
}

.padding-right-35 {
    padding-right: 35px !important
}

.padding-right-40 {
    padding-right: 40px !important
}

.padding-right-45 {
    padding-right: 45px !important
}

.padding-right-50 {
    padding-right: 50px !important
}

.padding-right-55 {
    padding-right: 55px !important
}

.padding-right-55 {
    padding-right: 55px !important
}

.padding-right-55 {
    padding-right: 55px !important
}

.padding-right-55 {
    padding-right: 55px !important
}

.padding-right-60 {
    padding-right: 60px !important
}

.padding-right-65 {
    padding-right: 65px !important
}

.padding-right-70 {
    padding-right: 70px !important
}

.padding-right-75 {
    padding-right: 75px !important
}

.padding-right-80 {
    padding-right: 80px !important
}

.padding-right-85 {
    padding-right: 85px !important
}

.padding-right-90 {
    padding-right: 90px !important
}

.padding-right-95 {
    padding-right: 95px !important
}

.padding-right-100 {
    padding-right: 100px !important
}

.padding-left-0 {
    padding-left: 0 !important
}

.padding-left-5 {
    padding-left: 5px !important
}

.padding-left-10 {
    padding-left: 10px !important
}

.padding-left-15 {
    padding-left: 15px !important
}

.padding-left-20 {
    padding-left: 20px !important
}

.padding-left-25 {
    padding-left: 25px !important
}

.padding-left-30 {
    padding-left: 30px !important
}

.padding-left-35 {
    padding-left: 35px !important
}

.padding-left-40 {
    padding-left: 40px !important
}

.padding-left-45 {
    padding-left: 45px !important
}

.padding-left-50 {
    padding-left: 50px !important
}

.padding-left-55 {
    padding-left: 55px !important
}

.padding-left-55 {
    padding-left: 55px !important
}

.padding-left-55 {
    padding-left: 55px !important
}

.padding-left-55 {
    padding-left: 55px !important
}

.padding-left-60 {
    padding-left: 60px !important
}

.padding-left-65 {
    padding-left: 65px !important
}

.padding-left-70 {
    padding-left: 70px !important
}

.padding-left-75 {
    padding-left: 75px !important
}

.padding-left-80 {
    padding-left: 80px !important
}

.padding-left-85 {
    padding-left: 85px !important
}

.padding-left-90 {
    padding-left: 90px !important
}

.padding-left-95 {
    padding-left: 95px !important
}

.padding-left-100 {
    padding-left: 100px !important
}

@media (max-width: 1600px) {
    .category-box-btn {
        left: 32px;
        right: auto
    }

    .category-box-content {
        bottom: 95px
    }

    .category-box-container.half {
        height: 100%
    }

    .fw-carousel-item .category-box-container.half:first-child {
        display: none
    }

    .fullwidth-slick-carousel.category-carousel .slick-slide {
        height: 350px
    }

    .dashboard-stat-icon {
        font-size: 66px
    }
}

@media only screen and (max-width: 1366px) and (min-width: 1240px) {
    .input-with-icon.location a i {
        padding: 15px 18px 15px 10px
    }

    .data-radius-title {
        margin-top: 7px;
        line-height: 24px
    }

    #map-container.fullwidth-home-map {
        height: 605px
    }

    .fs-content .search .row.with-forms .col-fs-6 {
        width: 50%
    }
}

@media (max-width: 1366px) {
    .fullwidth-slick-carousel.category-carousel .slick-slide {
        height: 320px
    }

    .dashboard-stat-icon {
        font-size: 50px
    }

    .main-search-container {
        height: 600px
    }
}

@media only screen and (max-width: 1365px) {
    header.fullwidth.dashboard .container {
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
        padding: 0 40px;
        width: 100%
    }

    .dashboard #logo {
        background-color: #efe9e4;
        position: relative;
        height: 100%;
        left: 0;
        top: 0;
        margin: 3px 50px 0 0;
        max-width: auto;
        min-width: auto
    }

    .dashboard #logo a {
        margin-left: 0;
        display: inline-block;
        height: 100%
    }

    .dashboard #logo a.dashboard-logo {
        display: none
    }

    .dashboard #logo a img {
        top: 0;
        position: relative;
        max-height: 40px;
        transform: translate3d(0, 0, 0)
    }

    .user-menu {
        margin: 0 25px
    }

    .user-menu ul {
        left: -25px;
        right: auto
    }
}

@media (max-width: 800px) {
    #navigation.style-1 {
        margin: 25px 0 5px -5px;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 0;
        position: relative
    }

    #navigation.style-1:before {
        position: absolute;
        width: 100%;
        height: 1px;
        display: block;
        top: 0;
        left: 5px;
        content: "";
        background: #e8e8e8;
        width: 940px
    }

    .fullwidth #navigation.style-1:before {
        background: linear-gradient(to right, #e8e8e8 50%, #fff)
    }

    .listing-item-container.list-layout .listing-item-image {
        flex: 3
    }

    .listing-slider,
    .listing-slider .item {
        height: 30vh;
        min-height: 400px;
        max-height: 400px
    }

    #header.cloned {
        display: none
    }
}

@media (max-width: 1024px) {
    #navigation.style-1 {
        margin: 0;
        padding-top: 0
    }

    #navigation.style-1:before {
        display: none
    }

    .header-widget {
        height: auto
    }

    .dashboard #logo {
        background-color: #efe9e4;
        position: absolute;
        width: 100%;
        height: auto;
        margin: 0 auto;
        margin-top: 20px;
        width: 35%;
        text-align: center;
        left: 0;
        right: 0
    }

    .dashboard #logo a {
        margin-left: 0;
        display: inline-block;
        height: 100%
    }

    .dashboard #logo a.dashboard-logo {
        display: none
    }

    .dashboard #logo a img {
        top: 0;
        position: relative;
        max-height: 40px;
        transform: translate3d(0, 0, 0)
    }
}

@media (max-width: 991px) {
    .main-search-container {
        height: 580px
    }

    .main-search-input select,
    .main-search-input select:focus {
        font-size: 16px;
        border: 1px solid #e0e0e0;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .08);
        background: #fff;
        height: 55px;
        padding: 12px 18px;
        border-radius: 4px
    }

    #dashboard {
        padding-top: 0 !important
    }

    .dashboard-nav {
        min-width: 90%;
        max-width: 90%;
        margin: 0 5%;
        border-radius: 0 0 4px 4px;
        position: relative;
        min-height: auto;
        opacity: 1;
        visibility: hidden;
        max-height: 0;
        padding: 0;
        transition: all .3s
    }

    .dashboard-nav.active ul,
    .dashboard-nav.active {
        opacity: 1;
        visibility: visible;
        max-height: 900px
    }

    .dashboard-nav ul,
    .dashboard-nav.active ul li ul,
    .dashboard-nav ul li ul {
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        transition: all .3s
    }

    .dashboard-nav.active ul li.active ul {
        visibility: visible;
        opacity: 1;
        max-height: 300px
    }

    #header-container.fixed {
        position: relative;
        top: 0;
        width: 100%
    }

    .dashboard-content {
        padding: 40px 5%;
        padding-bottom: 0;
        height: auto;
        margin-left: 0
    }

    header.fullwidth.dashboard .container {
        padding: 0 5%
    }

    .main-search-inner {
        transform: translate(0, -51%)
    }

    a.dashboard-responsive-nav-trigger {
        width: 90%;
        margin: 0 5%;
        margin-top: 40px;
        background-color: #333;
        border-radius: 5px;
        color: #fff;
        padding: 16px 30px;
        font-weight: 600;
        transition: .2s;
        display: block
    }

    a.dashboard-responsive-nav-trigger i {
        margin-right: 5px
    }

    a.dashboard-responsive-nav-trigger.active {
        border-radius: 4px 4px 0 0
    }

    .messages-container-inner {
        display: block
    }

    .messages-container-inner .messages-inbox {
        max-width: 100%;
        width: 100%;
        border-right: 0;
        display: none
    }

    .main-search-input {
        border-radius: 0;
        display: block;
        padding: 0;
        max-height: 100%;
        background-color: transparent;
        box-shadow: none
    }

    .gray-style.main-search-input {
        background-color: transparent
    }

    .gray-style .main-search-input-item,
    .main-search-input-item {
        border-color: transparent
    }

    .main-search-input button.button {
        margin-top: 10px
    }

    .main-search-input-item {
        margin-bottom: 10px
    }

    .main-search-input-item,
    .main-search-input .main-search-input-item:nth-last-child(2) {
        padding: 0
    }

    .main-search-input-item .chosen-container-single .chosen-single,
    .main-search-input-item .chosen-single.chosen-container-active,
    .gray-style .main-search-input-item .chosen-container-single .chosen-single,
    .gray-style .main-search-input-item .chosen-single.chosen-container-active,
    .gray-style.main-search-input input,
    .gray-style.main-search-input input:focus,
    .main-search-input input,
    .main-search-input input:focus {
        padding: 0 20px;
        height: 55px;
        line-height: 55px;
        border-radius: 4px;
        background: #fff;
        border: 1px solid #e0e0e0;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .08)
    }

    .gray-style.main-search-input input,
    .gray-style.main-search-input input:focus,
    .main-search-input input,
    .main-search-input input:focus {
        padding: 0 20px
    }

    .gray-style .main-search-input-item.location a {
        background: #fff
    }

    .gray-style .main-search-input-item .chosen-single {
        border-bottom: 1px solid #e0e0e0;
        transition: border 0s
    }

    .main-search-input-item .chosen-container-single .chosen-single,
    .main-search-input-item .chosen-container-active.chosen-with-drop .chosen-single {
        border: 1px solid #e0e0e0;
        box-shadow: none;
        transition: 0s
    }

    .main-search-input-item .chosen-container-active.chosen-with-drop .chosen-single {
        border-bottom: 0
    }

    .main-search-input-item .chosen-container .chosen-drop {
        border: 1px solid #e0e0e0;
        border-top: 0;
        border-radius: 0 0 4px 4px;
        box-shadow: none
    }

    .gray-style .main-search-input-item .chosen-container-active.chosen-with-drop .chosen-single {
        border-bottom: 0
    }

    .main-search-input-item .chosen-single::after {
        display: none
    }

    .simple-slick-carousel.slick-dotted.slick-slider {
        margin-bottom: 0;
        width: 100%;
        left: 0
    }

    .icon-box-2.with-line::before {
        display: none
    }

    .container .row div.padding-right-30 {
        padding-right: 15px !important
    }

    .container .row div.padding-left-30 {
        padding-left: 15px !important
    }

    .listing-item-container.list-layout:hover {
        transform: none
    }

    .listing-item-container.list-layout .listing-item-inner {
        position: relative;
        top: auto;
        transform: none;
        padding: 30px;
        left: 0
    }

    .listing-item-container.list-layout .listing-item {
        display: block;
        height: auto;
        padding: 0
    }

    .listing-item-container.list-layout .listing-item .listing-badge {
        display: none
    }

    .listing-item-container.list-layout .listing-item-image {
        min-height: auto
    }

    .listing-item-container.list-layout span.like-icon {
        top: -15px;
        transform: translateY(-100%);
        background-color: rgba(32, 32, 32, .4);
        color: #fff;
        right: 35px
    }

    .listing-item-container.list-layout .listing-item-details {
        position: relative;
        bottom: auto;
        right: auto;
        left: 0;
        top: -15px;
        margin: 0 30px 15px;
        padding: 5px 20px;
        background-color: #eee;
        border-radius: 50px;
        font-size: 14px;
        width: auto;
        display: inline-block
    }

    #titlebar {
        padding: 60px 0;
        margin-bottom: 60px
    }

    .panel-dropdown {
        margin-right: 4px;
        display: block;
        width: 100%
    }

    .fullwidth-filters .sort-by {
        display: block;
        width: 100%
    }

    .fullwidth-filters .sort-by label,
    .fullwidth-filters .sort-by .sort-by-select,
    .panel-dropdown {
        float: left;
        margin-top: 10px
    }

    .panel-dropdown a,
    .fullwidth-filters .sort-by .chosen-container-single .chosen-single {
        background: #f3f3f3
    }

    .panel-dropdown .panel-dropdown-content,
    .fullwidth-filters .panel-dropdown.float-right .panel-dropdown-content {
        left: 0;
        right: auto;
        width: 400px;
        max-width: 70vw
    }

    .fullwidth-filters .sort-by .chosen-container.chosen-with-drop .chosen-drop,
    .fullwidth-filters .sort-by .chosen-container .chosen-drop {
        left: 0;
        right: auto;
        margin-left: 0
    }

    .listing-item-container:hover {
        transform: none
    }

    .fs-inner-container.map-fixed {
        height: 500px;
        position: relative;
        top: 0;
        padding-top: 0 !important;
        width: 100%
    }

    .fs-inner-container.content {
        width: 100%;
        padding-top: 0 !important
    }

    .close-list-item {
        display: none
    }

    .buttons-to-right,
    .dashboard-list-box .button.to-right {
        position: relative;
        transform: none;
        top: 0;
        margin: 10px 0 0;
        opacity: 1;
        right: auto
    }

    .list-box-listing-img {
        max-width: 168px
    }

    .add-review-photos {
        float: left;
        top: 0
    }

    .add-review-box {
        border-radius: 4px;
        background-color: #f9f9f9;
        padding: 36px;
        padding-bottom: 40px;
        margin-bottom: 55px;
        margin-top: 70px;
        display: inline-block;
        width: 100%;
        position: relative
    }

    .listing-slider .slick-next:hover,
    .listing-slider .slick-next {
        right: 100px
    }

    .listing-slider .slick-prev:hover,
    .listing-slider .slick-prev {
        left: 100px
    }

    .listing-slider,
    .listing-slider .item {
        height: 30vh;
        min-height: 400px;
        max-height: 400px
    }

    .listing-nav-container.cloned .listing-nav {
        display: none
    }

    .opening-day .chosen-container {
        vertical-align: initial
    }

    .dashboard-content .dashboard-list-box-static {
        margin-bottom: 40px
    }

    .office-address {
        padding: 60px 0 !important
    }

    #googlemaps {
        height: 30vh !important;
        padding-bottom: 0 !important
    }

    .contact-map {
        display: block
    }

    .contact-map #singleListingMap-container {
        flex: 1;
        position: relative;
        padding-top: 0;
        display: block;
        height: 300px
    }

    .contact-map .address-box-container {
        width: 100%
    }

    .sidebar-textbox ul.contact-details {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .listing-features {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1
    }

    .messages-headline h4 {
        display: block
    }

    .message-action {
        display: inline-block;
        float: none;
        margin-top: 15px;
        background-color: #f2f2f2;
        border-radius: 50px;
        padding: 4px 20px;
        text-align: center
    }

    .message-bubble .message-text {
        margin-left: 55px
    }

    .message-bubble.me .message-text {
        margin-right: 55px
    }

    .message-bubble .message-avatar img {
        width: 35px;
        height: 35px;
        margin-top: 8px
    }

    #backtotop {
        display: none
    }

    .small-dialog-header {
        width: calc(100% + 60px);
        left: -30px;
        padding: 30px
    }

    #sign-in-dialog,
    #small-dialog {
        background: #fff;
        padding: 30px;
        padding-top: 0
    }

    #sign-in-dialog .mfp-close,
    #small-dialog .mfp-close {
        top: 24px;
        right: 30px;
        width: 40px;
        height: 40px
    }

    .list-box-listing {
        display: block
    }

    .list-box-listing-content .inner {
        position: relative;
        top: 0;
        transform: translateY(0);
        display: inline-block
    }

    .header-widget {
        height: auto
    }

    .list-box-listing-img {
        max-width: 100%
    }

    .list-box-listing-content {
        padding-left: 0;
        padding-top: 10px
    }

    #titlebar {
        padding: 50px 0;
        margin-bottom: 50px
    }

    #map-container.fullwidth-home-map .zoomControlWrapper {
        top: 15px !important
    }

    .pricing-container {
        display: block
    }

    .plan.featured {
        transform: none
    }

    .plan {
        margin-bottom: 35px
    }

    #breadcrumbs {
        position: relative;
        display: block;
        margin-top: 30px
    }

    #breadcrumbs ul li {
        margin: 0 10px 0 0
    }

    .comment-by .numerical-rating,
    .comment-by .star-rating {
        position: relative;
        right: 0;
        top: 7px;
        display: block;
        width: 100%;
        float: none;
        height: 30px
    }

    .avatar {
        display: none
    }

    .comment-content {
        padding: 0
    }

    .small-dialog-header h3 {
        padding: 0;
        margin: 0;
        font-size: 22px;
        padding-right: 80px;
        line-height: 30px
    }

    #sign-in-dialog,
    #small-dialog {
        max-width: 75vw
    }

    .listing-nav li a {
        border-bottom: 1px solid #e0e0e0;
        padding: 15px;
        width: 100%;
        display: block;
        padding-left: 0;
        border-color: #e8e8e8
    }

    .listing-nav li {
        margin-right: 0;
        display: block
    }

    .listing-nav-container .listing-nav li:first-child a {
        padding-top: 0
    }

    .listing-nav-container.cloned .listing-nav li:first-child a.active,
    .listing-nav-container.cloned .listing-nav li:first-child a:hover,
    .listing-nav li:first-child a,
    .listing-nav li a.active,
    .listing-nav li a:hover {
        border-color: #e8e8e8
    }

    .dashboard-content .checkboxes label {
        width: 100%
    }

    .pricing-list-item td {
        display: block;
        width: 100%;
        position: relative
    }

    .pricing-list-item td .fm-input,
    .pricing-list-item td .fm-input.pricing-price,
    .pricing-list-item td .fm-input.pricing-name {
        max-width: 100%;
        margin-right: 0
    }

    .pricing-list-item td .fm-close {
        text-align: left;
        position: absolute;
        top: 4px;
        right: 0
    }

    .about-author img {
        width: 70px;
        height: 70px
    }

    .about-description {
        margin: 2px 0 0 100px
    }

    #posts-nav li {
        position: relative;
        width: 100%
    }

    #posts-nav li a {
        padding: 0 !important
    }

    #posts-nav li a::before {
        display: none
    }

    #posts-nav li.next-post {
        text-align: left;
        margin-bottom: 30px
    }

    .comments ul li ul {
        margin: 0
    }

    .avatar img {
        max-width: 60px
    }

    .comment-content {
        padding: 0 0 0 85px
    }

    .comments ul li ul li::before {
        top: 25px
    }

    .comment-content {
        padding: 0
    }

    .testimonial::after {
        display: none
    }

    .testimonial-box {
        padding-left: 38px
    }

    .main-search-container h2 {
        font-size: 28px;
        font-weight: 400;
        line-height: 36px
    }

    .main-search-container h4 {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px
    }

    .main-search-container:before {
        background: rgba(255, 255, 255, .92)
    }
}

@media (max-width: 480px) {
    .tabs-nav.blog li,
    .tabs-nav.blog li a,
    .tabs-nav li,
    .tabs-nav li a {
        width: 100%;
        padding: 0;
        border-radius: 3px;
        text-align: center;
        border-bottom: 0
    }

    .about-author img {
        display: none
    }

    .about-description {
        margin: 0
    }

    .user-menu {
        margin: 10px 0 -10px 25px;
        top: 0;
        display: inline-block
    }

    .dashboard-list-box .sort-by .sort-by-select {
        display: none
    }
}

#style-switcher h3 {
    color: #fff;
    font-size: 13px;
    margin: 10px 0;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0
}

#style-switcher {
    background: #333;
    width: 205px;
    position: fixed;
    top: 145px;
    z-index: 999;
    left: -205px;
    border-radius: 0 0 4px 0
}

#style-switcher ul {
    list-style: none;
    padding: 0;
    margin-bottom: 22px
}

#style-switcher div {
    padding: 6px 18px
}

#style-switcher h2 {
    background: #282828;
    color: #fff;
    font-size: 14px;
    line-height: 46px;
    padding: 0 0 0 20px;
    margin-top: 0;
    font-weight: 600
}

#style-switcher h2 a {
    background-color: #282828;
    display: block;
    height: 46px;
    position: absolute;
    right: -46px;
    top: 0;
    width: 46px;
    border-radius: 0 4px 4px 0;
    font-size: 22px;
    color: #fff;
    text-align: center
}

#style-switcher h2 a i {
    position: relative;
    top: 2px
}

.colors {
    list-style: none;
    margin: 0 0 10px;
    overflow: hidden
}

.colors li {
    float: left;
    margin: 4px 2px 0
}

.colors li a {
    display: block;
    width: 17px;
    height: 17px;
    cursor: pointer
}

div.layout-style {
    padding: 0 !important
}

.layout-style select {
    width: 164px;
    padding: 7px;
    color: #aaa;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin: 2px 0 25px;
    background-color: #505050;
    border: 0;
    min-height: auto
}

.main {
    background: #f91941
}

.green {
    background: #00a948
}

.blue {
    background: #004dda
}

.orange {
    background: #fa5b0f
}

.navy {
    background: #495d7f
}

.yellow {
    background: #ffb400
}

.peach {
    background: #fcab55
}

.red {
    background: #f30c0c
}

.beige {
    background: #bfa980
}

.cyan {
    background: #37b6bd
}

.celadon {
    background: #74aea1
}

.brown {
    background: #784e3d
}

.cherry {
    background: #911938
}

.gray {
    background: #555
}

.purple {
    background: #6441eb
}

.olive {
    background: #b3c211
}

.colors li a.active {
    box-shadow: 0 0 0 2px #fff inset
}

.colors li a {
    border-radius: 3px
}

#reset {
    margin: -5px 0 15px
}

#reset a.button {
    padding: 7px 14px;
    font-weight: 700;
    font-size: 13px
}

#reset a.button:hover {
    color: #333;
    background-color: #fff
}

@media only screen and (max-width: 1024px) {
    #style-switcher {
        display: none
    }
}
